import React, { useState } from 'react';

import { useToast } from '@/hooks/useToast';
import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import Button, { ButtonVariant } from '@components/Button';
import { TextInput } from '@components/Inputs';
import Modal from '@components/Modal';
import { Typography } from '@components/Typography';
import { AddOrRenameCollectionModalProps } from './AddOrRenameCollectionModal.types';
import { encodeString } from '@/utilities/textHelpers';
import { MAX_CHARACTERS_ALLOWED } from '@/utilities/constants';

export const AddOrRenameCollectionModal: React.FC<AddOrRenameCollectionModalProps> = ({
  show,
  favoriteCollection,
  onConfirm,
  onClose,
}) => {
  const emptySpacesError = 'Collection name cannot be empty spaces or blank';
  const [collectionName, setCollectionName] = useState(favoriteCollection?.name ?? '');
  const [errorMessage, setErrorMessage] = useState('');
  const [maxCharactersRemaining, setMaxCharactersRemaining] = useState(
    favoriteCollection?.name
      ? MAX_CHARACTERS_ALLOWED - favoriteCollection?.name.length
      : MAX_CHARACTERS_ALLOWED,
  );

  const { addFavoriteCollection, updateFavoriteCollection } = useUserFavoriteCollections();
  const { addToast } = useToast();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const encodedString = encodeString(e.target.value);
    setMaxCharactersRemaining(MAX_CHARACTERS_ALLOWED - e.target.value.length);
    setCollectionName(encodedString);
    setErrorMessage('');
  };

  const handleConfirm = async () => {
    if (collectionName && collectionName.trim().length <= 0) {
      setErrorMessage(emptySpacesError);
      return;
    }
    try {
      if (!favoriteCollection) {
        const favoriteCollection = await addFavoriteCollection(collectionName, []);

        addToast({
          id: favoriteCollection.favoriteCollectionId,
          description: 'Collection Added Successfully',
          title: 'Success',
          type: 'success',
        });
      } else {
        await updateFavoriteCollection(favoriteCollection.favoriteCollectionId, collectionName);

        addToast({
          id: favoriteCollection.favoriteCollectionId,
          description: 'Collection Renamed Successfully',
          title: 'Success',
          type: 'success',
        });
      }

      onConfirm ? onConfirm() : onClose();
    } catch (error: any) {
      const { exceptionMessage } = error.response.data.responseException;
      setErrorMessage(exceptionMessage);
    }
  };

  return (
    <Modal show={show} haveClose onClose={() => onClose()}>
      <Typography className="mb-6 text-center mt-7" variant="h6">
        {favoriteCollection ? 'Rename' : 'Add New'} Collection
      </Typography>

      <Typography className="mb-4 bo" variant="body-4">
        Collection Name
      </Typography>

      <TextInput
        classNames="h-[5.375rem]"
        value={collectionName}
        onChange={handleChange}
        error={!!errorMessage}
        errorMessage={errorMessage}
        maxLength={MAX_CHARACTERS_ALLOWED}
        maxCharactersRemaining={maxCharactersRemaining}
        showRemainingCharactersText={true}
        errorClasses="break-all"
      />

      <Button
        buttonClassNames={errorMessage ? 'w-full mt-14 bg-slate-500' : 'w-full bg-slate-500'}
        disabled={collectionName === favoriteCollection?.name || !collectionName || !!errorMessage}
        label="Confirm"
        onClick={handleConfirm}
        variant={ButtonVariant.PRIMARY_ONE}
      />
    </Modal>
  );
};

export default AddOrRenameCollectionModal;
