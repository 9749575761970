import { Menu, MenuButton, MenuDivider, MenuItem } from '@szhsin/react-menu';
import { format } from 'date-fns';
import React, { useState } from 'react';

import useDelimitedActiveFiltersValues from '@/hooks/useDelimitedActiveFiltersValues';
import { useToast } from '@/hooks/useToast';
import { useUserSavedSearches } from '@/hooks/useUserSavedSearches';
import { SavedSearch } from '@/types';
import { Icon, IconName } from '@components/Icon';
import Link, { LinkVariant } from '@components/Link';
import RemoveSavedModal from '@components/RemoveSavedModal';
import { Typography } from '@components/Typography';
import EditSearchTitleModal from './EditSearchTitleModal';
import { SavedSeachCardProps } from './SavedSearchCard.types';
import { ShareSavedSearchModal } from '@components/ShareSavedSearchModal';
import { ToggleSwitch } from '@components/Inputs';
import './SavedSearchCard.css';
import { FEATURE_FLAG_ENABLE_SAVEDSEARCH_EMAIL_ALERTS } from '@/config';

export const SavedSearchCard: React.FC<SavedSeachCardProps> = ({ savedSearch }) => {
  const [showEditSearchTitleModal, setShowEditSearchTitleModal] = useState(false);
  const [showRemoveSavedSearchesModal, setShowRemoveSavedSearchesModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [emailToggle, setEmailToggle] = useState(savedSearch.emailAlertsEnabled);

  const { addToast } = useToast();
  const { removeSavedSearch, updateSavedSearch } = useUserSavedSearches();

  const startOfQueryParameterIndex = savedSearch.url.indexOf('?');
  const queryParameter = savedSearch.url.substring(startOfQueryParameterIndex);
  const { delimitedActiveFiltersValues } = useDelimitedActiveFiltersValues(queryParameter);

  const getAuditInformation = (savedSearch: SavedSearch) =>
    `Created On: ${format(new Date(savedSearch.createdAt), 'MM/dd/yyyy')}`;

  const auditInformation = getAuditInformation(savedSearch);

  const handleCloseRemoveSavedSearchesModal = () => {
    setShowRemoveSavedSearchesModal(false);
  };

  const handleRemoveSavedSearch = () => {
    const { savedSearchId } = savedSearch;
    removeSavedSearch(savedSearchId).then(() => {
      addToast({
        id: `remove-search-${savedSearchId}`,
        description: 'Search Has Been Removed',
        title: 'Success',
        type: 'success',
      });
      handleCloseRemoveSavedSearchesModal();
    });
  };

  const handleEditEmailAlerts = (enableEmailAlerts: boolean) => {
    setEmailToggle(enableEmailAlerts);
    const { url, name, savedSearchId } = savedSearch;
    updateSavedSearch(savedSearchId, name, url, enableEmailAlerts, delimitedActiveFiltersValues)
      .then(() => {
        addToast({
          description: 'Email Preference Updated',
          type: 'success',
          title: 'Success',
        });
      })
      .catch(() => {
        setEmailToggle(!enableEmailAlerts);
        addToast({
          id: `edit-search-${savedSearchId}`,
          description: 'Failed to Update Email Preference',
          type: 'error',
          title: 'Success',
        });
      });
  };

  return (
    <>
      {showEditSearchTitleModal && (
        <EditSearchTitleModal
          savedSearch={savedSearch}
          setShowEditSearchTitleModal={setShowEditSearchTitleModal}
          value={savedSearch.name ?? ''}
        />
      )}

      <RemoveSavedModal
        category="search"
        onCancel={handleCloseRemoveSavedSearchesModal}
        onRemove={handleRemoveSavedSearch}
        show={showRemoveSavedSearchesModal}
      />

      {showShareModal && (
        <ShareSavedSearchModal
          savedSearch={savedSearch}
          show={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      )}

      <div className="px-6 py-4 rounded-lg shadow bg-base-white relative">
        <div className="flex  sm:justify-between sm:items-center pb-[0.625rem]">
          <div className="flex flex-col sm:items-center sm:flex-row w-[100%] justify-between">
            <div className="flex flex-col self-start md:contents">
              <Link
                url={savedSearch.url}
                variant={LinkVariant.SAVED_SEARCH_CARD}
                classNames="link--nounderline w-full">
                <Typography variant="subtitle-1">{savedSearch.name}</Typography>
              </Link>

              {auditInformation && (
                <Typography
                  className="block md:hidden  audit-info mr-2 text-cement-500"
                  variant="body-4">
                  {auditInformation}
                </Typography>
              )}
            </div>

            {FEATURE_FLAG_ENABLE_SAVEDSEARCH_EMAIL_ALERTS && (
              <ToggleSwitch
                leftLabel="Allow Email Notifications"
                toggled={emailToggle}
                classNames="hidden md:flex text-sm font-normal w-full mr-6 content-between justify-between md:justify-end"
                onClick={(toggled) => handleEditEmailAlerts(toggled)}
              />
            )}
          </div>

          <div className="flex items-center">
            <Menu
              align="end"
              gap={-16}
              menuClassName="rounded-lg bg-white-400 shadow w-44 px-5 py-2 z-50"
              menuButton={
                <MenuButton>
                  <Icon classNames="text-cement-500" name={IconName.MORE_VERTICAL} />
                </MenuButton>
              }>
              <MenuItem
                className="flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100"
                onClick={() => {
                  setShowShareModal(true);
                }}>
                <Icon name={IconName.EXTERNAL_LINK} />
                <Typography variant="button">Share Search</Typography>
              </MenuItem>
              <MenuItem
                className="flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100"
                onClick={() => setShowEditSearchTitleModal(true)}>
                <Icon name={IconName.EDIT} />
                <Typography variant="button">Edit Title</Typography>
              </MenuItem>
              <MenuItem
                className="flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100"
                onClick={() => setShowRemoveSavedSearchesModal(true)}>
                <Icon name={IconName.TRASH} />
                <Typography variant="button">Remove Search</Typography>
              </MenuItem>
            </Menu>
          </div>
        </div>

        <div className="flex items-center justify-between mt-1">
          <Typography className="w-full" variant="body-2">
            {delimitedActiveFiltersValues}
          </Typography>

          {auditInformation && (
            <Typography
              className="hidden md:block audit-info mr-2 text-cement-500"
              variant="body-4">
              {auditInformation}
            </Typography>
          )}
        </div>
        {FEATURE_FLAG_ENABLE_SAVEDSEARCH_EMAIL_ALERTS && (
          <>
            <ToggleSwitch
              leftLabel="Allow Email Notifications"
              toggled={emailToggle}
              classNames="md:hidden text-sm font-normal w-full mr-6 mt-4 pt-4 content-between justify-between border-solid border-t border-cement-200"
              onClick={(toggled) => handleEditEmailAlerts(toggled)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SavedSearchCard;
