import { selectedListingInCart } from '@/contexts/reportCartContext';
import { useCart } from '@/hooks/useReportCart';
import useWindowSize from '@/hooks/useWindowSize';
import { ConstrainedTypography } from '@components/ConstrainedTypography';
import RemoveCircleIcon from '@images/remove-icon.png';
import InfiniteScroll from 'react-infinite-scroll-component';

type VisualizeTableProps = {
  isMediumScreen: boolean;
  visualizeData: selectedListingInCart[];
  updateSelectionModal: (value: boolean) => void;
  fetchData: () => void;
};
const VisualizeTable = ({
  isMediumScreen,
  visualizeData,
  updateSelectionModal,
  fetchData,
}: VisualizeTableProps) => {
  const { cartList, removeFromCart } = useCart();
  const { innerWidth } = useWindowSize();

  return (
    <InfiniteScroll
      dataLength={visualizeData.length - 1}
      next={fetchData}
      hasMore={visualizeData.length < cartList.size}
      loader={<h4>Loading...</h4>}
      height={336}>
      {visualizeData.map((row: selectedListingInCart, index: number) => (
        <div
          className="flex flex-row justify-between items-center px-2 h-[28px]"
          style={{
            background: index % 2 === 0 ? '#EEEDEF' : '#FFFFFF',
            minWidth: !isMediumScreen ? 438 : innerWidth - 48 + 'px',
          }}
          key={row.listingId}>
          <div className="listing-name-tablerow">
            <ConstrainedTypography
              className="flex items-center h-[28px]  text-black  custom-ln-max-width !font-[14px] !font-normal !text-sm !leading-5 !tracking-normal"
              variant="subtitle-1">
              {row?.listingName}
            </ConstrainedTypography>
          </div>
          <div className="flex justify-center items-center h-[28px]">
            <img
              role="none"
              src={RemoveCircleIcon}
              alt="RemoveCircleIcon"
              width="16px"
              height="16px"
              style={{ color: '#D32F2F', cursor: 'pointer' }}
              onClick={() => {
                const selectedListingsCount = cartList.size;
                removeFromCart(row);
                if (selectedListingsCount === 1) {
                  updateSelectionModal(false);
                }
              }}
            />
          </div>
        </div>
      ))}
    </InfiniteScroll>
  );
};

export default VisualizeTable;
