import React, { useState, useEffect, ChangeEventHandler, useMemo } from 'react';

import { FEATURE_FLAG_ENABLE_DISTANCE_FILTER, MAP_STYLES } from '@/config';
import { ActiveFilterQueryParam } from '@/types';
import { Icon, IconName } from '@components/Icon';
import { DataLayersMenuItem } from '@components/Map/DataLayersMenuItem';
import { MapStyleMenuItem } from '@components/Map/MapStyleMenuItem';
import { MapToolbar, MapToolbarItem } from '@components/Map/MapToolbar';
import { useGeolocation } from './hooks/useGeolocation';
import { useMapActions } from './hooks/useMapActions';
import { useMapData } from './hooks/useMapData';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import { TextInput } from '@components/Inputs';
import cn from 'classnames';
import { MapToolbarItemWithInput } from '@components/Map/MapToolbarItemWithInput';
import Button, { ButtonVariant } from '@components/Button';
import { NonLinearSlider } from '@components/Map/MapToolbarItemWithInput/NonLinearSlider';
import debounce from 'lodash/debounce';

interface ToolbarProps {
  className: string;
  isCollectionMap?: boolean;
}

export const Toolbar: React.FC<ToolbarProps> = ({ className, isCollectionMap }) => {
  const isBigScreen = useIsBigScreen();
  const {
    is3dDisabled,
    is3dActive,
    activate3d,
    deactivate3d,
    flyTo,
    setMapView,
    zoomIn,
    zoomOut,
    showAirports,
    setShowAirports,
    showBusStations,
    setShowBusStations,
    showMajorHighways,
    setShowMajorHighways,
    showSubwayStations,
    setShowSubwayStations,
    showTrainStations,
    setShowTrainStations,
    showPorts,
    setShowPorts,
    showIntermodalAir,
    setShowIntermodalAir,
    showIntermodalRail,
    setShowIntermodalRail,
    showPopulation,
    setShowPopulation,
  } = useMapActions();
  const {
    updateSearchParameters,
    distanceOption,
    setDistanceOption,
    distanceFilter,
    distanceFilterTemp,
    setDistanceFilterObj,
    setDistanceFilterTempObj,
  } = useMapData();
  const { updateCurrentLocation } = useGeolocation();
  const [radiusValue, setRadiusValue] = useState<number | undefined | string>(
    distanceFilter?.distance,
  );
  useEffect(() => {
    setRadiusValue(distanceFilter?.distance ?? distanceFilterTemp?.distance);
  }, [distanceFilter, distanceFilterTemp]);

  const _radiusValue = useMemo(() => {
    return Number(radiusValue);
  }, [radiusValue]);

  const updateRadiusValue = (radius: number | undefined, slideComplete: boolean = true) => {
    setRadiusValue(radius);
    if (!radius || radius <= 0) {
      clearRadiusFilter();
      return;
    }
    if (slideComplete) {
      if (distanceFilter) {
        setDistanceFilterObj(null);
      }
      setDistanceFilterTempObj({
        distance: radius,
        longitude: distanceOption?.center[0]!,
        latitude: distanceOption?.center[1]!,
      });
    }
  };

  const applyRadiusValue = () => {
    setDistanceFilterObj({
      distance: _radiusValue,
      longitude: distanceOption?.center[0]!,
      latitude: distanceOption?.center[1]!,
    });
    setTimeout(() => setDistanceFilterTempObj(null), 300);
  };

  const clearRadiusFilter = () => {
    setRadiusValue('');
    if (distanceFilter) {
      setDistanceFilterObj(null);
    }
    if (distanceFilterTemp) {
      setDistanceFilterTempObj(null);
    }
  };

  const onTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const radius = e.target.value as unknown as number;
    updateRadiusValue(radius > 750 ? 750 : radius);
  };

  const debouncedOnChange = debounce(onTextChange, 500);

  const items: MapToolbarItem[] = [
    {
      id: 'live-location-menu-item',
      type: 'simple',
      show: true,
      icon: IconName.LIVE_LOCATION,
      onClick: async () => {
        const currentLocation = await updateCurrentLocation();

        if (!currentLocation) {
          return;
        }

        updateSearchParameters?.([
          [ActiveFilterQueryParam.SEARCHED_LOCATION, null],
          [ActiveFilterQueryParam.MARKET_IDS, null],
          [ActiveFilterQueryParam.SUBMARKET_IDS, null],
          [ActiveFilterQueryParam.INITIAL_VIEW_STATE, null],
        ]);

        const { longitude, latitude } = currentLocation;
        setDistanceOption({
          optionIndex: -1,
          category: 'locations',
          center: [longitude, latitude],
          boundingBox: null,
          displayName: 'Current Location',
          listingId: null,
          types: ['currentLocation'],
        });
        flyTo({ center: [longitude, latitude], zoom: 8 });
      },
    },
    {
      id: 'map-style-menu-item-radius',
      type: 'custom',
      show: isBigScreen && !isCollectionMap,
      element: FEATURE_FLAG_ENABLE_DISTANCE_FILTER ? (
        <MapToolbarItemWithInput
          id={'map-style-menu-item-radius'}
          classNames="w-full"
          icon={IconName.ACTIVE_LOCATION}
          title={'Distance From Pin'}
          showCloseButton={true}
          isButtonDisabled={distanceOption === null}
          disabledTooltipContent={'Search for an address or a listing or select a pin on the map'}
          closeOnMouseOut={distanceFilterTemp === null}
          onCloseCallBack={() => {
            distanceFilterTemp && clearRadiusFilter();
          }}>
          <TextInput
            className="text-input text-base-black max-w-[4.5rem] radius-input"
            placeholder="Distance (Miles)"
            style={{ width: '40px' }}
            type="number"
            label="Distance (Miles)"
            max={750}
            min={0}
            maxLength={3}
            onChange={(e) => {
              // Max length does not work with number type. So alter value manually
              if (Number(e.target.value) > e.target.maxLength) {
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              }
              setRadiusValue(Number(e.target.value));
              // If the value is 0, clear the value immediately without debounce
              if (Number(e.target.value) === 0) {
                clearRadiusFilter();
              } else {
                debouncedOnChange(e);
              }
            }}
            value={_radiusValue === 0 ? '' : _radiusValue}
          />

          <NonLinearSlider defaultValue={_radiusValue} onChange={updateRadiusValue} />
          {!distanceFilterTemp && distanceFilter && distanceFilter.distance > 0 ? (
            <Button
              buttonClassNames={cn(['w-full !px-4 !h-12'])}
              label={'Reset Distance Filter'}
              Icon={<Icon classNames="p-1" name={IconName.REFRESH} />}
              onClick={clearRadiusFilter}
              variant={ButtonVariant.DEFAULT_OUTLINE_DARK_THEME}
            />
          ) : (
            <Button
              buttonClassNames={cn(['w-full !px-4 !h-12'])}
              disabled={!_radiusValue || _radiusValue <= 0}
              label="Apply Distance Filter"
              onClick={applyRadiusValue}
              variant={ButtonVariant.PRIMARY_TWO}
            />
          )}
        </MapToolbarItemWithInput>
      ) : (
        <></>
      ),
    },
    {
      id: 'map-style-menu-item',
      type: 'custom',
      show: true,
      element: <MapStyleMenuItem styles={MAP_STYLES} setMapView={setMapView} />,
    },
    {
      id: 'three-d-menu-item',
      type: 'simple',
      show: true,
      icon: IconName.THREE_D,
      isActive: is3dActive,
      isDisabled: is3dDisabled,
      disabledTooltipContent: 'Click a pin on the map to enable 3-D',
      onClick: () => {
        if (is3dActive) {
          deactivate3d();
        } else {
          activate3d();
        }
      },
    },
  ];

  const ZoomInOutIcons: MapToolbarItem[] = [
    {
      id: 'zoom-in-menu-item',
      type: 'simple',
      show: isBigScreen,
      icon: IconName.ZOOM_IN,
      onClick: zoomIn,
    },
    {
      id: 'zoom-out-menu-item',
      type: 'simple',
      show: isBigScreen,
      icon: IconName.ZOOM_OUT,
      onClick: zoomOut,
    },
  ];

  if (!isCollectionMap) {
    items.splice(2, 0, {
      id: 'data-layers-menu-item',
      type: 'custom',
      show: true,
      element: (
        <DataLayersMenuItem
          showAirports={showAirports}
          setShowAirports={setShowAirports}
          showBusStations={showBusStations}
          setShowBusStations={setShowBusStations}
          showMajorHighways={showMajorHighways}
          setShowMajorHighways={setShowMajorHighways}
          showSubwayStations={showSubwayStations}
          setShowSubwayStations={setShowSubwayStations}
          showTrainStations={showTrainStations}
          setShowTrainStations={setShowTrainStations}
          showPorts={showPorts}
          setShowPorts={setShowPorts}
          showIntermodalAir={showIntermodalAir}
          setShowIntermodalAir={setShowIntermodalAir}
          showIntermodalRail={showIntermodalRail}
          setShowIntermodalRail={setShowIntermodalRail}
          showPopulation={showPopulation}
          setShowPopulation={setShowPopulation}
        />
      ),
    });
  }
  return (
    <div className={className}>
      <MapToolbar items={isBigScreen ? [...items, ...ZoomInOutIcons] : items} />
    </div>
  );
};
