import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import {
  Checkbox,
  CheckboxState,
  DropdownSelector,
  DropdownSelectorColor,
} from '@components/Inputs';
import InputValueClearingPill from '../InputValueClearingPill';
import './CheckboxDropdown.css';
import {
  CheckboxDropdownProps,
  CheckboxDropdownSelectorOption,
  CheckboxDropdownSelectorRenderOptionFunc,
} from './CheckboxDropdown.types';

const CheckboxDropdown: React.FC<CheckboxDropdownProps> = ({
  classNames,
  disabled,
  options,
  onSelect,
  placeholder,
  isSubmarket,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState<CheckboxDropdownSelectorOption[]>(options);
  const [selectedSubmarketPills, setSelectedSubmarketPills] = useState<JSX.Element[] | string>('');

  // Synchronize value changes
  useEffect(() => {
    setDropdownOptions(options);
    updateSelectedLabelsText(options);
  }, [options]);

  const handleClick = (checkedState: CheckboxState, label?: string, value?: string) => {
    const updatedDropdownOptions = dropdownOptions;
    if (label === 'Select All') {
      for (let dropdownOption of updatedDropdownOptions) {
        checkedState === CheckboxState.CHECKED
          ? (dropdownOption.checkedState = CheckboxState.EMPTY)
          : (dropdownOption.checkedState = CheckboxState.CHECKED);
      }
    } else {
      for (let dropdownOption of updatedDropdownOptions) {
        dropdownOption.value === value
          ? dropdownOption.checkedState === CheckboxState.CHECKED
            ? (dropdownOption.checkedState = CheckboxState.EMPTY)
            : (dropdownOption.checkedState = CheckboxState.CHECKED)
          : null;
      }

      const selectAll = updatedDropdownOptions.find((x) => x.label === 'Select All');

      if (selectAll) {
        selectAll.checkedState = updatedDropdownOptions.some(
          (x) => x.label !== 'Select All' && x.checkedState !== CheckboxState.CHECKED,
        )
          ? CheckboxState.EMPTY
          : CheckboxState.CHECKED;
      }
    }
    setDropdownOptions(updatedDropdownOptions);
    updateSelectedLabelsText(updatedDropdownOptions);
  };

  const updateSelectedLabelsText = (options: CheckboxDropdownSelectorOption[]) => {
    const filteredOptions = options.filter(
      (option) => option.checkedState === 'CHECKED' && option.label !== 'Select All',
    );

    const selectedSubmarketPills = filteredOptions.map((option) => {
      const submarketLabel =
        option.label === 'Submarket N/A' ? `${option.parentLabel} - ${option.label}` : option.label;
      const submarketValue = option.value as string;

      return (
        <InputValueClearingPill
          key={`${submarketLabel}-submarket-pill`}
          classNames="relative w-fit"
          onRemove={() => {
            handleClick(CheckboxState.EMPTY, submarketLabel, submarketValue);
            onSelect(CheckboxState.EMPTY, submarketLabel, submarketValue);
          }}
          value={submarketLabel}
        />
      );
    });

    setSelectedSubmarketPills(selectedSubmarketPills);
  };

  const renderOption: CheckboxDropdownSelectorRenderOptionFunc = (option) => {
    return (
      <>
        <Checkbox
          label={
            option.listingsCount != null
              ? option.label + ' (' + option.listingsCount + ')'
              : option.label
          }
          checkedState={option.checkedState as CheckboxState}
          onClick={() => {
            !disabled
              ? handleClick(
                  option.checkedState as CheckboxState,
                  option.label,
                  option.value as string,
                )
              : undefined;
          }}
          name={option.label}
          darkMode={true}
        />
      </>
    );
  };

  return (
    <div className="checkboxDropdown">
      <DropdownSelector
        classNames={cn([
          selectedSubmarketPills.length && '[&_.dropdown-selector-btn]:h-fit',
          classNames,
        ])}
        color={DropdownSelectorColor.WHITE}
        disabled={disabled}
        isCheckboxDropdown
        onSelect={onSelect}
        options={dropdownOptions}
        placeholder={
          selectedSubmarketPills.length ? (
            <div className="w-full relative min-h-[3rem] break-normal flex flex-wrap space-x-2 py-[0.45rem] align-middle">
              {selectedSubmarketPills}
            </div>
          ) : (
            placeholder
          )
        }
        renderOption={renderOption}
        selectedOption={undefined}
        isSubmarket={isSubmarket}
      />
    </div>
  );
};

export default CheckboxDropdown;
