import React from 'react';
import { Link } from 'react-router-dom';

import { Icon, IconName } from '@components/Icon';
import { Typography } from '@components/Typography';
import LinkLogoLight from '@images/link_logo_light.png';
import './Footer.css';

const Footer: React.FC = () => (
  <footer className="bg-slate-500">
    <div className="footer-row">
      <div className="brand">
        <Link to="/">
          <img alt="Link Logistics" src={LinkLogoLight} />
        </Link>
      </div>

      <div className="social-media">
        <Link className="mr-5" to="https://www.linkedin.com/company/linklogistics/" target="_blank">
          <Icon classNames="!h-[1.125rem] !w-[1.25rem]" name={IconName.LINKEDIN} />
        </Link>

        <Link
          className="mr-5"
          to="https://www.facebook.com/Link-Logistics-Real-Estate-193091432290835"
          target="_blank">
          <Icon classNames="!h-[1.25rem] !w-[1.25rem]" name={IconName.FACEBOOK} />
        </Link>

        <Link className="mr-5" to="https://www.instagram.com/linklogisticsre/" target="_blank">
          <Icon name={IconName.INSTAGRAM} />
        </Link>

        <Link to="https://www.youtube.com/channel/UCgFPpo6PcNn56HJe85lBeHA" target="_blank">
          <Icon classNames="!h-[0.875rem] !w-[1.25rem]" name={IconName.YOUTUBE} />
        </Link>
      </div>
    </div>

    <div className="text-cardboard-100 policy-copywright">
      <Link to="https://www.linklogistics.com/privacy-policy/" target="_blank">
        <Typography variant="body-4">Privacy Policy</Typography>
      </Link>

      <Typography className="copywright" variant="body-4">
        © 2023 Link
      </Typography>
    </div>
  </footer>
);

export default Footer;
