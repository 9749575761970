export const NO_I_WANT_TO_CANCEL = 'No, I want to cancel';
export const SELECT_ALL = 'Select All';
export const SHOWING_ALL_RESULTS = 'Showing All Results';
export const SHARE_COLLECTION_QUERY_PARAMETER_NAME = 'sharedId';
export const SHARE_SEARCH_QUERY_PARAMETER_NAME = 'savedsearchid';
export const MAX_CHARACTERS_ALLOWED = 35;
export const MAX_CHARACTERS_ALLOWED_FOR_CONTACT_MESSAGE_BOX = 300;
export const EXCEL_REPORT_CHUNK_SIZE = 4000;
export const CURRENT_LOCATION_STRING = 'Current Location';
export const LISTVIEW_CACHE_KEY = 'last-view';
export const MAINTENCANCE_APPCONFIG_KEY = 'Maintenance';
export const SMOKETESTID_APPCONFIG_KEY = 'SmokeTestID';
export const MAINTENCANCE_END_LABEL_APPCONFIG_KEY = 'MaintenanceEndLabel';
export const LONG_POLLING_SESSION_KEY = 'longpolling_sessions';
export const LONG_POLLING_INTERVAL = 10000;
export const DATA_DISCLAIMER =
  '*While we aim for accuracy, this site may contain errors and is subject to change without notice. We do not guarantee uninterrupted, secure, or error-free access. This site is provided “as-is.” We disclaim all warranties, express or implied, including non-infringement, merchantability, and fitness for a particular purpose.';
export function isiOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export enum PropertyType {
  DEVELOPMENT = 'DEVELOPMENT',
  INDUSTRIAL = 'INDUSTRIAL',
  BUSINESS_PARK = 'BUSINESS_PARK',
  PRE_DEVELOPMENT = 'PRE_DEVELOPMENT',
}

export const isIndustrialOrPark = (propertyType: string | undefined) =>
  propertyType === PropertyType.INDUSTRIAL || propertyType === PropertyType.BUSINESS_PARK;

export const orderDevelopmentPrimaryInfoLargeScreen = [
  'NAME',
  'FULL_ADDRESS',
  'MARKET',
  'CONTACT_US_OR_DATE_AVAILABLE',
  'SUBMARKET',
  'ACREAGE',
  'BUILDING_SQUARE_FOOTAGE',
  'UNIT_SQUARE_FOOTAGE',
  'DIGITAL_ASSETS',
  'BUILDING_STATUS',
  'MODIFIED_AT',
  'PROPERTY_ID',
];
export const orderDevelopmentPrimaryInfoMediumScreen = [
  'NAME',
  'FULL_ADDRESS',
  'MARKET',
  'SUBMARKET',
  'CONTACT_US_OR_DATE_AVAILABLE',
  'ACREAGE',
  'BUILDING_SQUARE_FOOTAGE',
  'UNIT_SQUARE_FOOTAGE',
  'DIGITAL_ASSETS',
  'BUILDING_STATUS',
  'MODIFIED_AT',
  'PROPERTY_ID',
];

export const orderIndustrialParkPrimaryInfoLargeScreen = [
  'NAME',
  'FULL_ADDRESS',
  'MARKET',
  'CONTACT_US_OR_DATE_AVAILABLE',
  'SUBMARKET',
  'BUILDING_SQUARE_FOOTAGE',
  'DIGITAL_ASSETS',
  'BUILDING_STATUS',
  'MODIFIED_AT',
  'PROPERTY_ID',
  'UNIT_SQUARE_FOOTAGE',
];
export const orderIndustrialParkPrimaryInfoMediumScreen = [
  'NAME',
  'FULL_ADDRESS',
  'MARKET',
  'SUBMARKET',
  'CONTACT_US_OR_DATE_AVAILABLE',
  'BUILDING_SQUARE_FOOTAGE',
  'UNIT_SQUARE_FOOTAGE',
  'DIGITAL_ASSETS',
  'BUILDING_STATUS',
  'MODIFIED_AT',
  'PROPERTY_ID',
];
export const orderDevelopmentUnitSpecs = [
  'UNIT_SQUARE_FOOTAGE_RANGE',
  'TRAILER_PARKING_AVAILABLE',
  'UNIT_OFFICE_SQUARE_FOOTAGE_OR_BUILD_TO_SUIT',
  'RAIL_SERVED',
  'CLEAR_HEIGHT',
  'ESFR',
  'LOADING_DOCKS',
  'OUTDOOR_STORAGE',
  'DRIVE_IN_DOORS',
  'TENANCY',
  'TRUCK_COURT_DEPTH',
];
export const orderIndustrialParkUnitSpecs = [
  'UNIT_SQUARE_FOOTAGE',
  'TRUCK_COURT_DEPTH',
  'UNIT_OFFICE_SQUARE_FOOTAGE_OR_BUILD_TO_SUIT',
  'TRAILER_PARKING_AVAILABLE',
  'CLEAR_HEIGHT',
  'RAIL_SERVED',
  'LOADING_DOCKS',
  'TENANCY',
  'DRIVE_IN_DOORS',
];

export const PropertyDataExcelKeyMap = {
  PropertyId: 'Property Id',
  ListingType: 'Listing Type',
  Market: 'Market',
  Submarket: 'Submarket',
  ListingName: 'Listing Name',
  StreetAddress: 'Street Address',
  City: 'City',
  State: 'State',
  Zip: 'Zip',
  Acreage: 'Acreage',
  DateAvailable: 'Date Available',
  AvailableSF: 'Available SF',
  ExpirationDate: 'Expiration Date',
  UnitSF: 'Unit SF',
  BuildingSF: 'Building SF',
  ClearHeight: 'Clear Height',
  RailServed: 'Rail Served',
  OutdoorStorage: 'Outdoor Storage',
  Tenancy: 'Tenancy',
  ESFR: 'ESFR',
  TrailerParking: 'Trailer Parking',
  TruckCourtDepth: 'Truck Court Depth',
  OfficeSF: 'Office SF',
  DriveInDoors: 'Drive In Doors',
  DockDoors: 'Dock Doors',
  LinkMarketOfficer: 'Link Market Officer',
  LinkMarketOfficerPhone: 'Link Market Officer Phone',
  LinkMarketOfficerEmail: 'Link Market Officer Email',
  BrokerName: 'Broker Name',
  BrokerPhone: 'Broker Phone',
  BrokerEmail: 'Broker Email',
  BrokerShop: 'Broker Shop',
};

export enum DevelopmentType {
  label = 'Developments (All Subtypes)',
  value = 'Development',
}
export enum IndustrialType {
  label = 'Industrial',
  value = 'Operational Industrial',
}
export enum ParksType {
  label = 'Parks',
  value = 'Link Parks',
}

export enum BTSType {
  label = 'Build to Suit Opportunities',
}
export enum UCType {
  label = 'Under Construction',
}
export enum NewClassType {
  label = 'New Class A Developments',
}

export const LISTINGS_PER_PAGE = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export const SORTING_ENUM = [
  { label: 'aToZ', sortBy: 0, sortOrder: 0 },
  { label: 'zToA', sortBy: 0, sortOrder: 1 },
  { label: 'SiTox', sortBy: 1, sortOrder: 0 },
  { label: 'SxToi', sortBy: 1, sortOrder: 1 },
];
