import React from 'react';

import { TemplateAttributeKey as AttrKey, TemplateAttributeValue } from '@/types';
import { ConstrainedTypography } from '@components/ConstrainedTypography';
import { Typography } from '@components/Typography';
import PrimarySpecification from './PrimarySpecification';
import ThumbnailWrapper from './ThumbnailWrapper';

export const addChildrenToElement = (element: JSX.Element, primaryInfoElement: JSX.Element) =>
  React.cloneElement(primaryInfoElement, {}, primaryInfoElement.props.children.concat(element));

// returns required element for provided property primary specification argument
export const primarySpecElement = (
  attribute: TemplateAttributeValue,
  propertyType?: string,
  below368Pixels?: boolean,
) => {
  switch (attribute.key) {
    case AttrKey.FullAddress:
      return (
        <Typography
          variant="body-2"
          className="sm:mb-[2.125rem] md:mb-[1.25rem] lg:mb-[2.125rem] text-slate-300">
          {attribute.value}
        </Typography>
      );
    case AttrKey.Name:
      return (
        <ConstrainedTypography
          className="mb-2.5"
          variant="h4"
          width="lg:max-w-[40.625rem] md:max-w-[94%]">
          {attribute.value}
        </ConstrainedTypography>
      );
    case AttrKey.DigitalAssets:
      return <ThumbnailWrapper thumbnailUrl={attribute.value} assetUrls={attribute.rawValue} />;
    case AttrKey.BuildingStatus:
      let buildingStatus;
      switch (attribute.rawValue) {
        case 'BUILD_TO_SUIT':
          buildingStatus = 'build to suit opportunity';
          break;
        case 'NEW_SPECULATIVE_DEVELOPMENT':
          buildingStatus = 'new class a development';
          break;
        case 'UNDER_CONSTRUCTION':
          buildingStatus = 'under construction';
          break;
      }
      return buildingStatus ? (
        <Typography
          variant="body-4"
          className="py-[0.1875rem] mr-2 rounded-sm px-[0.5rem] bg-site-100 capitalize">
          {buildingStatus}
        </Typography>
      ) : (
        <></>
      );
    case AttrKey.DateAvailable:
      return <></>;
    case AttrKey.ModifiedAt:
      return (
        <div className="flex text-slate-200">
          <Typography
            className={below368Pixels ? 'mr-1 !text-[12px]' : 'mr-1'}
            variant="subtitle-3">
            Last Updated:
          </Typography>
          <Typography variant="body-3">{attribute.value}</Typography>
        </div>
      );
    case AttrKey.PropertyId:
      return (
        <div className="flex text-slate-200">
          <Typography
            className={below368Pixels ? 'mr-1 !text-[12px]' : 'mr-1'}
            variant="subtitle-3">
            Property ID:
          </Typography>
          <Typography variant="body-3">{attribute.value}</Typography>
        </div>
      );
    case AttrKey.Market:
    case AttrKey.SquareFootage:
    case AttrKey.Submarket:
    default: // If no case attribute is available, will default here until required case attribute is added
      return <PrimarySpecification label={attribute.label!} value={attribute.value} />;
  }
};
