import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { useMapData } from '@/pages/Map/hooks/useMapData';
import { Icon, IconName } from '@components/Icon';
import InputValueClearingPill from '@components/Inputs/InputValueClearingPill';
import { Typography } from '@components/Typography';
import { MapFilterModalButtonProps } from './MapFilterModalButton.types';
import useDynamicMediaQuery from '@/hooks/useDynamicMediaQuery';
import { useMediaQuery } from 'react-responsive';

const MapFilterModalButton: React.FC<MapFilterModalButtonProps> = ({
  buttonLabel,
  classNames,
  disableMousedownEventListener,
  Modal,
  modalClassNames,
  onRemoveInputValueClearingPill,
  setSqFtRange,
  showFilterCount,
  showInputValueClearingPill,
  sqFtRange,
  maxWidth,
  isFilterButton,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    moreFilterCount,
    dateAvailableFilterCount,
    sqFtAvailableMinFilterCount,
    sqFtAvailableMaxFilterCount,
  } = useMapData();
  const modalButtonRef = useRef<HTMLDivElement>(null);
  const [filterCount, setFilterCount] = useState(0);
  const isDateAvailableVisible = useMediaQuery({ query: '(min-width: 1024px)' });
  const isAvailableSqFtVisible = useMediaQuery({ query: '(min-width: 1301px)' });

  const handleClickOutside = (event: MouseEvent) => {
    if (modalButtonRef.current && !modalButtonRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleInputKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        setIsOpen(false);
        break;
    }
  };

  useEffect(() => {
    if (!disableMousedownEventListener) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    } else {
      document.addEventListener('keydown', handleInputKeyDown);
      return () => {
        document.removeEventListener('keydown', handleInputKeyDown);
      };
    }
  }, []);

  useEffect(() => {
    if (isAvailableSqFtVisible) {
      setFilterCount(
        moreFilterCount -
          sqFtAvailableMinFilterCount -
          sqFtAvailableMaxFilterCount -
          dateAvailableFilterCount,
      );
    } else if (isDateAvailableVisible) {
      setFilterCount(moreFilterCount - dateAvailableFilterCount);
    } else {
      setFilterCount(moreFilterCount);
    }
  }, [moreFilterCount, isAvailableSqFtVisible, isDateAvailableVisible]);

  return (
    <div className={cn(['flex flex-col justify-start relative', classNames])} ref={modalButtonRef}>
      <div
        className={cn([
          'bg-white-100 cursor-pointer relative flex h-12 items-center justify-between px-3 rounded-lg',
          showFilterCount && filterCount > 0 ? 'border border-freight-100' : '',
        ])}
        onClick={() => setIsOpen(!isOpen)}>
        {showFilterCount && filterCount > 0 && (
          <div className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 rounded-full bg-freight-100 translate-x-[50%] translate-y-[-50%]">
            <Typography className="text-base-white" variant="subtitle-2">
              {filterCount}
            </Typography>
          </div>
        )}

        {!isFilterButton ? (
          <Typography
            variant="body-3"
            className={cn([
              'overflow-hidden',
              showInputValueClearingPill && 'text-white-100 text-[0.0625rem]',
            ])}>
            {buttonLabel}
          </Typography>
        ) : useDynamicMediaQuery('(min-width: 1024px)') ? (
          <Typography
            variant="body-3"
            className={cn([
              'overflow-hidden',
              showInputValueClearingPill && 'text-white-100 text-[0.0625rem]',
            ])}>
            {buttonLabel}
          </Typography>
        ) : (
          <Icon name={IconName.FILTER} />
        )}

        {showInputValueClearingPill && onRemoveInputValueClearingPill && (
          <InputValueClearingPill
            onRemove={() => onRemoveInputValueClearingPill()}
            value={buttonLabel}
            maxWidth={maxWidth}
          />
        )}

        {!isFilterButton ? (
          <Icon
            classNames="!h-6 pointer-event-none !w-6 z-20"
            name={isOpen ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
          />
        ) : useDynamicMediaQuery('(min-width: 1024px)') ? (
          <Icon
            classNames="!h-6 pointer-event-none !w-6 z-20"
            name={isOpen ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
          />
        ) : null}
      </div>
      {isOpen && (
        <Modal
          classNames={modalClassNames}
          closeModal={(event: MouseEvent) =>
            disableMousedownEventListener && handleClickOutside(event)
          }
          setSqFtRange={setSqFtRange}
          sqFtRange={sqFtRange}
          setIsOpen={setIsOpen}
        />
      )}
    </div>
  );
};

export default MapFilterModalButton;
