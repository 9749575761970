import './SearchResultSkeleton.css';

const SearchResultSkeleton = () => (
  <div className="search-result-skeleton flex flex-col lg:flex-row flex-auto search-result-skeleton-gap">
    <div className="image-skeleton" />
    <div className="skeleton-container-right">
      <div className="name-skeleton" />
      <div className="address-skeleton" />
      <div className="skeleton-container-attributes">
        <div className="attribute-skeleton" />
        <div className="attribute-skeleton" />
        <div className="attribute-skeleton" />
        <div className="attribute-skeleton" />
      </div>
    </div>
  </div>
);

export default SearchResultSkeleton;
