import React from 'react';
import cn from 'classnames';

// Header Bar Icons
import { ReactComponent as Bookmark } from '@images/bookmark.svg';
import { ReactComponent as Navigator } from '@images/navigator.svg';
import { ReactComponent as Notifications } from '@images/notifications.svg';
import { ReactComponent as UserProfile } from '@images/user-profile.svg';
import { ReactComponent as MapIcon } from '@images/map-icon.svg';
import { ReactComponent as ContactIcon } from '@images/contact-icon.svg';
import { ReactComponent as UserIcon } from '@images/user-icon.svg';

// Map Icons
import { ReactComponent as CurrentLocation } from '../../assets/map/current-location.svg';

// Tool Bar Icons
import { ReactComponent as Comps } from '@images/comps.svg';
import { ReactComponent as DataLayers } from '@images/data-layers.svg';
import { ReactComponent as LiveLocation } from '@images/live-location.svg';
import { ReactComponent as MapSettings } from '@images/map-settings.svg';
import { ReactComponent as PinStyling } from '@images/pin-styling.svg';
import { ReactComponent as PolygonSelect } from '@images/polygon-select.svg';
import { ReactComponent as Search } from '@images/search.svg';
import { ReactComponent as ZoomIn } from '@images/zoom-in.svg';
import { ReactComponent as ZoomOut } from '@images/zoom-out.svg';

// Property Panel Icons
import { ReactComponent as Calendar } from '@images/calendar.svg';
import { ReactComponent as CheckBox } from '@images/check-box.svg';
import { ReactComponent as Download } from '@images/download.svg';
import { ReactComponent as ExternalLink } from '@images/external-link.svg';
import { ReactComponent as Facility } from '@images/facility.svg';
import { ReactComponent as FilePlus } from '@images/file-plus.svg';
import { ReactComponent as FinancialAndInvestment } from '@images/financial-and-investment.svg';
import { ReactComponent as Home } from '@images/home.svg';
import { ReactComponent as LeasingAndOccupancy } from '@images/leasing-and-occupancy.svg';
import { ReactComponent as Media } from '@images/media.svg';
import { ReactComponent as Menu } from '@images/menu.svg';
import { ReactComponent as PropertySpecs } from '@images/property-specs.svg';
import { ReactComponent as Utilities } from '@images/utilities.svg';

// Misc. UI Icons
import { ReactComponent as AlertCircle } from '@images/alert-circle.svg';
import { ReactComponent as AlertTriangle } from '@images/alert-triangle.svg';
import { ReactComponent as ArrowLeft } from '@images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '@images/arrow-right.svg';
import { ReactComponent as Center } from '@images/center.svg';
import { ReactComponent as CheckMark } from '@images/check-mark.svg';
import { ReactComponent as ChevronDown } from '@images/chevron-down.svg';
import { ReactComponent as ChevronLeft } from '@images/chevron-left.svg';
import { ReactComponent as ChevronRight } from '@images/chevron-right.svg';
import { ReactComponent as ChevronUp } from '@images/chevron-up.svg';
import { ReactComponent as Clock } from '@images/clock.svg';
import { ReactComponent as Close } from '@images/close.svg';
import { ReactComponent as CloseLarge } from '@images/close-large.svg';
import { ReactComponent as CollectionMapPin } from '@images/collection-map-pin.svg';
import { ReactComponent as CollectionPin } from '@images/collection-pin.svg';
import { ReactComponent as Dot } from '@images/dot.svg';
import { ReactComponent as Edit } from '@images/edit.svg';
import { ReactComponent as Filter } from '@images/filter.svg';
import { ReactComponent as FullScreenButton } from '@images/full-screen-button.svg';
import { ReactComponent as Folder } from '@images/folder.svg';
import { ReactComponent as HashMark } from '@images/hash-mark.svg';
import { ReactComponent as Link } from '@images/link.svg';
import { ReactComponent as List } from '@images/list.svg';
import { ReactComponent as Mail } from '@images/mail.svg';
import { ReactComponent as MoreVertical } from '@images/more-vertical.svg';
import { ReactComponent as Navigation } from '@images/navigation.svg';
import { ReactComponent as PauseButton } from '@images/pause-button.svg';
import { ReactComponent as Percent } from '@images/percent.svg';
import { ReactComponent as Phone } from '@images/phone.svg';
import { ReactComponent as Pin } from '@images/pin.svg';
import { ReactComponent as PinActive } from '@images/pin-active.svg';
import { ReactComponent as PinActiveLg } from '@images/pin-active-lg.svg';
import { ReactComponent as PinProperty } from '@images/pin-property.svg';
import { ReactComponent as PinDefault } from '@images/pin-default.svg';
import { ReactComponent as PinSecondaryHover } from '@images/pin-secondary-hover.svg';
import { ReactComponent as PlayButton } from '@images/play-button.svg';
import { ReactComponent as Plus } from '@images/plus2.svg';
import { ReactComponent as Refresh } from '@images/refresh.svg';
import { ReactComponent as Reset } from '@images/reset.svg';
import { ReactComponent as Sort } from '@images/sort.svg';
import { ReactComponent as SortAscending } from '@images/sort-asc.svg';
import { ReactComponent as SortDescending } from '@images/sort-desc.svg';
import { ReactComponent as SortNone } from '@images/sort-none.svg';
import { ReactComponent as Square } from '@images/square.svg';
import { ReactComponent as ThreeD } from '@images/three-d.svg';
import { ReactComponent as Trash } from '@images/trash.svg';
import { ReactComponent as User } from '@images/user.svg';
import { ReactComponent as Star } from '@images/star.svg';
import { ReactComponent as StarFilled } from '@images/star-filled.svg';
import { ReactComponent as Trello } from '@images/trello.svg';
import { ReactComponent as Tick } from '@images/tick.svg';
import { ReactComponent as GreyUserIcon } from '@images/grey-user-icon.svg';
import { ReactComponent as QuestionCircle } from '@images/question-circle.svg';
import { ReactComponent as Eye } from '@images/eye.svg';
import { ReactComponent as Export } from '@images/export.svg';
import { ReactComponent as CameraIcon } from '@images/camera-icon.svg';
import { ReactComponent as VideoIcon } from '@images/video-icon.svg';
import { ReactComponent as VideoPlayButton } from '@images/video-play-button.svg';
import { ReactComponent as PopulationLegendHorizontal } from '@images/population-legend-horizontal.svg';
import { ReactComponent as PopulationLegendVertical } from '@images/population-legend-vertical.svg';

import { IconProps } from './Icon.types';

// Social Media Icons
import { ReactComponent as LinkedIn } from '@images/linkedin.svg';
import { ReactComponent as Facebook } from '@images/facebook.svg';
import { ReactComponent as Instagram } from '@images/instagram.svg';
import { ReactComponent as Twitter } from '@images/twitter.svg';
import { ReactComponent as Youtube } from '@images/youtube.svg';
import { ReactComponent as AddFolder } from '@images/add-folder.svg';
import { ReactComponent as ExportMedium } from '@images/export-medium.svg';

import { ReactComponent as CircleCheck } from '@images/circle-check.svg';
import { ReactComponent as CircleError } from '@images/circle-error.svg';
import { ReactComponent as CircleInfo } from '@images/circle-info.svg';
import { ReactComponent as CircleWarning } from '@images/circle-warning.svg';
import { ReactComponent as AppSwitcher } from '@images/app-switcher.svg';
import { ReactComponent as AppSwitcherHover } from '@images/app-switcher-hover.svg';
import { ReactComponent as ActiveLocation } from '@images/active-location.svg';

const iconComponents = {
  addFolder: AddFolder,
  alertCircle: AlertCircle,
  alertTriangle: AlertTriangle,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  bookmark: Bookmark,
  calendar: Calendar,
  center: Center,
  checkBox: CheckBox,
  checkMark: CheckMark,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  clock: Clock,
  close: Close,
  closeLarge: CloseLarge,
  collectionMapPin: CollectionMapPin,
  collectionPin: CollectionPin,
  comps: Comps,
  currentLocation: CurrentLocation,
  dataLayers: DataLayers,
  dot: Dot,
  download: Download,
  edit: Edit,
  externalLink: ExternalLink,
  exportMedium: ExportMedium,
  facebook: Facebook,
  filePlus: FilePlus,
  facility: Facility,
  filter: Filter,
  financialAndInvestment: FinancialAndInvestment,
  folder: Folder,
  fullScreenButton: FullScreenButton,
  hashMark: HashMark,
  home: Home,
  instagram: Instagram,
  leasingAndOccupancy: LeasingAndOccupancy,
  link: Link,
  list: List,
  linkedin: LinkedIn,
  liveLocation: LiveLocation,
  mail: Mail,
  mapSettings: MapSettings,
  media: Media,
  menu: Menu,
  moreVertical: MoreVertical,
  navigation: Navigation,
  navigator: Navigator,
  notifications: Notifications,
  pauseButton: PauseButton,
  percent: Percent,
  phone: Phone,
  pin: Pin,
  pinActive: PinActive,
  pinActiveLg: PinActiveLg,
  pinDefault: PinDefault,
  pinSecondaryHover: PinSecondaryHover,
  pinStyling: PinStyling,
  playButton: PlayButton,
  plus2: Plus,
  polygonSelect: PolygonSelect,
  propertySpecs: PropertySpecs,
  reset: Reset,
  search: Search,
  sort: Sort,
  sortAscending: SortAscending,
  sortDescending: SortDescending,
  sortNone: SortNone,
  square: Square,
  star: Star,
  starFilled: StarFilled,
  threeD: ThreeD,
  trash: Trash,
  user: User,
  userProfile: UserProfile,
  utilities: Utilities,
  zoomIn: ZoomIn,
  zoomOut: ZoomOut,
  trello: Trello,
  twitter: Twitter,
  youtube: Youtube,
  tick: Tick,
  greyUserIcon: GreyUserIcon,
  questionCircle: QuestionCircle,
  eye: Eye,
  circleCheck: CircleCheck,
  circleInfo: CircleInfo,
  circleWarning: CircleWarning,
  circleError: CircleError,
  mapIcon: MapIcon,
  contactIcon: ContactIcon,
  appSwitcher: AppSwitcher,
  appSwitcherHover: AppSwitcherHover,
  userIcon: UserIcon,
  refresh: Refresh,
  export: Export,
  cameraIcon: CameraIcon,
  videoIcon: VideoIcon,
  videoPlayButton: VideoPlayButton,
  activeLocation: ActiveLocation,
  pinProperty: PinProperty,
  populationLegendHorizontal: PopulationLegendHorizontal,
  populationLegendVertical: PopulationLegendVertical,
};

/**
 * 1.5rem (24px) is the over default size for most of the app's icons. Simply override the default size by passing
 * custom size(s) via the classNames prop (Make sure to set the size(s) as important to override default sizes)
 */

const Icon: React.FC<IconProps> = ({ classNames, name }) => {
  const Icon = iconComponents[name];

  return <Icon className={cn(['h-[1.5rem] w-[1.5rem]', classNames])} />;
};

export default Icon;
