import React, { useMemo } from 'react';

import { Typography } from '@components/Typography';
import './MapCluster.css';
interface MapClusterProps {
  count: number;
  onClick: () => void;
}

export const MapCluster: React.FC<MapClusterProps> = ({ count, onClick }) => {
  const abbreviatedNumber = useMemo(
    () => (count > 999 ? (count / 1000).toPrecision(2) + 'k' : count),
    [count],
  );
  return (
    <div
      className="flex justify-center w-12 h-12 border-4 rounded-full cursor-pointer border-base-white bg-freight-100  text-base-white  hover:bg-base-black font-medium items-center cluster-shadow"
      onClick={onClick}>
      <Typography className="block" variant="subtitle-1">
        {abbreviatedNumber}
      </Typography>
    </div>
  );
};

export default MapCluster;
