import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { ListingExcelRow } from '@/types/excel';
import { EXCEL_REPORT_CHUNK_SIZE, PropertyDataExcelKeyMap } from '@/utilities/constants';
import { renameKeysDeep, ternaryOperation } from '@/utilities/functions';
import { formatNumberWithCommas } from '@/utilities/textHelpers';
import { ButtonVariant } from '@components/Button';
import ConfirmationModal from '@components/ConfirmationModal';
import Spinner from '@components/LoadingSpinner/Spinner';
import FileSaver from 'file-saver';
import React, { useState } from 'react';
import XLSX from 'sheetjs-style';

export interface ExportModalProps {
  listingIds: string[];
  showExportModal: boolean;
  updateShowExportModal: (state: boolean) => void;
  exportLength: number;
}

const ExportModal: React.FC<ExportModalProps> = ({
  listingIds,
  showExportModal,
  updateShowExportModal,
  exportLength,
}) => {
  const [exporting, setExporting] = useState<boolean>(false);
  const { getDataForExcel } = useApiClient();
  const { addToast } = useToast();

  const descriptionText =
    exportLength === 1
      ? `1 record will be exported as an Excel file.`
      : `${formatNumberWithCommas(exportLength)} records will be exported as an Excel file.`;
  const exportText = ternaryOperation(
    exportLength === 1,
    `Export 1 record`,
    ternaryOperation(
      exporting,
      `Exporting ${formatNumberWithCommas(exportLength)} records`,
      `Export ${formatNumberWithCommas(exportLength)} records`,
    ),
  );
  const exportingSpinner = exporting ? <Spinner /> : <></>;

  const handleClose = () => {
    updateShowExportModal(false);
    setExporting(false);
  };

  const getExcelData = async (listingIds: string[]) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let allListingData: ListingExcelRow[] = [];
    for (let i = 0; i < listingIds.length; i += EXCEL_REPORT_CHUNK_SIZE) {
      const chunk = listingIds.slice(i, i + EXCEL_REPORT_CHUNK_SIZE);
      // Call API for chunk
      const excelChunk = await getDataForExcel(chunk);
      allListingData = allListingData.concat(excelChunk);
    }
    if (allListingData.length > 0) {
      const _allListingData = allListingData.map((listingData) => {
        const updatedObject = renameKeysDeep(listingData, PropertyDataExcelKeyMap);
        return updatedObject;
      });
      // create excel stream and assign to data
      const ws = XLSX.utils.json_to_sheet(_allListingData);
      let index = 1;
      let cellNumber = '';
      let subIndex = 1;
      Object.keys(allListingData[0]).forEach((key) => {
        if (index > 26) {
          cellNumber = `A${String.fromCharCode(subIndex + 64)}1`;
          subIndex++;
        } else {
          cellNumber = `${String.fromCharCode(index + 64)}1`;
        }
        if (ws[cellNumber]) {
          // Customize the header row font
          ws[cellNumber].s = {
            font: {
              bold: true,
            },
          };
          index++;
        }
      });
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      return new Blob([excelBuffer], { type: fileType });
    }
  };

  const handleDownloadExcelReport = async (listingIds: string[]) => {
    setExporting(true);
    const fileExtension = '.xlsx';
    try {
      const data = await getExcelData(listingIds);
      if (data) {
        // Save file
        FileSaver.saveAs(data, `download-excel-report-${new Date().getTime()}${fileExtension}`);
        addToast({
          id: `download-excel-report-${new Date().getTime()}`,
          description: `Excel Report Downloaded`,
          title: 'Success',
          type: 'success',
        });
      } else {
        addToast({
          id: `download-excel-report-${new Date().getTime()}`,
          description: `Failed to generate excel report`,
          title: 'Error',
          type: 'error',
        });
      }
    } catch (e) {
      addToast({
        id: `download-excel-report-${new Date().getTime()}`,
        description: `Failed to generate excel report`,
        title: 'Error',
        type: 'error',
      });
    }
    setExporting(false);
    updateShowExportModal(false);
  };

  return (
    <ConfirmationModal
      header="Export"
      primaryBtnLabel={exportText}
      primaryBtnIcon={exportingSpinner}
      primaryBtnDisabled={exporting}
      primaryBtnOnClick={() => handleDownloadExcelReport(listingIds)}
      secondaryBtnLabel="Cancel"
      secondaryBtnOnClick={handleClose}
      secondaryBtnDisabled={exporting}
      onClose={handleClose}
      secondaryBtnVariant={ButtonVariant.DEFAULT_OUTLINE}
      show={showExportModal}
      subText={
        <>
          {descriptionText}
          <br /> Are you sure you want to export?
        </>
      }
    />
  );
};

export default ExportModal;
