import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { LONG_POLLING_SESSION_KEY } from '@/utilities/constants';
import { downloadURI } from '@/utilities/fileDownloadHelper';
import { NextAction } from '@components/Toaster/Toaster';
import { createContext, useEffect, useMemo, useRef } from 'react';

interface FileLPRequest {
  fileId: string;
  intervalInMs: number;
  timer?: NodeJS.Timer;
}

interface LongPollingContext {
  longpollFileAndDownload: (fileLPRequest: FileLPRequest) => void;
}

export const LongPollingContext = createContext<LongPollingContext>({} as LongPollingContext);

const fileExistsInSession = (newRequest: FileLPRequest) => {
  const session = getFileFromSession(newRequest);
  return !!session;
};

const getAllFilesFromSession = () => {
  const currentSessions = sessionStorage.getItem(LONG_POLLING_SESSION_KEY);
  if (currentSessions) {
    return JSON.parse(currentSessions) as FileLPRequest[];
  }
  return [];
};

const getFileFromSession = (newRequest: FileLPRequest) => {
  const currentSessions = sessionStorage.getItem(LONG_POLLING_SESSION_KEY);
  if (currentSessions) {
    const parsedSessions = JSON.parse(currentSessions) as FileLPRequest[];
    if (parsedSessions?.length > 0) {
      const session = parsedSessions.find((s) => s.fileId === newRequest.fileId);
      return session;
    }
  }
};

const addFileToSession = (newRequest: FileLPRequest) => {
  if (!fileExistsInSession(newRequest)) {
    const parsedSessions = getAllFilesFromSession();
    parsedSessions.push(newRequest);
    sessionStorage.setItem(LONG_POLLING_SESSION_KEY, JSON.stringify(parsedSessions));
  }
};

const deleteFileFromSession = (newRequest: FileLPRequest) => {
  let parsedSessions = getAllFilesFromSession();
  parsedSessions = parsedSessions.filter((s) => s.fileId !== newRequest.fileId);
  sessionStorage.setItem(LONG_POLLING_SESSION_KEY, JSON.stringify(parsedSessions));
};

export const LongPollingProvider: React.FC<any> = ({ children }) => {
  const { checkFileExists } = useApiClient();
  const { addToast } = useToast();
  const initialized = useRef(false);

  const reset = (request: FileLPRequest) => {
    if (request) {
      clearInterval(request.timer);
      deleteFileFromSession(request);
    }
  };

  useEffect(() => {
    // Check if the component is already initialized.
    // This check is added to fix the issue of double-renders in dev server because of StrictMode.
    if (!initialized.current) {
      initialized.current = true;
      const sessions = getAllFilesFromSession();
      sessions.forEach((session) => {
        reset(session);
        longpollFileAndDownload(session);
      });
    }
  }, []);

  const longpollFileAndDownload = (fileLPRequest: FileLPRequest) => {
    addFileToSession(fileLPRequest);
    const inter = setInterval(async () => {
      const request = { ...fileLPRequest, timer: inter } as FileLPRequest;
      try {
        const d = new Date();
        console.log(
          `attempt at ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`,
          request.fileId,
        );
        // API Call
        let fileResponse = await checkFileExists(request.fileId);

        if (fileResponse.exists === true && fileResponse.sasUrl) {
          downloadURI(fileResponse.sasUrl);
          addToast({
            id: `download-vacancy-report-${new Date().getTime()}`,
            description: `National availability report downloaded successfully`,
            title: 'Success',
            type: 'success',
          });
          reset(request);
        }
      } catch (error) {
        console.log(error);
        reset(request);
        addToast({
          id: `download-vacancy-report-${new Date().getTime()}`,
          description: `Failed to generate national availability report`,
          title: 'Error',
          type: 'error',
          nextAction: { type: 'label', label: 'Please try again' } as NextAction,
        });
      }
    }, fileLPRequest.intervalInMs);

    // If the file doesn't get downloaded even after 5 mins, remove the API call timer
    setTimeout(() => {
      clearInterval(inter);
    }, 300000);
  };

  const contextValue = useMemo(() => {
    return { longpollFileAndDownload };
  }, []);

  return <LongPollingContext.Provider value={contextValue}>{children}</LongPollingContext.Provider>;
};
