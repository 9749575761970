import cn from 'classnames';
import React from 'react';

import { Icon, IconName } from '@components/Icon';

export type TransparentCheckboxProps = {
  isChecked: boolean;
  onCheck: () => void;
  onUnCheck: () => void;
};

const TransparentCheckbox: React.FC<TransparentCheckboxProps> = ({
  isChecked,
  onCheck,
  onUnCheck,
}) => {
  const toggleCheckbox = () => {
    isChecked ? onUnCheck() : onCheck();
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggleCheckbox();
  };

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id="myCheckbox"
        className="hidden"
        checked={isChecked}
        onChange={toggleCheckbox}
      />
      <label htmlFor="myCheckbox" className="flex items-center cursor-pointer">
        <div
          onClick={handleClick}
          className="border rounded-sm appearance-none cursor-pointer border-white-100">
          <div
            className={cn([
              'w-5 h-5 text-white-100 pt-[0.1875rem] pl-[0.1875rem]',
              isChecked ? 'bg-rust-100 ' : ' bg-white-100 opacity-50',
            ])}>
            <Icon classNames="w-[0.875rem] h-[0.875rem]" name={IconName.TICK} />
          </div>
        </div>
      </label>
    </div>
  );
};

export default TransparentCheckbox;
