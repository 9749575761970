import React from 'react';
import cn from 'classnames';

import { ButtonVariant } from '@components/Button';
import ConfirmationModal, { BtnsDirection } from '@components/ConfirmationModal';
import { Icon, IconName } from '@components/Icon';
import OverflowAndScrollBarHiddenWrapper from '@components/OverflowHiddenWrapper';

import UnavailableListingCard from './UnavailableListingCard';
import { Favorite } from '@/types';
import { Typography } from '@components/Typography';

type RemoveUnavailableListingsModalProps = {
  unavailableListings: Favorite[];
  onClose: () => void;
  onConfirm: () => Promise<void>;
  collectionName?: string;
};

const RemoveUnavailableListingsModal: React.FC<RemoveUnavailableListingsModalProps> = ({
  onClose,
  onConfirm,
  unavailableListings,
  collectionName,
}) => {
  const header = 'Listings No Longer Available';
  const subtext = `The following listings are no longer available. Would you like to remove these listings from ${
    collectionName ?? 'your favorites'
  }?`;

  return (
    <ConfirmationModal
      btnsDirection={BtnsDirection.COL}
      classNames="lg:!w-[30.375rem]"
      header={header}
      primaryBtnIcon={<Icon name={IconName.TRASH} />}
      primaryBtnLabel="Yes, Remove Unavailable Listings"
      primaryBtnOnClick={onConfirm}
      primaryBtnVariant={ButtonVariant.PRIMARY_ONE}
      onClose={onClose}
      show
      secondaryBtnLabel="No, Keep Unavailable Listings"
      secondaryBtnOnClick={onClose}>
      <Typography variant="body-2">{subtext}</Typography>
      <OverflowAndScrollBarHiddenWrapper classNames="mt-6" heightRem={20}>
        {unavailableListings.map((favorite, index) => {
          return (
            <UnavailableListingCard
              key={favorite.listingId}
              classNames={cn([index && 'mt-4', ''])}
              favoriteId={favorite.favoriteId}
              favoriteName={favorite.name}
              favoriteSq={favorite.size}
              favoriteAddress={favorite.addressLine1}
              thumbnailImageUrl={favorite.thumbnailImageUrl ?? null}
            />
          );
        })}
      </OverflowAndScrollBarHiddenWrapper>
    </ConfirmationModal>
  );
};

export default RemoveUnavailableListingsModal;
