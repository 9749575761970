import React from 'react';
import { Typography } from '@components/Typography';
import { LISTINGS_PER_PAGE } from '@/utilities/constants';
import PageNavigator from './PageNavigationButtons';
import { PaginationProps } from './Pagination.types';
import { DropdownSelector, DropdownSelectorOption } from '@components/Inputs';
import useDynamicMediaQuery from '@/hooks/useDynamicMediaQuery';
import './Pagination.css';
import cn from 'classnames';
const Pagination: React.FC<PaginationProps> = ({
  pageSize,
  totalRows,
  selectedPageNumber,
  dataCount,
  updatePageSize,
  updateSelectedPageNumber,
  bottom,
}) => {
  const handlePageSizeChange = (size: any) => {
    updatePageSize(size);
  };

  const isSmallDevice = useDynamicMediaQuery('(max-width: 768px)');

  let displayValue;
  if (totalRows < pageSize) {
    displayValue = totalRows;
  } else if (dataCount < pageSize) {
    displayValue = pageSize * selectedPageNumber - pageSize + dataCount;
  } else {
    displayValue = pageSize * selectedPageNumber;
  }
  return (
    <div
      className={cn(
        'pagination-main-div h-[4.5rem] md:justify-start',
        totalRows < 25 ? 'sm:justify-around' : 'sm:justify-between',
        isSmallDevice ? 'sticky' : '',
        bottom ? 'bottom-[90px]' : '',
      )}>
      {isSmallDevice ? (
        <>
          {totalRows < 25 ? (
            <>
              <PageNavigator
                updateSelectedPageNumber={(page) => updateSelectedPageNumber(page)}
                selectedPageNumber={selectedPageNumber}
                totalRows={totalRows}
                pageSize={pageSize}
              />
              <div className="pagination-records-count">
                <Typography className="text-sm font-semibold" variant="body-2">
                  {`${pageSize * selectedPageNumber + 1 - pageSize} - ${displayValue} `}{' '}
                  <span style={{ fontWeight: '100' }}>{' of '}</span>
                  {` ${totalRows}   Listings`}
                </Typography>
              </div>
            </>
          ) : (
            <div className="pagination-select-page-size left-section !inline-grid">
              <Typography className="text-sm font-semibold" variant="body-2">
                {`${pageSize * selectedPageNumber + 1 - pageSize} - ${displayValue} `}{' '}
                <span style={{ fontWeight: '100' }}>{' of '}</span>
                {` ${totalRows}   Listings`}
              </Typography>
              <PageNavigator
                updateSelectedPageNumber={(page) => updateSelectedPageNumber(page)}
                selectedPageNumber={selectedPageNumber}
                totalRows={totalRows}
                pageSize={pageSize}
              />
            </div>
          )}
          <div className="pagination-select-page-size right-section !inline-grid">
            <Typography className="text-sm font-normal" variant="body-2">
              {`Listings per page: `}{' '}
            </Typography>
            <DropdownSelector
              classNames="listings-dropdown justify-between w-[4.5rem] h-10 !relative !left-2.5 sm:ml-7 md:ml-0"
              options={LISTINGS_PER_PAGE}
              onSelect={(selectedOption) => {
                const option = selectedOption as DropdownSelectorOption;
                handlePageSizeChange(option.value);
              }}
              selectedOption={{ label: pageSize.toString(), value: pageSize }}
            />
          </div>
        </>
      ) : (
        <>
          <PageNavigator
            updateSelectedPageNumber={(page) => updateSelectedPageNumber(page)}
            selectedPageNumber={selectedPageNumber}
            totalRows={totalRows}
            pageSize={pageSize}
          />
          <div className="pagination-records-count">
            <Typography className="text-sm font-semibold" variant="body-2">
              {`${pageSize * selectedPageNumber + 1 - pageSize} - ${displayValue} `}{' '}
              <span style={{ fontWeight: '100' }}>{' of '}</span>
              {` ${totalRows}   Listings`}
            </Typography>
          </div>
          <div className="pagination-select-page-size">
            <Typography className="text-sm font-normal" variant="body-2">
              {`Listings per page: `}{' '}
            </Typography>
          </div>
          <div className="pagination-select-page-size">
            <DropdownSelector
              classNames="justify-between w-[4.5rem] h-10"
              buttonWrapperClassName="!px-2"
              options={LISTINGS_PER_PAGE}
              onSelect={(selectedOption) => {
                const option = selectedOption as DropdownSelectorOption;
                handlePageSizeChange(option.value);
              }}
              selectedOption={{ label: pageSize.toString(), value: pageSize }}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default Pagination;
