import React from 'react';
import cn from 'classnames';
import './TextInput.css';
import { InputProps } from '../inputs.types';
import { decodeString } from '@/utilities/textHelpers';
import { Typography } from '@components/Typography';

const TextInput: React.FC<InputProps> = (props) => {
  const {
    classNames,
    error,
    errorMessage,
    value,
    maxLength,
    maxCharactersRemaining,
    showRemainingCharactersText,
    errorClasses,
    label,
    type,
    ...rest
  } = props;

  const inputClassNames = cn(['text-input', error && 'text-input--error']);
  const errorMessageClassNames = cn(['absolute text-freight-200', error && errorClasses]);

  return (
    <div className={cn(['relative text-sm', classNames])}>
      {label && (
        <Typography variant="body-5" className="mb-1">
          {label}
        </Typography>
      )}
      <input
        className={inputClassNames}
        type={type ?? 'text'}
        value={typeof value === 'string' ? decodeString(value) : value}
        maxLength={maxLength}
        {...rest}
      />
      {showRemainingCharactersText && (
        <p
          className={cn([
            maxCharactersRemaining === 0
              ? 'pt-1.5 text-right text-xs text-freight-200'
              : 'pt-1.5 text-xs text-right',
          ])}>
          {maxCharactersRemaining} Characters Remaining
        </p>
      )}
      {error && <p className={errorMessageClassNames}>{errorMessage}</p>}
    </div>
  );
};

export default TextInput;
