import cn from 'classnames';
import React from 'react';

import { ConstrainedTypography } from '@components/ConstrainedTypography';
import { Icon, IconName } from '@components/Icon';
import { InputValueClearingPillProps } from './InputValueClearingPill.types';

const InputValueClearingPill: React.FC<InputValueClearingPillProps> = ({
  classNames,
  onClick,
  onRemove,
  value,
  maxWidth,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onRemove();
    event.stopPropagation(); // used to prevent event bubbling i.e. prevent outer input onClick from firing an event
  };

  return (
    <div
      className={cn([
        'absolute bg-cement-100 flex space-x-2 h-8 items-center px-1.5 rounded-lg z-10 my-[0.5px]',
        classNames,
      ])}
      onClick={onClick}>
      <ConstrainedTypography variant="body-2" width={maxWidth}>
        {value}
      </ConstrainedTypography>

      <div
        className="flex items-center justify-center w-5 h-5 rounded-full bg-cement-100 hover:bg-cement-200 hover:cursor-pointer text-slate-200 hover:text-slate-400"
        onClick={handleClick}>
        <Icon classNames="!h-[0.625rem] !w-[0.625rem]" name={IconName.CLOSE_LARGE} />
      </div>
    </div>
  );
};

export default InputValueClearingPill;
