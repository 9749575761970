import LandingPage from '@/pages/LandingPage';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

export function Authentication() {
  const { error } = useMsalAuthentication(InteractionType.Redirect);
  const { inProgress } = useMsal();

  if (error) {
    return <>{error.message}</>;
  }

  let loadingMessage = 'Checking authentication...';

  if (inProgress === InteractionStatus.Login) {
    loadingMessage = 'Logging you in to Property Search...';
  } else if (inProgress === InteractionStatus.Logout) {
    loadingMessage = 'Logging you out of Property Search...';
  } else if (inProgress === InteractionStatus.HandleRedirect) {
    loadingMessage = 'Redirecting you to Property Search...';
  }

  return <>{loadingMessage}</>;
}

export function UserAuth() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    return <Authentication />;
  } else {
    navigate('/');
    return <LandingPage />;
  }
}
