import {
  MinMaxRangeDefault as RangeDefault,
  MinMaxRangeType,
  SetMinMaxOptionRangeFunc,
} from './MinMaxSelector.types';

export const hasRangeError = (oppositeRangeValue: string, rangeType: string, value: string) =>
  !!value &&
  ((rangeType === MinMaxRangeType.MAX && parseInt(value, 10) < parseInt(oppositeRangeValue, 10)) ||
    (rangeType === MinMaxRangeType.MIN && parseInt(value, 10) > parseInt(oppositeRangeValue, 10)));

export const sanitizeMinMaxValue = (value: string) => {
  return !value ? '' : value;
};

export const updateSearchResults = (
  inputValue: string,
  defaultData: string[],
  oppositeInputValue: string,
  rangeDefault: RangeDefault,
) =>
  defaultData.reduce((updatedData, datum) => {
    const isDefaultValue = datum === RangeDefault.NO_MAX || datum === RangeDefault.NO_MIN;
    const isWithinRange =
      !oppositeInputValue ||
      (rangeDefault === RangeDefault.NO_MAX
        ? parseInt(datum, 10) >= parseInt(oppositeInputValue, 10)
        : parseInt(datum, 10) <= parseInt(oppositeInputValue, 10));
    const showDefaultDatum = isDefaultValue && !inputValue;
    const showNonDefaultDatum = !isDefaultValue && isWithinRange;

    if (showNonDefaultDatum || showDefaultDatum) updatedData.push(datum);

    return updatedData;
  }, [] as string[]);

export const setMinMaxOptionRange: SetMinMaxOptionRangeFunc =
  ({ setRange, queryParameter, updateSearchParameters }) =>
  (value, rangeType) => {
    const sanitizedValue = sanitizeMinMaxValue(value);

    setRange &&
      setRange((prevRange) =>
        rangeType ? { ...prevRange, [rangeType]: sanitizedValue } : prevRange,
      );

    queryParameter &&
      updateSearchParameters &&
      updateSearchParameters([[`${queryParameter}_${rangeType}`, sanitizedValue]]);
  };
