export const ternaryOperation = (condition: any, ifTrue: any, ifFalse: any) => {
  return condition ? ifTrue : ifFalse;
};

export const sortBy = (valuesToSort: any[] | undefined, sortOrder: string | any[]) => {
  const ordering: any = {};
  for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;
  if (valuesToSort && valuesToSort?.length > sortOrder.length) {
    for (let i = 0; i < valuesToSort.length; i++) {
      const val = ordering[valuesToSort[i].key];
      if (typeof val !== 'number') ordering[valuesToSort[i].key] = sortOrder.length + i;
    }
  }
  valuesToSort?.sort(function (a, b) {
    return ordering[a.key] - ordering[b.key] || a.key.localeCompare(b.key);
  });
  return valuesToSort;
};

export const renameKeysDeep = (obj: any, keyMap: { [x: string]: any }): any[] => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => renameKeysDeep(item, keyMap));
  }

  const renamedObj: any = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = keyMap[key] || key;
      renamedObj[newKey] = renameKeysDeep(obj[key], keyMap);
    }
  }
  return renamedObj;
};
