import { addYears, format, getYear } from 'date-fns';
import { DateFormatter } from 'react-day-picker';
import { DatePickerTimeFrames } from './DatePicker.types';

export const timeFrameOptions = [
  {
    label: 'Available Now',
    value: '0',
  },
  {
    label: 'Next 30 Days',
    value: '30',
  },
  {
    label: 'Next 60 Days',
    value: '60',
  },
  {
    label: 'Next 90 Days',
    value: '90',
  },
  {
    label: 'Next 6 Months',
    value: '180',
  },
  {
    label: 'Next 12 Months',
    value: '365',
  },
];

export const sixMonthsTimeFrameOptions = [
  {
    label: 'Next 6 Months',
    value: '181',
  },
  {
    label: 'Next 6 Months',
    value: '182',
  },
  {
    label: 'Next 6 Months',
    value: '183',
  },
  {
    label: 'Next 6 Months',
    value: '184',
  },
  {
    label: 'Next 6 Months',
    value: '185',
  },
];

export const formatWeekdayName: DateFormatter = (date: Date) => format(date, 'EEEEE');

export const getFromYear = () => getYear(new Date());

export const getToYear = () => getYear(addYears(new Date(), 3));

export const getLabelFromTimeFrameValue = (value: DatePickerTimeFrames) => {
  let newInputValue = timeFrameOptions.find((option) => option.value === value)!;
  if (newInputValue === undefined) {
    newInputValue = sixMonthsTimeFrameOptions.find((option) => option.value === value)!;
  }
  return newInputValue.label;
};
