import { FlyToOptions, LngLatLike } from 'mapbox-gl';
import React, { createContext, useState } from 'react';
import { FitBoundsOptions, LngLatBoundsLike, useMap } from 'react-map-gl';

import { MAP_DEFAULT_BOUNDS, MAP_STYLES } from '@/config';
import { Listing } from '@/types';

interface MapActionsContextValue {
  fitBounds: (bounds: LngLatBoundsLike, options?: FitBoundsOptions) => Promise<void>;
  flyTo: (options: FlyToOptions) => Promise<void>;
  panTo: (lngLat: LngLatLike) => Promise<void>;
  resize: () => void;
  reset: () => Promise<void>;
  zoomIn: () => void;
  zoomOut: () => void;
  resultCardHover: Listing | null;
  setResultCardHover: (listing: Listing | null) => void;
  secondaryHover: Listing | null;
  setSecondaryHover: (listing: Listing | null) => void;

  setMapView: (style: string) => void;
  mapStyle: string;
  setMapStyle: (mapStyle: string) => void;
  resetMapToggles: () => void;
  is3dActive: boolean;
  setIs3dActive: (is3dActive: boolean) => void;
  is3dDisabled: boolean;
  setIs3dDisabled: (is3dDisabled: boolean) => void;
  activate3d: () => void;
  deactivate3d: () => void;

  showAirports: boolean;
  setShowAirports: (showAirports: boolean) => void;
  showBusStations: boolean;
  setShowBusStations: (showBusStations: boolean) => void;
  showMajorHighways: boolean;
  setShowMajorHighways: (showMajorHighways: boolean) => void;
  showSubwayStations: boolean;
  setShowSubwayStations: (showSubwayStations: boolean) => void;
  showTrainStations: boolean;
  setShowTrainStations: (showTrainStations: boolean) => void;
  showPorts: boolean;
  setShowPorts: (showPorts: boolean) => void;
  showViewList: boolean;
  setShowViewList: (showViewList: boolean) => void;
  showIntermodalAir: boolean;
  setShowIntermodalAir: (showIntermodalAir: boolean) => void;
  showIntermodalRail: boolean;
  setShowIntermodalRail: (showIntermodalRail: boolean) => void;
  showPopulation: boolean;
  setShowPopulation: (setShowPopulation: boolean) => void;
}

export const MapActionsContext = createContext<MapActionsContextValue>(
  {} as MapActionsContextValue,
);

type MapboxProviderProps = Omit<React.ProviderProps<MapActionsContextValue>, 'value'>;

export const MapActionsProvider: React.FC<MapboxProviderProps> = ({
  children,
}: MapboxProviderProps) => {
  const { default: mapRef } = useMap();

  const [is3dActive, setIs3dActive] = useState(false);
  const [mapStyle, setMapStyle] = useState(MAP_STYLES[0].url);
  const [is3dDisabled, setIs3dDisabled] = useState(true);
  const [resultCardHover, setResultCardHover] = useState<Listing | null>(null);
  const [secondaryHover, setSecondaryHover] = useState<Listing | null>(null);

  const fitBounds = async (bounds: LngLatBoundsLike, options?: FitBoundsOptions) => {
    await waitForAnimations();

    mapRef?.fitBounds(bounds, options);
  };

  const flyTo = async (options: FlyToOptions) => {
    await waitForAnimations();

    mapRef?.flyTo(options);
  };

  const panTo = async (lngLat: LngLatLike) => {
    await waitForAnimations();

    mapRef?.panTo(lngLat);
  };

  const resize = () => {
    mapRef?.resize();
  };

  const resetMapToggles = () => {
    setShowAirports(false);
    setShowBusStations(false);
    setShowMajorHighways(true);
    setShowSubwayStations(false);
    setShowTrainStations(false);
    setShowPorts(false);
    setShowIntermodalAir(false);
    setShowIntermodalRail(false);
    setShowPopulation(false);
  };

  const reset = async () => {
    await waitForAnimations();

    fitBounds(MAP_DEFAULT_BOUNDS, {
      pitch: 0,
      bearing: 0,
    });
  };

  const zoomIn = () => {
    mapRef?.zoomIn();
  };

  const zoomOut = () => {
    mapRef?.zoomOut();
  };

  const setMapView = (style: string) => {
    mapRef?.getMap().setStyle(style);
    setMapStyle(style);
  };

  const activate3d = () => {
    flyTo({
      pitch: 60,
      zoom: 14,
    });

    setIs3dActive(true);
  };

  const deactivate3d = () => {
    flyTo({ bearing: 0, pitch: 0 });
    setIs3dActive(false);
  };

  const waitForAnimations = async () => {
    // NOTE - Wait for animations to complete.

    while (mapRef?.isMoving()) {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 100);
      });
    }
  };

  const [showAirports, setShowAirports] = useState(false);
  const [showBusStations, setShowBusStations] = useState(false);
  const [showMajorHighways, setShowMajorHighways] = useState(true);
  const [showSubwayStations, setShowSubwayStations] = useState(false);
  const [showTrainStations, setShowTrainStations] = useState(false);
  const [showPorts, setShowPorts] = useState(false);
  const [showViewList, setShowViewList] = useState(false);
  const [showIntermodalAir, setShowIntermodalAir] = useState(false);
  const [showIntermodalRail, setShowIntermodalRail] = useState(false);
  const [showPopulation, setShowPopulation] = useState(false);

  const contextValue: MapActionsContextValue = {
    fitBounds,
    flyTo,
    panTo,
    resize,
    reset,
    zoomIn,
    zoomOut,
    resultCardHover,
    setResultCardHover,
    secondaryHover,
    setSecondaryHover,

    setMapView,
    mapStyle,
    setMapStyle,
    resetMapToggles,
    is3dActive,
    setIs3dActive,
    is3dDisabled,
    setIs3dDisabled,
    activate3d,
    deactivate3d,

    showAirports,
    setShowAirports,
    showBusStations,
    setShowBusStations,
    showMajorHighways,
    setShowMajorHighways,
    showSubwayStations,
    setShowSubwayStations,
    showTrainStations,
    setShowTrainStations,
    showPorts,
    setShowPorts,
    showViewList,
    setShowViewList,

    showIntermodalAir,
    setShowIntermodalAir,
    showIntermodalRail,
    setShowIntermodalRail,
    showPopulation,
    setShowPopulation,
  };

  return <MapActionsContext.Provider value={contextValue}>{children}</MapActionsContext.Provider>;
};
