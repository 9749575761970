import { DatePickerTimeFrames } from '@components/Inputs';

export type ActiveFilters = {
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
  dateAvailable?: Date | DatePickerTimeFrames;
  minUnitSquareFootageAvailable?: number;
  maxUnitSquareFootageAvailable?: number;
  marketIds?: string[];
  submarketIds?: string[];
  listingTypes?: string[];
  minUnitDockDoors?: number;
  maxUnitDockDoors?: number;
  minUnitDriveInDoors?: number;
  maxUnitDriveInDoors?: number;
  minClearHeight?: number;
  maxClearHeight?: number;
  minTruckCourtDepth?: number;
  maxTruckCourtDepth?: number;
  classifications?: string[];
  trailerParking?: boolean;
  railServed?: boolean;
  outdoorStorage?: boolean;
  esfr?: boolean;
  fund?: string;
  pageNumber?: number;
  geoSpatialSearchParameter?: DistanceFilter;
  pageSize?: number;
};

export enum ActiveFilterQueryParam {
  VIEWPORT = 'bounds',
  UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX = 'available_sq_ft_max',
  UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN = 'available_sq_ft_min',
  CLEAR_HEIGHT_MAX = 'clear_height_max',
  CLEAR_HEIGHT_MIN = 'clear_height_min',
  DATE_AVAILABLE = 'date_available',
  DATE_TIMEFRAME = 'date_time_frame',
  LOADING_DOCKS_MAX = 'dock_doors_max',
  LOADING_DOCKS_MIN = 'dock_doors_min',
  DRIVE_INS_MAX = 'drive_ins_max',
  DRIVE_INS_MIN = 'drive_ins_min',
  MARKET_IDS = 'market_id',
  SEARCHED_LOCATION = 'searched_location',
  SUBMARKET_IDS = 'submarket_ids',
  LISTING_TYPES = 'listing_types',
  TRUCK_COURT_DEPTH_MIN = 'truck_court_depth_min',
  TRUCK_COURT_DEPTH_MAX = 'truck_court_depth_max',
  CLASSIFICATIONS = 'classifications',
  ACCOUNT_OWNERSHIPS = 'account_ownerships',
  RAIL_SERVED = 'rail_served',
  TRAILER_PARKING = 'trailer_parking',
  OUTDOOR_STORAGE = 'outdoor_storage',
  ESFR = 'esfr',
  FUND = 'fund',
  INITIAL_VIEW_STATE = 'initial_view_state',
  SORT = 'sort',
}

export type InitialViewState = {
  lngLatBoundsLike: number[];
  zoom?: number;
  listingId?: string;
  selectedOptionCategory?: string | null;
};

export type DistanceFilter = {
  latitude: number;
  longitude: number;
  distance: number;
};
