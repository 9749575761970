import React, { useEffect, useRef, useState } from 'react';
import { Pagination, Navigation, Zoom } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

import Button, { ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import Modal from '@components/Modal/Modal';
import { Typography } from '@components/Typography';
import './AssetsCarousel.css';
import { AssetsCarouselProps, CurrentSlideControlsRef } from './AssetsCarousel.types';
import ImageSlide from './ImageSlide';
import VideoSlide from './VideoSlide';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

export const MAX_ZOOM_LEVEL = 8;
export const MIN_ZOOM_LEVEL = 1;

const isAssetAVideo = (assetType: string) => assetType === 'video';

const AssetsCarousel: React.FC<AssetsCarouselProps> = ({
  initialSlideIndex,
  assets,
  onClose,
  onAllMediaClick,
  displayCustomMediaButton,
  customMediaButton,
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(MIN_ZOOM_LEVEL);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });

  /**
   * Required to access reset and or also zoom level controls for the active slide from outside the carousel
   * (Zoom level controls) are only for the image slides
   */
  const currentSlideControlsRef = useRef<CurrentSlideControlsRef | null>(null);

  const handleGettingCurrentZoomLevel = (ref: ReactZoomPanPinchRef) => {
    ((currentZoomLevel !== MAX_ZOOM_LEVEL && ref.state.scale <= MAX_ZOOM_LEVEL) ||
      (currentZoomLevel !== MIN_ZOOM_LEVEL && ref.state.scale >= MIN_ZOOM_LEVEL)) &&
      setCurrentZoomLevel(ref.state.scale);
  };

  const handleNavigationOnClick = () => {
    // resets for active image slide
    if (currentSlideControlsRef?.current?.resetTransform) {
      currentSlideControlsRef.current.resetTransform();
      setCurrentZoomLevel(MIN_ZOOM_LEVEL);
    }

    // reset for active video slide
    if (currentSlideControlsRef?.current?.resetPlayer) {
      currentSlideControlsRef.current.resetPlayer();
    }
  };

  const handleSettingCurrentSlideControlsRef = (transformCallbacks: CurrentSlideControlsRef) => {
    currentSlideControlsRef.current = transformCallbacks;
  };

  useEffect(() => {
    if (initialSlideIndex) {
      setActiveSlideIndex(initialSlideIndex);
    }
  }, []);

  const customCloseButton = (
    <button
      className="absolute flex space-x-[0.5625rem] hover:opacity-50 right-0 text-white-100 top-[-2.625rem] mobile-button"
      onClick={onClose}>
      <Icon name={IconName.CLOSE} />
      <Typography variant="button">Close</Typography>
    </button>
  );
  const customAllMediaButton = (
    <button
      className="absolute flex space-x-[0.5625rem] left-0 text-white-100 top-[15px]"
      onClick={onAllMediaClick}>
      <Icon name={IconName.CHEVRON_LEFT} />
      <Typography variant="button">All Media</Typography>
    </button>
  );

  return (
    <>
      {isSmallScreen ? (
        <div className="[&_.modal-sub]:max-w-none">
          <Modal
            classNames="!bg-transparent !p-0 !rounded-none"
            customCloseBtn={customCloseButton}
            customAllMediaButton={displayCustomMediaButton ? customAllMediaButton : <></>}
            show
            isSmallScreen>
            <div className="carousel-wrapper custom-wrapper">
              <div
                role="none"
                className="carousel-navigation md:left-[1rem] lg:left-[-5.5rem] swiper-button-prev"
                onClick={handleNavigationOnClick}>
                <Icon name={IconName.CHEVRON_LEFT} />
              </div>
              <div
                role="none"
                className="carousel-navigation md:right-[1rem] lg:right-[-5.5rem] swiper-button-next"
                onClick={handleNavigationOnClick}>
                <Icon name={IconName.CHEVRON_RIGHT} />
              </div>
              <Swiper
                initialSlide={initialSlideIndex ?? activeSlideIndex}
                allowTouchMove={false}
                className={cn(['rounded-lg image-container'])}
                onSlideChangeTransitionEnd={(swiperInstance) =>
                  setActiveSlideIndex(swiperInstance.realIndex)
                }
                loop
                modules={[Pagination, Navigation, Zoom]}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
                pagination={{
                  el: '.swiper-custom-pagination',
                  type: 'fraction',
                }}>
                {assets.map((asset: { type: string; url: string }, index: number) => (
                  <SwiperSlide key={asset.url}>
                    <div
                      className={cn([
                        'relative w-full h-full',
                        isAssetAVideo(asset.type) ? '!h-[15rem]' : '',
                      ])}>
                      {isAssetAVideo(asset.type) ? (
                        <VideoSlide
                          activeSlideIndex={activeSlideIndex}
                          index={index}
                          onSettingCurrentSlideControlsRef={handleSettingCurrentSlideControlsRef}
                          videoUrl={asset.url}
                        />
                      ) : (
                        <ImageSlide
                          activeSlideIndex={activeSlideIndex}
                          imageUrl={asset.url}
                          index={index}
                          onGettingCurrentZoomLevel={handleGettingCurrentZoomLevel}
                          onSettingCurrentSlideControlsRef={handleSettingCurrentSlideControlsRef}
                          isMobileCarousel={true}
                        />
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Modal>
        </div>
      ) : (
        <div className="[&_.modal-sub]:max-w-none">
          <Modal
            classNames="!bg-transparent !p-0 !rounded-none"
            customCloseBtn={customCloseButton}
            customAllMediaButton={customMediaButton}
            show>
            <div className="carousel-wrapper">
              <Swiper
                allowTouchMove={false}
                className="rounded-lg"
                onSlideChangeTransitionEnd={(swiperInstance) =>
                  setActiveSlideIndex(swiperInstance.realIndex)
                }
                loop
                modules={[Pagination, Navigation, Zoom]}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
                pagination={{
                  el: '.swiper-custom-pagination',
                  type: 'fraction',
                }}>
                {assets.map((asset: { type: string; url: string }, index: number) => (
                  <SwiperSlide key={asset.url}>
                    <div
                      className={cn([
                        'relative w-full h-full',
                        isAssetAVideo(asset.type) ? '!h-[27rem]' : '',
                      ])}>
                      {isAssetAVideo(asset.type) ? (
                        <VideoSlide
                          activeSlideIndex={activeSlideIndex}
                          index={index}
                          onSettingCurrentSlideControlsRef={handleSettingCurrentSlideControlsRef}
                          videoUrl={asset.url}
                        />
                      ) : (
                        <ImageSlide
                          activeSlideIndex={activeSlideIndex}
                          imageUrl={asset.url}
                          index={index}
                          onGettingCurrentZoomLevel={handleGettingCurrentZoomLevel}
                          onSettingCurrentSlideControlsRef={handleSettingCurrentSlideControlsRef}
                        />
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div role="none" className="swiper-custom-pagination" />

              <div
                role="none"
                className="carousel-navigation md:left-[1rem] lg:left-[-5.5rem] swiper-button-prev"
                onClick={handleNavigationOnClick}>
                <Icon name={IconName.CHEVRON_LEFT} />
              </div>
              <div
                role="none"
                className="carousel-navigation md:right-[1rem] lg:right-[-5.5rem] swiper-button-next"
                onClick={handleNavigationOnClick}>
                <Icon name={IconName.CHEVRON_RIGHT} />
              </div>

              {!isAssetAVideo(assets[activeSlideIndex].type) && (
                <div className="absolute bottom-[-4.0625rem] flex justify-between left-0 mx-auto my-0 right-0 w-[15.25rem]">
                  <Button
                    buttonClassNames="disabled:cursor-not-allowed disabled:opacity-50 hover:opacity-50 !text-white-100"
                    disabled={currentZoomLevel === MIN_ZOOM_LEVEL}
                    Icon={<Icon classNames="h-[1.25rem] w-[1.25rem]" name={IconName.ZOOM_OUT} />}
                    label="Zoom Out"
                    onClick={() => currentSlideControlsRef?.current?.zoomOut?.()}
                    variant={ButtonVariant.NO_FILL}
                  />
                  <Button
                    buttonClassNames="disabled:cursor-not-allowed disabled:opacity-50 hover:opacity-50 !text-white-100"
                    disabled={currentZoomLevel === MAX_ZOOM_LEVEL}
                    Icon={<Icon classNames="h-[1.25rem] w-[1.25rem]" name={IconName.ZOOM_IN} />}
                    label="Zoom In"
                    onClick={() => currentSlideControlsRef?.current?.zoomIn?.()}
                    variant={ButtonVariant.NO_FILL}
                  />
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AssetsCarousel;
