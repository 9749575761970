import React from 'react';
import cn from 'classnames';
import { SearchResultsProps } from './PredictiveSearch.types';
import { searchResultOption } from './PredictiveSearch.utils';

const SearchResults: React.FC<SearchResultsProps> = ({
  focusedOptionIndex,
  handleSettingValues,
  inputValue,
  optionIdentifierSubtext,
  minMaxDefaultValue,
  searchResults,
  searchResultsOptionRef,
  setFocusedOptionIndex,
  setPredictiveValue,
}) => {
  // sets focused option index and predictive value
  const handleOptionHover = (optionValue: string, optionIndex: number) => {
    inputValue && setPredictiveValue(optionValue);
    setFocusedOptionIndex(optionIndex);
  };

  return (
    <div className="predictive-search-results-wrapper">
      <ul className="predictive-search-results">
        {searchResults.map((optionValue, index) => {
          return (
            <li
              key={`${optionValue}-${index}`}
              className={cn([
                'p-2',
                index === focusedOptionIndex && 'bg-slate-500 cursor-pointer rounded',
              ])}
              id={`${optionValue}-${index}`}
              onClick={() =>
                handleSettingValues({
                  newValue: optionValue,
                  triggerInputOnBlur: true,
                  updateInputValue: true,
                })
              }
              onMouseEnter={() => handleOptionHover(optionValue, index)}
              ref={index === focusedOptionIndex ? searchResultsOptionRef : null}>
              {searchResultOption({
                inputValue,
                minMaxDefaultValue,
                optionIdentifierSubtext,
                optionValue,
              })}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchResults;
