import { useMemo } from 'react';

import { formatNumberWithCommas } from '@/utilities/textHelpers';
import { MinMaxRange, MinMaxRangeDefault as RangeDefault } from '@components/Inputs';

export const AVAILABLE_SQ_FT = 'Square Footage';

export const useAvailableSqFtButtonLabel = (availableSqFtRange: MinMaxRange) => {
  return useMemo(() => {
    const { max, min } = availableSqFtRange;
    const formattedMax = formatNumberWithCommas(max);
    const formattedMin = formatNumberWithCommas(min);

    if (max && min) {
      return `${formattedMin} - ${formattedMax} SF`;
    } else if (max && !min) {
      return `${RangeDefault.NO_MIN} - ${formattedMax} SF`;
    } else if (!max && min) {
      return `${formattedMin} SF - ${RangeDefault.NO_MAX}`;
    }

    return AVAILABLE_SQ_FT;
  }, [availableSqFtRange.max, availableSqFtRange.min]);
};
