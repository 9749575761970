import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { MAP_3D_ZOOM_THRESHOLD, MAP_FLY_ZOOM } from '@/config';
import { useApiClient } from '@/hooks/useApiClient';
import useMarkets from '@/hooks/useMarkets';
import { useMapActions } from '@/pages/Map/hooks/useMapActions';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import {
  FormattedLocation,
  FormattedLocationSearchData,
  LocationCategory,
  ActiveFilterQueryParam as QueryParam,
} from '@/types';
import { decodeString, encodeString } from '@/utilities/textHelpers';
import { Icon, IconName } from '@components/Icon';
import InputValueClearingPill from '@components/Inputs/InputValueClearingPill';
import DropDownList from './DropDownList';
import { searchInputProps } from './SearchInput.types';

import { useMediaQuery } from 'react-responsive';
import './SearchInput.css';

const SearchInput: React.FC<searchInputProps> = ({
  classNames,
  clearFilters,
  customClearInput,
  customOnSelect,
  onSelect,
  onMyLocation,
  selection,
  showMyLocation,
  dropdownHeight,
}) => {
  const mobileSmallScreen = useMediaQuery({ query: '(max-width: 499px)' });
  /** LOCAL STATE **/
  const [focusedOption, setFocusedOption] = useState<FormattedLocation | null>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [locationSearchData, setLocationSearchData] = useState<
    FormattedLocationSearchData | undefined
  >(undefined);
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const [value, setValue] = useState(selection);

  const inputRef = useRef<HTMLInputElement>(null);

  /** VARIABLES**/
  const showClearingInputPill = !isInputFocused && selection;
  const showDropdown = !!(((!selection && showMyLocation) || searchResultsCount) && isInputFocused);

  /** HOOKS **/
  const { getLocationSearchData } = useApiClient();
  const [debouncedSearchTerm] = useDebounce(value, 500);
  const { reset } = useMapActions();
  const { nameLookup } = useMarkets();
  const {
    marketIds,
    setSearchedLocation,
    updateSearchParameters,
    distanceFilter,
    setDistanceOption,
  } = useMapData();

  const { data: formattedLocationSearchData, error } = getLocationSearchData(debouncedSearchTerm);

  const resetState = useCallback(() => {
    setFocusedOption(null);
    setIsInputFocused(false);
    setLocationSearchData(undefined);
    setSearchResultsCount(0);
  }, []);

  // required to clear all state via MoreFiltersModal clear all filters onClick
  useEffect(() => {
    setValue('');
    clearFilters && resetState();
  }, [clearFilters]);

  useEffect(() => {
    if (!selection) {
      setValue('');
      resetState();
    }
  }, [selection]);

  // required to set location search data state
  useEffect(() => {
    if (!formattedLocationSearchData) {
      return;
    }

    if (error) {
      setLocationSearchData(undefined);
      setFocusedOption(null);
    }

    setLocationSearchData(formattedLocationSearchData.locationSearchData);
    setSearchResultsCount(formattedLocationSearchData.resultsCount);
  }, [error, formattedLocationSearchData]);

  /** FUNCTIONS **/
  const resetLocalAndGlobalState = async () => {
    resetState();
    setValue('');
    onSelect?.(null);
    customOnSelect?.(null);
    customClearInput ? customClearInput() : setSearchedLocation('');
    updateSearchParameters?.([
      [QueryParam.SEARCHED_LOCATION, null],
      [QueryParam.INITIAL_VIEW_STATE, null],
      [QueryParam.MARKET_IDS, null],
    ]);
    if (!distanceFilter) {
      // Reset the option selected when user removed the pill from free search
      setDistanceOption(null);
      reset?.();
    }
  };

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsInputFocused(true);

    const sanitizedInputValue = encodeString(event.target.value);

    setValue(sanitizedInputValue);

    if (!sanitizedInputValue) {
      resetLocalAndGlobalState();
      setIsInputFocused(true);
    }
  };

  const handleScrollOptionIntoView = (nextOption: FormattedLocation) => {
    const focusedOptionElement = document.getElementById(
      `${nextOption.displayName}-${nextOption.optionIndex}`,
    );

    focusedOptionElement &&
      focusedOptionElement.scrollIntoView({
        block: 'center',
      });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (locationSearchData && focusedOption) {
      const listings: FormattedLocation[] = locationSearchData
        ? Object.values(locationSearchData).flatMap((listings) => listings)
        : [];
      const listingsCount = listings.length;
      let nextOption = listings[0];

      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          nextOption = listings[(focusedOption.optionIndex + 1) % listingsCount];
          handleScrollOptionIntoView(nextOption);
          break;
        case 'ArrowUp':
          event.preventDefault();
          nextOption = listings[(focusedOption.optionIndex + listingsCount - 1) % listingsCount];
          handleScrollOptionIntoView(nextOption);
          break;
        case 'Escape':
          resetState();
          break;
        case 'Enter':
          event.preventDefault();
          handleOptionSelection(
            focusedOption.category as LocationCategory,
            focusedOption.optionIndex,
          );
          inputRef.current?.blur();
          break;
      }

      setFocusedOption(nextOption);
    }
  };

  const handleMyLocationSelection = () => {
    resetLocalAndGlobalState();
    setIsInputFocused(false);
    onMyLocation && onMyLocation();
  };

  const placeholderText = () => {
    return mobileSmallScreen ? 'Search' : 'Search Market, Location, or Listing Name';
  };

  const handleOptionSelection = (locationCategory: LocationCategory, selectIndex: number) => {
    const selectedOption = locationSearchData?.[locationCategory][selectIndex];

    if (!selectedOption) return resetState();

    setValue(selectedOption.displayName);

    if (customOnSelect) {
      customOnSelect(selectedOption);
    } else {
      if (locationCategory === 'markets') {
        const marketId = nameLookup.get(selectedOption.displayName) as string;

        updateSearchParameters([
          [QueryParam.SEARCHED_LOCATION, selectedOption.displayName],
          [QueryParam.INITIAL_VIEW_STATE, null],
          [QueryParam.MARKET_IDS, marketId],
          [QueryParam.SUBMARKET_IDS, null],
        ]);
      } else {
        const lngLatBoundsLike = (selectedOption.boundingBox ?? selectedOption.center).flat();
        let zoom: number | undefined;

        switch (selectedOption.category) {
          case 'locations': {
            zoom = MAP_FLY_ZOOM;
            setDistanceOption(selectedOption);
            break;
          }
          case 'listingNames':
          case 'propertyAddresses': {
            setDistanceOption(selectedOption);
            zoom = MAP_3D_ZOOM_THRESHOLD;
            break;
          }
        }

        updateSearchParameters([
          [QueryParam.SEARCHED_LOCATION, selectedOption.displayName],
          [
            QueryParam.INITIAL_VIEW_STATE,
            `${lngLatBoundsLike}|${zoom ? zoom : ''}|${
              selectedOption.listingId ? selectedOption.listingId : ''
            }`,
          ],
          [QueryParam.MARKET_IDS, null],
          [QueryParam.SUBMARKET_IDS, null],
        ]);
      }

      onSelect && onSelect(selectedOption);
    }
    resetState();
  };

  return (
    <div className={cn(['flex text-sm', classNames])}>
      <div
        className="outline-none relative text-sm w-[100%]"
        onBlur={() => {
          setFocusedOption(null);
          setIsInputFocused(false);
        }}
        onKeyDown={handleKeyDown}
        tabIndex={1}>
        <div className={cn(['search-input-wrapper', !showDropdown && 'rounded-b-lg', classNames])}>
          <Icon classNames="!h-6 !w-6 mr-3" name={IconName.SEARCH} />

          <input
            className="search-input"
            ref={inputRef}
            onChange={handleInputChange}
            onFocus={() => setIsInputFocused(true)}
            placeholder={showClearingInputPill ? '' : placeholderText()}
            type="text"
            value={
              showClearingInputPill
                ? ''
                : marketIds && marketIds.length > 1
                ? 'Multiple Markets (' + marketIds.length + ')'
                : decodeString(value)
            }
          />

          {showClearingInputPill && (
            <InputValueClearingPill
              classNames="left-[2.375rem] search-input-pill"
              onClick={() => {
                inputRef.current?.focus();
                setValue(selection);
              }}
              onRemove={resetLocalAndGlobalState}
              value={
                marketIds && marketIds.length > 1
                  ? 'Multiple Markets (' + marketIds.length + ')'
                  : selection
              }
              maxWidth="max-w-[18.75rem]"
            />
          )}
        </div>

        {showDropdown && (
          <DropDownList
            focusedOption={focusedOption}
            locationSearchData={locationSearchData}
            onOptionSelection={handleOptionSelection}
            setFocusedOption={setFocusedOption}
            dropdownHeight={dropdownHeight}>
            {showMyLocation && (
              <div
                className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-slate-500"
                onMouseDown={() => handleMyLocationSelection()}>
                <Icon classNames="!h-6 !w-6 mr-3" name={IconName.LIVE_LOCATION} />
                <div className="w-[100%]">Use Current Location</div>
              </div>
            )}
          </DropDownList>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
