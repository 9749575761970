import SearchResults from '@components/SearchResults';
import React, { useEffect, useMemo, useState } from 'react';

import { searchResultsListings } from '@/api/listings';
import {
  FEATURE_FLAG_PAGINATION_CARDS_PER_PAGE,
  FEATURE_FLAG_USE_FRONTEND_FILTERING,
} from '@/config';
import { useCart } from '@/hooks/useReportCart';
import { marketplaceApiInstance } from '@/lib/axios';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import {
  ActiveFilters,
  ListingsResponseDto,
  ActiveFilterQueryParam as SearchParameterNames,
} from '@/types';
import Button, { ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Filters } from './Filters';
import { Map } from './Map';
import './Page.css';
import { Toolbar } from './Toolbar';
import { useMapActions } from './hooks/useMapActions';
import { SORTING_ENUM } from '@/utilities/constants';

export const Page: React.FC = () => {
  const { showViewList, setShowViewList } = useMapActions();
  const { cartList } = useCart();
  const isDesktopView = useMediaQuery({ query: '(min-width: 1024px)' });
  const SESSION_STORAGE_KEY = 'last-view';
  const [data, setData] = useState<ListingsResponseDto>();
  const [pageNumber, setPageNumber] = useState<number>();
  const [searchedByLocation, isSearchedByLocation] = useState<boolean>(false);
  const [sortSelection, setSortSelection] = useState<{
    label: string;
    sortBy: number;
    sortOrder: number;
  }>({ label: 'aToZ', sortBy: 0, sortOrder: 0 });

  useEffect(() => {
    if (!isDesktopView) {
      if (sessionStorage.getItem(SESSION_STORAGE_KEY) === undefined) {
        sessionStorage.setItem(SESSION_STORAGE_KEY, 'map');
      } else {
        if (sessionStorage.getItem(SESSION_STORAGE_KEY) === 'list') {
          setShowViewList(true);
        } else {
          setShowViewList(false);
        }
      }
    }
  }, []);

  const {
    viewport,
    setIsMoving,
    dateAvailable,
    dateTimeFrame,
    unitSquareFootageAvailableRange,
    marketId,
    marketIds,
    submarketIds,
    listingTypes,
    clearHeightRange,
    loadingDocksRange,
    classifications,
    accountOwnerships,
    trailerParking,
    railServed,
    outdoorStorage,
    esfr,
    fund,
    distanceFilter,
    selectedOption,
  } = useMapData();

  const searchFilters = useMemo<ActiveFilters | null>(() => {
    // NOTE - If we have enabled frontend filtering, then the only filters we
    // apply are the bounds of the US. This will return all listings on the
    // initial request and filter them accordingly. No further requests will be
    // made due to a stable react-query key.

    const searchParameters = new URLSearchParams(location.search);
    const currentSortQuery = searchParameters.get(SearchParameterNames.SORT);
    const urlSortSelection = currentSortQuery
      ? SORTING_ENUM.filter((sortValue) => sortValue.label === currentSortQuery)
      : undefined;

    if (FEATURE_FLAG_USE_FRONTEND_FILTERING) {
      return {
        minLatitude: 20,
        maxLatitude: 50,
        minLongitude: -125,
        maxLongitude: -65,
        // listingGroupType: listingGroupType ?? ListingGroupType.DEVELOPMENT,
        pageNumber: 1,
        pageSize: FEATURE_FLAG_PAGINATION_CARDS_PER_PAGE,
      };
    }

    // NOTE - The viewport will not be available until the map finishes its
    // initial load. We return null here to ensure that we don't make a request
    // until the viewport is available.

    if (!viewport) {
      return null;
    }
    return {
      minLatitude: viewport.getSouth(),
      maxLatitude: viewport.getNorth(),
      minLongitude: viewport.getWest(),
      maxLongitude: viewport.getEast(),
      dateAvailable: dateAvailable === null ? undefined : dateAvailable,
      minUnitSquareFootageAvailable:
        unitSquareFootageAvailableRange.min === ''
          ? undefined
          : Number(unitSquareFootageAvailableRange.min),
      maxUnitSquareFootageAvailable:
        unitSquareFootageAvailableRange.max === ''
          ? undefined
          : Number(unitSquareFootageAvailableRange.max),
      marketId: marketId === '' ? undefined : marketId,
      marketIds: marketIds.length === 0 ? undefined : marketIds,
      submarketIds: submarketIds.length === 0 ? undefined : submarketIds,
      listingTypes: listingTypes.length === 0 ? undefined : listingTypes,
      minClearHeight: clearHeightRange.min === '' ? undefined : Number(clearHeightRange.min),
      maxClearHeight: clearHeightRange.max === '' ? undefined : Number(clearHeightRange.max),
      minUnitDockDoors: loadingDocksRange.min === '' ? undefined : Number(loadingDocksRange.min),
      maxUnitDockDoors: loadingDocksRange.max === '' ? undefined : Number(loadingDocksRange.max),
      classifications: classifications.length === 0 ? undefined : classifications,
      accountOwnerships: accountOwnerships.length === 0 ? undefined : accountOwnerships,
      trailerParking: trailerParking ? true : undefined,
      railServed: railServed ? true : undefined,
      outdoorStorage: outdoorStorage ? true : undefined,
      esfr: esfr ? true : undefined,
      fund: fund === null ? undefined : fund,
      pageNumber: 1,
      geoSpatialSearchParameter:
        !distanceFilter?.latitude || !distanceFilter?.longitude || !distanceFilter?.distance
          ? undefined
          : { ...distanceFilter, distance: distanceFilter.distance * 1.6093 }, // Need to convert Miles to KM for the API since Azure Search expects KM.
      isGeoSpatialQuery:
        distanceFilter?.latitude && distanceFilter?.longitude && distanceFilter?.distance > 0,
      sortBy: urlSortSelection ? urlSortSelection[0].sortBy : 0,
      sortOrder: urlSortSelection ? urlSortSelection[0].sortOrder : 0,
    };
  }, [
    viewport,
    dateAvailable,
    dateTimeFrame,
    unitSquareFootageAvailableRange.min,
    unitSquareFootageAvailableRange.max,
    marketId,
    marketIds,
    submarketIds,
    listingTypes,
    clearHeightRange.min,
    clearHeightRange.max,
    loadingDocksRange.min,
    loadingDocksRange.max,
    classifications,
    accountOwnerships,
    trailerParking,
    railServed,
    outdoorStorage,
    esfr,
    fund,
    distanceFilter,
  ]);

  useEffect(() => {
    if (searchFilters !== null) {
      setIsMoving(true);
      searchResultsListings(marketplaceApiInstance, searchFilters)
        .then((result) => {
          if (result.status === 200) {
            isSearchedByLocation(false);
            if (
              selectedOption?.category === 'listingNames' ||
              selectedOption?.category === 'propertyAddresses' ||
              selectedOption?.category === 'locations'
            ) {
              setData(undefined);
              isSearchedByLocation(true);
              searchResultsListings(marketplaceApiInstance, {
                ...searchFilters,
                pageSize: result.data.totalCount,
              }).then((response) => {
                setData(response.data);
                setPageNumber(response.data.returnedPageNumber);
              });
            } else {
              setData(result.data);
              setPageNumber(result.data.returnedPageNumber);
            }
          }
          setIsMoving(false);
        })
        .catch((err) => {
          console.log(err);
          setIsMoving(false);
        });
    }
  }, [searchFilters]);

  return (
    <main className="map-page relative flex flex-col">
      <div className="flex-none">
        <Filters />
      </div>
      <div className="flex w-screen overflow-hidden basis-full">
        <div className="map-section">
          <Map />

          <Button
            classNames={cn([
              'map-view-list w-full',
              isDesktopView || Boolean(cartList.size) ? 'hidden' : 'block',
            ])}
            label={!showViewList ? 'View List' : 'View Map'}
            Icon={<Icon name={!showViewList ? IconName.LIST : IconName.MAP_SETTINGS} />}
            onClick={() => {
              setShowViewList(!showViewList);
              sessionStorage.setItem(SESSION_STORAGE_KEY, showViewList ? 'map' : 'list');
            }}
            variant={ButtonVariant.PRIMARY_ONE}
          />
        </div>
        {data && (
          <SearchResults
            initialData={data}
            updateData={setData}
            initialPageNumber={pageNumber}
            updatePageNumber={setPageNumber}
            setSortSelection={(selectedSort: any) => setSortSelection(selectedSort)}
            sortSelection={sortSelection}
            searchedByLocation={searchedByLocation}
          />
        )}
      </div>
      <Toolbar
        className={cn(['map-toolbar', !showViewList || isDesktopView ? 'block' : 'hidden'])}
      />
    </main>
  );
};
