import React from 'react';

import { useToast } from '@/hooks/useToast';
import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import ConfirmationModal from '@components/ConfirmationModal';
import { RemoveCollectionModalProps } from './RemoveCollectionModal.types';
import { decodeString } from '@/utilities/textHelpers';

export const RemoveCollectionModal: React.FC<RemoveCollectionModalProps> = ({
  show,
  favoriteCollection,
  onConfirm,
  onClose,
}) => {
  const { removeFavoriteCollection } = useUserFavoriteCollections();
  const { addToast } = useToast();

  const handleConfirm = async () => {
    await removeFavoriteCollection(favoriteCollection.favoriteCollectionId);

    addToast({
      id: favoriteCollection.favoriteCollectionId,
      description: 'Collection Deleted Successfully',
      title: 'Success',
      type: 'success',
    });

    onConfirm ? onConfirm() : onClose();
  };

  return (
    <ConfirmationModal
      // classNames="sm:!w-[30.375rem]"
      header="Delete Collection?"
      onClose={onClose}
      primaryBtnLabel="Yes, I want to delete this collection"
      primaryBtnOnClick={handleConfirm}
      secondaryBtnLabel="No, I want to cancel"
      secondaryBtnOnClick={onClose}
      show={show}
      subText={`This collection '${decodeString(
        favoriteCollection.name,
      )}' will be removed, the listings in this collection will still be saved in "Favorites".`}
    />
  );
};

export default RemoveCollectionModal;
