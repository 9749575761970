import cn from 'classnames';
import React from 'react';

import { Typography } from '@components/Typography';

interface TabProps {
  label: string;
  onClick: () => void;
  active?: boolean;
}

export const Tab: React.FC<TabProps> = ({ label, onClick, active }: TabProps) => {
  return (
    <div className="cursor-pointer select-none" onClick={onClick} data-testid="tab-click">
      <Typography
        className={cn(
          'hover:text-slate-500',
          active ? 'text-slate-500 border-rust-100 border-b-[0.25rem] pb-1' : 'text-cement-300',
        )}
        variant="label-2"
        component="span">
        {label}
      </Typography>
    </div>
  );
};

export default Tab;
