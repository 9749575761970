import React, { useMemo } from 'react';

import useMarkets from '@/hooks/useMarkets';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import { ActiveFilterQueryParam as QueryParam } from '@/types';
import { SELECT_ALL, SHOWING_ALL_RESULTS } from '@/utilities/constants';
import { CheckboxState } from '@components/Inputs';
import CheckboxDropdown from '@components/Inputs/CheckboxDropdown/CheckboxDropdown';
import { CheckboxDropdownSelectorOption } from '@components/Inputs/CheckboxDropdown/CheckboxDropdown.types';
import { Typography } from '@components/Typography';
import { MarketFilterProps } from './MarketFilter.types';

export const MarketFilter: React.FC<MarketFilterProps> = ({ className }) => {
  const { marketIds, updateSearchParameters } = useMapData();
  const { data } = useMarkets();
  const options: CheckboxDropdownSelectorOption[] = useMemo(() => {
    if (!data?.length) {
      return [];
    }

    const options: CheckboxDropdownSelectorOption[] = data
      .map((x) => ({
        label: x.name,
        value: x.marketId,
        listingsCount: x.listingsCount,
        checkedState: marketIds.includes(x.marketId) ? CheckboxState.CHECKED : CheckboxState.EMPTY,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return options;
  }, [data]);

  return (
    <div className={className}>
      <Typography className="mb-4 text-white-100" variant="subtitle-1">
        Market
      </Typography>
      <CheckboxDropdown
        onSelect={() => {
          const selectedOptions = options
            .filter((x) => x.label !== SELECT_ALL && x.checkedState === CheckboxState.CHECKED)
            .map((x) => x.value as string);

          const value = selectedOptions.length === 0 ? null : selectedOptions.join(',');

          updateSearchParameters([
            [QueryParam.SEARCHED_LOCATION, null],
            [QueryParam.INITIAL_VIEW_STATE, null],
            [QueryParam.MARKET_IDS, value],
            [QueryParam.SUBMARKET_IDS, null],
          ]);
        }}
        options={options}
        placeholder={SHOWING_ALL_RESULTS}
      />
    </div>
  );
};
