import { createContext, useMemo, useState } from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useUser } from '@/hooks/useUser';
import { Favorite } from '@/types';

interface UserFavoritesContextValue {
  addFavorite: (listingId: string) => Promise<Favorite>;
  isFavorite: (listingId: string) => boolean;
  isFavoritesLoading: boolean;
  favorites: Favorite[];
  refetchFavorites: () => Promise<void>;
  removeFavorites: (listingIds: string[]) => Promise<void>;
  pendingFavorites: string[];
  updatePendingFavorites: (value: string[]) => void;
  paginatedFavorites?: Favorite[];
}

export const UserFavoritesContext = createContext<UserFavoritesContextValue>(
  {} as UserFavoritesContextValue,
);

type UserFavoritesProviderProps = Omit<React.ProviderProps<UserFavoritesContextValue>, 'value'>;

export const UserFavoritesProvider: React.FC<UserFavoritesProviderProps> = ({
  children,
}: UserFavoritesProviderProps) => {
  const {
    addFavorite: addFavorite_,
    getFavorites,
    removeFavorites: removeFavorites_,
  } = useApiClient();

  const [pendingFavorites, setPendingFavorites] = useState<string[]>([]);

  const { mutateAsync: addFavoriteMutation } = addFavorite_();
  const { mutateAsync: removeFavoritesMutation } = removeFavorites_();

  const { uniqueId } = useUser();

  const {
    data,
    isLoading: isFavoritesLoading,
    refetch: refetchFavorites_,
  } = getFavorites(!!uniqueId);

  const favoriteLookup = useMemo(() => new Set(data?.map((x) => x.listingId)), [data]);

  const isFavorite = (listingId: string) => {
    return favoriteLookup.has(listingId);
  };

  const addFavorite = async (listingId: string) => {
    if (!pendingFavorites.includes(listingId)) {
      setPendingFavorites([...pendingFavorites, listingId]);
    }
    const favorite = await addFavoriteMutation({ listingId });
    await refetchFavorites();
    return favorite;
  };

  const removeFavorites = async (listingIds: string[]) => {
    await removeFavoritesMutation({ listingIds });
    setPendingFavorites(pendingFavorites.filter((f) => !listingIds.includes(f)));
    await refetchFavorites();
  };

  const refetchFavorites = async () => {
    await refetchFavorites_();
  };

  const updatePendingFavorites = (values: string[]) => {
    setPendingFavorites(values);
  };

  const contextValue: UserFavoritesContextValue = {
    favorites: data ?? [],
    isFavoritesLoading,
    refetchFavorites,
    isFavorite,
    addFavorite,
    removeFavorites,
    pendingFavorites,
    updatePendingFavorites,
    // paginatedFavorites: paginatedData ?? [],
  };

  return (
    <UserFavoritesContext.Provider value={contextValue}>{children}</UserFavoritesContext.Provider>
  );
};
