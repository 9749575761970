import { InputProps } from '../inputs.types';

export enum DropdownSelectorColor {
  GRAY = 'bg-cement-100 border-cement-100',
  DARK = 'bg-slate-500 border-white-100 text-white-100',
  WHITE = 'bg-white-100 border-white-100',
  DARKSLATE = 'bg-slate-500 border-white-100 text-white-100',
}

export type DropdownSelectorOnSelectFunc = (
  selectedOption: DropdownSelectorSelectedOption,
  name?: string,
  value?: string,
) => void;

export interface DropdownSelectorOption {
  label: string;
  value: number | string;
  parentName?: string | undefined;
  childs?: DropdownSelectorOption[];
  listingsCount?: number;
}

export type DropdownSelectorRenderOptionFunc = (
  option: DropdownSelectorOption,
  onSelect: () => void,
  name?: string,
) => React.ReactNode;

export type DropdownSelectorSelectedOption = DropdownSelectorOption | number | string | undefined;

export interface DropdownSelectorProps
  extends Omit<InputProps, 'onChange' | 'onSelect' | 'placeholder' | 'value'> {
  clearFilters?: boolean;
  color?: DropdownSelectorColor;
  ignoreFocusedBorder?: boolean;
  onSelect: DropdownSelectorOnSelectFunc;
  options: DropdownSelectorOption[];
  placeholder?: JSX.Element | string;
  renderOption?: DropdownSelectorRenderOptionFunc;
  selectedOption: DropdownSelectorSelectedOption;
  showList?: boolean;
  isCheckboxDropdown?: boolean;
  isValuePill?: boolean;
  showPill?: boolean;
  isSubmarket?: boolean;
  displayPill?: boolean;
  buttonWrapperClassName?: string;
}
