export type ListingGroup = {
  name?: string;
  listingIds: string[];
};

export interface CreateReportModalProps {
  listingGroups: ListingGroup[];
  fromCollections: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export enum ExportTypes {
  AVAILABILITY_REPORT,
  VACANCY_REPORT,
  EXCEL_EXPORT,
}
