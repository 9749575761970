import { InputProps } from '../inputs.types';

export type DatePickerOnSelectFunc = (
  selectedDate: DatePickerSelectedDate | undefined,
  mode: DatePickerMode,
) => void;

export enum DatePickerMode {
  DATE = 'DATE',
  TIMEFRAME = 'TIMEFRAME',
}

export type DatePickerTimeFrames =
  | '0'
  | '30'
  | '60'
  | '90'
  | '180'
  | '181'
  | '182'
  | '183'
  | '184'
  | '185'
  | '365';

export type DatePickerSelectedDate = Date | DatePickerTimeFrames | undefined;

export type DatePickerFooter = string | undefined;

export interface DatePickerProps extends Omit<InputProps, 'onSelect' | 'selected' | 'showList'> {
  classNames?: string;
  disabled?: boolean | undefined;
  disablePastDays?: boolean;
  error?: boolean | undefined;
  errorMessage?: string | undefined;
  footer?: DatePickerFooter;
  placeholder?: string;
  showList?: boolean | undefined;
  onSelect?: DatePickerOnSelectFunc;
  selectedDate?: Date;
  selectedTimeFrame?: DatePickerTimeFrames;
  timeFrameOptions?: { label: string; value: string }[];
}
