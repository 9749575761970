import React, { useState } from 'react';

import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import ConfirmationModal from '@components/ConfirmationModal';
import { Icon, IconName } from '@components/Icon';
import AddNewCollectionModal from './AddNewFavoriteCollectionModal';
import CollectionsModal from './CollectionsModal';
import {
  CollectionAddedResponseProps,
  PostFavoritedModalsProps,
} from './PostFavoritedModals.types';

import './PostFavoritedModals.css';
import { ButtonVariant } from '@components/Button';

const PostFavoritedModals: React.FC<PostFavoritedModalsProps> = ({
  favoriteListingIds,
  onClose,
  addToCollectionFlow,
}) => {
  const [newFavoriteCollectionAdded, setNewFavoriteCollectionAdded] = useState(false);
  const [newCollectionAdded, setNewCollectionAdded] = useState<CollectionAddedResponseProps>();

  const [showAddNewFavoriteCollectionModal, setShowAddNewFavoriteCollectionModal] = useState(false);

  const { favoriteCollections } = useUserFavoriteCollections();

  const handleShowingAddNewCollectionModal = () => setShowAddNewFavoriteCollectionModal(true);

  return (
    <>
      {showAddNewFavoriteCollectionModal ? (
        <AddNewCollectionModal
          onClose={onClose}
          setNewFavoriteCollectionAdded={setNewFavoriteCollectionAdded}
          setShowModal={setShowAddNewFavoriteCollectionModal}
          favoriteListingIds={favoriteListingIds}
          setNewCollectionAdded={setNewCollectionAdded}
        />
      ) : !!favoriteCollections.length ? (
        <CollectionsModal
          addToCollectionFlow={addToCollectionFlow}
          favoriteListingIds={favoriteListingIds}
          favoritesCollections={favoriteCollections}
          newFavoriteCollectionAdded={newFavoriteCollectionAdded}
          newFavoriteCollectionOnClick={handleShowingAddNewCollectionModal}
          onClose={onClose}
          newCollectionAdded={newCollectionAdded}
        />
      ) : (
        <ConfirmationModal
          classNames="post-favorited-modal"
          header="Add to Collection"
          hideSecondaryButton={false}
          onClose={onClose}
          primaryBtnLabel="Add Collection"
          primaryBtnOnClick={handleShowingAddNewCollectionModal}
          primaryBtnDisabled={false}
          primaryBtnVariant={ButtonVariant.PRIMARY_ONE}
          secondaryBtnIcon={<Icon name={IconName.ADD_FOLDER} />}
          secondaryBtnLabel="Back to Favorites"
          secondaryBtnOnClick={onClose}
          show
          subText="You currently do not have any collections. Organize your favorites further by adding a collection first."
        />
      )}
    </>
  );
};

export default PostFavoritedModals;
