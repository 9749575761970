import React from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { SavedSearch } from '@/types';
import { IconName } from '@components/Icon';
import ShareModal from '@components/ShareModal';
import { SHARE_SEARCH_QUERY_PARAMETER_NAME } from '@/utilities/constants';

export interface ShareSavedSearchModalProps {
  show: boolean;
  savedSearch: SavedSearch;
  onClose: () => void;
}

export const ShareSavedSearchModal: React.FC<ShareSavedSearchModalProps> = ({
  show,
  onClose,
  savedSearch,
}: ShareSavedSearchModalProps) => {
  const { shareSavedSearchViaEmail } = useApiClient();
  const { addToast } = useToast();

  const { mutate: shareSavedSearchViaEmailMutation } = shareSavedSearchViaEmail();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${savedSearch.url}&${SHARE_SEARCH_QUERY_PARAMETER_NAME}=${savedSearch.savedSearchId}`,
    );

    addToast({
      id: 'shared-saved-search-' + savedSearch.savedSearchId,
      description: 'URL Copied to Clipboard',
      title: 'Success',
      type: 'success',
    });
  };

  const handleSendEmail = (recipient: string[]) => {
    shareSavedSearchViaEmailMutation({
      recipient: recipient[0],
      savedSearchId: savedSearch.savedSearchId,
    });

    addToast({
      id: 'share-saved-search-' + savedSearch.savedSearchId,
      description: 'Email Sent Successfully',
      title: 'Success',
      type: 'success',
    });
  };

  return (
    <ShareModal
      show={show}
      onClose={onClose}
      header="Share Search"
      mainActionIcon={IconName.LINK}
      mainActionLabel="Copy Link"
      onMainAction={handleCopyLink}
      onSendEmail={handleSendEmail}
      singleRecipient={true}
    />
  );
};
