import React, { useState, useEffect } from 'react';
import './Toaster.css';
import { Typography } from '@components/Typography';
import { useToast } from '@/hooks/useToast';
import { Icon, IconName } from '@components/Icon';
import { Transition } from '@headlessui/react';
import cn from 'classnames';
import Link from '@components/Link';

export type NextAction = {
  type?: 'label' | 'link';
  label: string;
  action: () => void;
};

export type ToastType = {
  id?: string;
  title: string;
  description: string;
  type: 'success' | 'error' | 'warning' | 'info';
  nextAction?: NextAction;
  autoRemoveTime?: number;
  expired?: boolean;
};
export interface ToasterProps {
  autoRemoveTime?: number;
}

const Toaster: React.FC<ToasterProps> = ({ ...props }) => {
  const { toastList, deleteToast } = useToast();
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList(toastList);
  }, [toastList]);

  useEffect(() => {
    // Get all new toasts to show
    const newToasts = list.filter((t) => !t.expired);

    newToasts.forEach((t) => {
      t.expired = true;
      const index = list.findIndex((old) => old.id === t.id);
      list[index] = t;
      setTimeout(() => {
        deleteToast(t);
        setList((list) => list.splice(index, 1));
      }, t.autoRemoveTime ?? 5000);
    });
  }, [list]);

  const onClickHandler = (toast: ToastType) => {
    deleteToast(toast);
    setList(list.filter((e) => e.id !== toast.id));
  };
  const getBorderColorClass = (type: string | undefined) => {
    switch (type) {
      case 'success':
        return 'b-green';
      case 'info':
        return 'b-blue';
      case 'warning':
        return 'b-orange';
      case 'error':
        return 'b-red';
      default:
        return 'b-green';
    }
  };

  const getIconBgColorClass = (type: string | undefined) => {
    switch (type) {
      case 'success':
        return 'icon-bg-green';
      case 'info':
        return 'icon-bg-blue';
      case 'warning':
        return 'icon-bg-orange';
      case 'error':
        return 'icon-bg-red';
      default:
        return 'icon-bg-green';
    }
  };

  const getBgColorClass = (type: string | undefined) => {
    switch (type) {
      case 'success':
        return 'bg-green';
      case 'info':
        return 'bg-blue';
      case 'warning':
        return 'bg-orange';
      case 'error':
        return 'bg-red';
      default:
        return 'bg-green';
    }
  };

  const getColorClass = (type: string | undefined) => {
    switch (type) {
      case 'success':
        return 'green';
      case 'info':
        return 'blue';
      case 'warning':
        return 'orange';
      case 'error':
        return 'red';
      default:
        return 'green';
    }
  };

  const getIcon = (type: string | undefined) => {
    switch (type) {
      case 'success':
        return IconName.CIRCLE_CHECK;
      case 'info':
        return IconName.CIRCLE_INFO;
      case 'warning':
        return IconName.CIRCLE_WARNING;
      case 'error':
        return IconName.CIRCLE_ERROR;
      default:
        return IconName.CIRCLE_CHECK;
    }
  };
  return (
    <div className={'toaster bottom-left'} {...props}>
      {list &&
        list?.map((toast: ToastType, i) => {
          return (
            <Transition
              key={i}
              show={!!toast}
              appear={true}
              enter="transition-all ease-in-out duration-500 delay-[200ms]"
              enterFrom="opacity-0 translate-x-0"
              enterTo="opacity-100 translate-x-6"
              leave="transition-all ease-in-out duration-4000"
              leaveFrom="opacity-100 translate-x-6"
              leaveTo="opacity-0 translate-x-0">
              <div
                className={cn([
                  'toast',
                  getBgColorClass(toast.type),
                  getBorderColorClass(toast.type),
                ])}>
                <div className={cn(['icon', getIconBgColorClass(toast.type)])}>
                  <Icon name={getIcon(toast.type)} />
                </div>
                <div className="body">
                  <p className="font-semibold text-base mb-1 title">{toast.title}</p>
                  <div className="description">
                    <Typography variant="body-2">{toast.description}</Typography>
                  </div>
                  {toast.nextAction && toast.nextAction.type === 'label' && (
                    <p
                      className={cn([
                        'font-semibold text-sm mt-2 next-action',
                        getColorClass(toast.type),
                      ])}>
                      {toast.nextAction.label}
                    </p>
                  )}
                  {toast.nextAction && toast.nextAction.type === 'link' && (
                    <Link
                      classNames={cn([
                        'font-semibold text-sm mt-2 next-action',
                        getColorClass(toast.type),
                        `hover:${getColorClass(toast.type)}`,
                      ])}
                      onClick={() => {
                        toast.nextAction?.action && toast.nextAction?.action();
                      }}>
                      {toast.nextAction.label}
                    </Link>
                  )}
                </div>
                <div>
                  <button
                    className="flex space-x-[0.5625rem] w-[1.5rem] pt-[0.25rem] pr-[0.25rem] right-0 top-0 hover:opacity-75 text-black-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickHandler(toast);
                    }}>
                    <Icon name={IconName.CLOSE} />
                  </button>
                </div>
              </div>
            </Transition>
          );
        })}
    </div>
  );
};

export default Toaster;
