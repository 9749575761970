import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from '@components/Header';
import Toaster from '@components/Toaster/Toaster';
import { Bookmarks } from './pages/Bookmarks';
import { CollectionDetails } from './pages/CollectionDetails';
import { Collections } from './pages/Collections';
import { Favorites } from './pages/Favorites';
import LandingPage from './pages/LandingPage/LandingPage';
import { ListingDetails } from './pages/ListingDetails';
import { MapComponent } from './pages/Map';
import { NotFound } from './pages/NotFound';
import { SavedSearches } from './pages/SavedSearches';
import { UserAuth } from './utilities/Authentication';
import { Providers } from './utilities/Providers';
import ScrollToTop from '@components/ScrollToTop';
import { MapProviders } from './pages/Map/MapProviders';
import { Maintenance } from './pages/Maintenance';
import { API_LISTINGS_BASE_URL } from './config';
import { AppConfig } from './types/app-config';
import { marketplaceApiInstance } from './lib/axios';
import { MAINTENCANCE_APPCONFIG_KEY, SMOKETESTID_APPCONFIG_KEY } from './utilities/constants';
import HeaderPublic from '@components/HeaderPublic';

const App: React.FC<any> = () => {
  // Get App Config
  const [isMaintenance, setIsMaintenance] = useState(() => {
    const sessionMaintenance = sessionStorage.getItem(MAINTENCANCE_APPCONFIG_KEY);
    return sessionMaintenance === 'true';
  });
  const [smokeTestMode, setSmokeTestMode] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  console.log(isAuthenticated);
  useEffect(() => {
    // We cannot make use the useApiClient() hook here because the context is not ready at this point.
    // So we will make a direct axios call to get the config value.
    async function getMaintenanceConfig() {
      const res = await marketplaceApiInstance.get<AppConfig>(
        `${API_LISTINGS_BASE_URL}/appconfig?key=${MAINTENCANCE_APPCONFIG_KEY}`,
      );
      const appConfig = res?.data;
      if (appConfig?.value?.toLowerCase() === 'true') {
        setIsMaintenance(true);
        sessionStorage.setItem(MAINTENCANCE_APPCONFIG_KEY, 'true');
      } else {
        setIsMaintenance(false);
        sessionStorage.removeItem(MAINTENCANCE_APPCONFIG_KEY);
      }
    }
    async function getSmokeTestIdConfig() {
      // Enable smoke test mode only if the app is in maintenance mode
      if (isMaintenance) {
        const res = await marketplaceApiInstance.get<AppConfig>(
          `${API_LISTINGS_BASE_URL}/appconfig?key=${SMOKETESTID_APPCONFIG_KEY}`,
        );
        const appConfig = res?.data;
        const sessionValue = sessionStorage.getItem(SMOKETESTID_APPCONFIG_KEY);
        console.log(appConfig?.value, sessionValue);
        if (
          isMaintenance &&
          appConfig?.value &&
          sessionValue &&
          appConfig?.value?.toLowerCase() === sessionValue?.toLowerCase()
        ) {
          setSmokeTestMode(true);
        } else {
          setSmokeTestMode(false);
          sessionStorage.removeItem(SMOKETESTID_APPCONFIG_KEY);
        }
      } else {
        setSmokeTestMode(false);
        sessionStorage.removeItem(SMOKETESTID_APPCONFIG_KEY);
      }
    }
    getMaintenanceConfig().catch((error) => {
      console.log(error);
      // Remove maintenance state if API returns error
      setIsMaintenance(false);
      sessionStorage.removeItem(MAINTENCANCE_APPCONFIG_KEY);
    });
    getSmokeTestIdConfig().catch((error) => {
      console.log(error);
      // Remove smoke test state if API returns error
      setSmokeTestMode(false);
      sessionStorage.removeItem(SMOKETESTID_APPCONFIG_KEY);
    });
  }, []);

  return (
    <>
      <UnauthenticatedTemplate>
        <Providers>
          <MapProviders>
            {isMaintenance && !smokeTestMode ? (
              <Maintenance />
            ) : (
              <>
                <HeaderPublic smokeTestMode={smokeTestMode} />
                <ScrollToTop>
                  <Routes>
                    <Route index element={<LandingPage />} />
                    <Route path="/map" element={<MapComponent />} />
                    <Route path="/listing/:id" element={<ListingDetails />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/auth" element={<UserAuth />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </ScrollToTop>
                <Toaster />
              </>
            )}
          </MapProviders>
        </Providers>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Providers>
          <MapProviders>
            {isMaintenance && !smokeTestMode ? (
              <Maintenance />
            ) : (
              <>
                <Header smokeTestMode={smokeTestMode} />
                <ScrollToTop>
                  <Routes>
                    <Route index element={<LandingPage />} />
                    <Route path="/bookmarks" element={<Bookmarks />}>
                      <Route path="favorites" element={<Favorites />} />
                      <Route path="collections" element={<Collections />} />
                      <Route path="collections/:id" element={<CollectionDetails />} />
                      <Route path="saved-searches" element={<SavedSearches />} />
                    </Route>
                    <Route path="/map" element={<MapComponent />} />
                    <Route path="/listing/:id" element={<ListingDetails />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/auth" element={<UserAuth />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </ScrollToTop>
                <Toaster />
              </>
            )}
          </MapProviders>
        </Providers>
      </AuthenticatedTemplate>
    </>
  );
};

export default App;
