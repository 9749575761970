import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { LngLatBounds } from 'mapbox-gl';

import { API_LISTINGS_BASE_URL } from '@/config';
import {
  ActiveFilters,
  ListingCollection,
  TemplateTypes,
  TemplateValue,
  ActiveFilterQueryParam as SearchParameterNames,
  ListingsResponseDto,
} from '@/types';

export function searchListings(axios: AxiosInstance, activeFilters: ActiveFilters | null) {
  return useQuery({
    queryKey: ['listings', activeFilters],
    queryFn: async ({ signal }) => {
      const response = await axios.post<ListingCollection>(
        `${API_LISTINGS_BASE_URL}/listings/search/map-pins`,
        activeFilters,
        {
          signal,
        },
      );

      return response.data;
    },
    enabled: !!activeFilters,
  });
}

export function getListingSearchBounds(
  axios: AxiosInstance,
  filters: Pick<
    ActiveFilters,
    'minLatitude' | 'maxLatitude' | 'minLongitude' | 'maxLongitude' | 'marketIds' | 'submarketIds'
  >,
) {
  const searchParameters = new URLSearchParams(location.search);
  const isInitialViewState =
    searchParameters.get(SearchParameterNames.INITIAL_VIEW_STATE) ||
    (searchParameters.get(SearchParameterNames.SEARCHED_LOCATION) &&
      !searchParameters.get(SearchParameterNames.MARKET_IDS))
      ? true
      : false;
  return useQuery({
    queryKey: ['listing-search', 'bounds', filters],
    queryFn: async ({ signal }) => {
      const response = await axios.post<LngLatBounds>(
        `${API_LISTINGS_BASE_URL}/listings/search/bounds`,
        filters,
        { signal },
      );

      return response.data;
    },
    enabled: !isInitialViewState && !!filters.marketIds,
  });
}

export function getListingData(
  axios: AxiosInstance,
  templateType: TemplateTypes,
  listingIds: string[],
) {
  return useQuery({
    queryKey: ['listing-data', templateType, listingIds],
    queryFn: async ({ signal }) => {
      const response = await axios.post<TemplateValue[]>(
        `${API_LISTINGS_BASE_URL}/listings/data`,
        {
          templateType,
          listingIds,
        },
        {
          signal,
        },
      );

      return response.data;
    },

    // NOTE - Cache listing data for an hour.
    staleTime: 60 * 1000,
  });
}

type ListingViewCountVariables = {
  listingId: string;
  listingMetricType: number;
};
export function updateListingViewCount(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ listingId, listingMetricType }: ListingViewCountVariables) => {
      const response = await axios.post<boolean>(`${API_LISTINGS_BASE_URL}/listing-metrics`, {
        listingId,
        listingMetricType,
      });

      return response.data;
    },
  });
}

type ShareListingViaEmailVariables = {
  recipient: string;
  listingId: string;
};

export function shareListingViaEmail(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ recipient, listingId }: ShareListingViaEmailVariables) => {
      const response = await axios.post<boolean>(
        `${API_LISTINGS_BASE_URL}/listings/${listingId}/emails`,
        {
          recipient,
        },
      );

      return response.data;
    },
  });
}

export const searchResultsListings = async (
  axios: AxiosInstance,
  activeFilters: ActiveFilters | null,
) => {
  const response = await axios.post<ListingsResponseDto>(
    `${API_LISTINGS_BASE_URL}/listings/search/result-panel-data`,
    activeFilters,
  );
  return response;
};
