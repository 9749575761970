import React, { useEffect, useRef, useState } from 'react';

import { Icon, IconName } from '@components/Icon';
import './HeaderItemWithOptions.css';
import cn from 'classnames';

export interface HeaderItemWithOptionsProps {
  defaultIcon: IconName;
  hoverIcon: IconName;
  showIconBorderWhenActive: boolean;
  children?: any;
}

const HeaderItemWithOptions: React.FC<HeaderItemWithOptionsProps> = ({
  defaultIcon,
  hoverIcon,
  children,
  showIconBorderWhenActive = false,
}) => {
  const [iconName, setIconName] = useState<IconName>(defaultIcon);
  const [open, setOpen] = useState(false);
  const wrappedRef = useRef(null);

  useOutsideClick(wrappedRef);

  function useOutsideClick(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [wrappedRef]);
  }

  return (
    <>
      <div ref={wrappedRef} className="mr-[1.250rem] z-[1000] overflow-hidden">
        <button
          className={cn([
            'flex items-center space-x-3 p-1 rounded lg:hover:bg-solar-200',
            showIconBorderWhenActive && open ? 'open-menu' : 'closed-menu',
          ])}
          onMouseEnter={() => {
            setIconName(hoverIcon);
          }}
          onMouseLeave={() => setIconName(defaultIcon)}
          onClick={() => setOpen(!open)}>
          <Icon classNames="!h-[1.125rem] !w-[1.125rem] m-1" name={iconName} />
        </button>
        {open && (
          <div className="absolute right-[7.7rem] rounded header-menu bg-base-white md:w-[133px] lg:w-[140px] max-h-[250px]">
            {children}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderItemWithOptions;
