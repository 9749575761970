import React from 'react';
import './Link.css';
import { LinkProps, LinkVariant } from './Link.types';
import { formatLinkClassNames } from './Link.utils';

const Link: React.FC<LinkProps> = ({
  children,
  classNames = '',
  disabled,
  error,
  onClick,
  url,
  variant = LinkVariant.DEFAULT,
  newTab = false,
}) => {
  const isEnabled = !disabled && !error;

  return (
    <span
      className={`link ${formatLinkClassNames(!!disabled, !!error, variant)} ${classNames}`}
      onClick={onClick}
      data-testid="custom-link">
      <a
        className={`${!isEnabled ? 'anchor--disabled' : ''}`}
        href={url}
        target={newTab ? '_blank' : ''}
        rel="noreferrer">
        {children}
      </a>
    </span>
  );
};

export default Link;
