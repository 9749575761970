import React, { useState } from 'react';

import Button, { ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import { TextInput } from '@components/Inputs';
import Modal from '@components/Modal';
import { Typography } from '@components/Typography';
import { useToast } from '@/hooks/useToast';
import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import { AddNewFavoriteCollectionModalProps } from './PostFavoritedModals.types';
import { decodeString, encodeString } from '@/utilities/textHelpers';
import { MAX_CHARACTERS_ALLOWED } from '@/utilities/constants';

const AddNewFavoriteCollectionModal: React.FC<AddNewFavoriteCollectionModalProps> = ({
  onClose,
  setNewFavoriteCollectionAdded,
  setShowModal,
  favoriteListingIds,
  setNewCollectionAdded,
}) => {
  const emptySpacesError = 'Collection name cannot be empty spaces or blank';
  const { addFavoriteCollection, addFavoritesToFavoriteCollection } = useUserFavoriteCollections();
  const [favoriteCollectionName, setFavoriteCollectionName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [maxCharactersRemaining, setMaxCharactersRemaining] = useState(MAX_CHARACTERS_ALLOWED);
  const { addToast } = useToast();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxCharactersRemaining(MAX_CHARACTERS_ALLOWED - e.target.value.length);
    const encodedString = encodeString(e.target.value);
    setFavoriteCollectionName(encodedString);
  };

  const handleSaveNewCollection = () => {
    if (favoriteCollectionName && favoriteCollectionName.trim().length <= 0) {
      setErrorMessage(emptySpacesError);
      return;
    }
    addFavoriteCollection(favoriteCollectionName, [])
      .then((response) => {
        setNewFavoriteCollectionAdded(true);
        setShowModal(false);
        setNewCollectionAdded(response);
      })
      .catch((error) => {
        const { exceptionMessage } = error.response.data.responseException;
        setErrorMessage(exceptionMessage);
      });
  };

  return (
    <Modal classNames="post-favorited-modal fixed-height" haveClose onClose={onClose} show>
      <Button
        classNames="absolute top-4 left-4 [&_button]:p-0 [&_button]:h-fit"
        Icon={<Icon name={IconName.CHEVRON_LEFT} />}
        label="Back"
        onClick={() => setShowModal(false)}
        variant={ButtonVariant.NO_FILL}
      />
      <div className="flex flex-col justify-between w-full h-full pt-4 text-center row">
        <div>
          <Typography className="mb-6 capitalize" variant="h6">
            Add New Collection
          </Typography>

          <div>
            <Typography className="mb-4 text-left capitalize" variant="body-4">
              Collection Name
            </Typography>
            <TextInput
              classNames="h-[5.375rem]"
              onChange={handleOnChange}
              value={favoriteCollectionName}
              error={!!errorMessage}
              errorMessage={errorMessage}
              maxLength={MAX_CHARACTERS_ALLOWED}
              maxCharactersRemaining={maxCharactersRemaining}
              showRemainingCharactersText={true}
            />
          </div>
        </div>

        <Button
          buttonClassNames="w-full"
          classNames="[&_a]:w-full"
          disabled={!favoriteCollectionName}
          label="Confirm"
          onClick={handleSaveNewCollection}
          variant={ButtonVariant.PRIMARY_ONE}
        />
      </div>
    </Modal>
  );
};

export default AddNewFavoriteCollectionModal;
