import { useMapActions } from '@/pages/Map/hooks/useMapActions';

const useFlyToLocation = () => {
  const { fitBounds, flyTo } = useMapActions();

  const flyToLocation = (bound: any, zoom?: number) => {
    if (bound.length === 4) {
      fitBounds(bound, zoom ? { zoom } : undefined);
    } else {
      const options = zoom ? { zoom } : undefined;
      flyTo({
        center: bound,
        ...options,
      });
    }
  };

  return { flyToLocation };
};

export default useFlyToLocation;
