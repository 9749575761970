import React from 'react';
import { Path, useNavigate } from 'react-router-dom';

import Button, { ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import './BackHeader.css';

interface BackHeaderProps {
  to: (number & Partial<Path>) | string;
  children?: React.ReactNode;
}

export const BackHeader: React.FC<BackHeaderProps> = ({ to, children }: BackHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className="h-[6rem] bg-base-white flex items-center md:justify-between max-w-[70.625rem] self-center back-header lg:w-[90%] md:w-[94%]">
      <Button
        classNames="[&_span]:leading-[0] back-button"
        Icon={<Icon name={IconName.CHEVRON_LEFT} />}
        label="Back"
        onClick={() => navigate(to)}
        variant={ButtonVariant.NO_FILL}
      />

      {children}
    </div>
  );
};

export default BackHeader;
