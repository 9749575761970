import React, { useMemo } from 'react';

import { useMapData } from '@/pages/Map/hooks/useMapData';
import { ActiveFilterQueryParam as QueryParam } from '@/types';
import { SHOWING_ALL_RESULTS } from '@/utilities/constants';
import { CheckboxState } from '@components/Inputs';
import { CheckboxDropdownSelectorOption } from '@components/Inputs/CheckboxDropdown/CheckboxDropdown.types';
import MultipleLevelCheckboxDropdown from '@components/Inputs/MultipleLevelCheckBoxDropDown/MultipleLevelCheckboxDropdown';
import { Typography } from '@components/Typography';
import './ListingTypes.css';
import { originalOptions, originalOptionsUnauthenticated } from './constants';
import { getParentStateBasedOnChilds, getSelectedChildCount } from './utils';
import { useIsAuthenticated } from '@azure/msal-react';
const ListingTypes: React.FC = () => {
  const { listingTypes, updateSearchParameters } = useMapData();
  const isAuth = useIsAuthenticated();
  const checkState = (state: boolean) => (state ? CheckboxState.CHECKED : CheckboxState.EMPTY);
  const types = isAuth ? originalOptions : originalOptionsUnauthenticated;

  const options = useMemo(
    () =>
      types.reduce((options, option) => {
        const optionIsChecked = listingTypes.includes(option.value.toString());
        if (option.childs) {
          const childOptions = option.childs.map((childOption) => {
            const childOptionIsChecked = listingTypes.includes(childOption.value.toString());
            const _childrenOption = {
              ...childOption,
              checkedState: checkState(childOptionIsChecked),
            };
            return _childrenOption;
          });

          options.push({
            ...option,
            checkedState: getParentStateBasedOnChilds(childOptions),
            childs: childOptions,
          });
        } else {
          options.push({
            ...option,
            checkedState: checkState(optionIsChecked),
          });
        }

        return options;
      }, [] as CheckboxDropdownSelectorOption[]),
    [],
  );

  return (
    <div className="mb-4 lg:mb-6">
      <Typography className="mb-4 text-white-100" variant="subtitle-1">
        Listing Types
      </Typography>

      <div className="listingTypesDD">
        <MultipleLevelCheckboxDropdown
          onSelect={() => {
            //Checked Parent options (only values) but without childs as we dont want parent with childs to be part of pills.
            let filteredCheckedOptions = options
              .filter(
                (option) =>
                  option.checkedState === CheckboxState.CHECKED &&
                  getSelectedChildCount(option) === 0,
              )
              .map((option) => option.value);

            let filteredCheckedChildOptions: CheckboxDropdownSelectorOption[] = [];
            for (let option of options) {
              const childOptions = option.childs;
              if (childOptions) {
                //All checked child options.
                const _filteredCheckedChildOptions = childOptions.filter(
                  (x) => x.checkedState === CheckboxState.CHECKED,
                );
                if (_filteredCheckedChildOptions.length > 0) {
                  filteredCheckedChildOptions = [
                    ...filteredCheckedChildOptions,
                    ..._filteredCheckedChildOptions,
                  ];
                  filteredCheckedOptions = [
                    ...filteredCheckedOptions,
                    ...filteredCheckedChildOptions.map((option) => option.value),
                  ];
                } else {
                  filteredCheckedOptions = filteredCheckedOptions.filter(
                    (value) => value != option.value,
                  );
                }
              }
            }

            const value = filteredCheckedOptions.length ? filteredCheckedOptions.join(',') : null;
            updateSearchParameters([[QueryParam.LISTING_TYPES, value]]);
          }}
          options={options}
          placeholder={SHOWING_ALL_RESULTS}
        />
      </div>
    </div>
  );
};

export default ListingTypes;
