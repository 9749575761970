import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { Icon, IconName } from '@components/Icon';
import { MinMaxRangeDefault as RangeDefault } from '@components/Inputs';
import { Typography } from '@components/Typography';
import { hasRangeError } from '@components/Inputs/MinMaxSelector/MinMaxSelector.utils';
import {
  decodeString,
  encodeString,
  formatNumberWithCommas,
  removeCommasFromString,
} from '@/utilities/textHelpers';
import InputValueClearingPill from '../InputValueClearingPill';
import { PredictiveSearchProps, handleSettingValuesFunc } from './PredictiveSearch.types';
import { isValidNumericStringValue, predictiveHighlightedValue } from './PredictiveSearch.utils';
import './PredictiveSearch.css';
import SearchResults from './SearchResults';

const PredictiveSearch: React.FC<PredictiveSearchProps> = ({
  classNames,
  clearFilters,
  data,
  displayResultsWhenFocused,
  errorMessage = 'No results',
  label,
  name = '',
  numericOnly,
  onSelect,
  optionIdentifierSubtext = '',
  placeholder,
  value,
  maxWidth,

  /** MIN MAX SELECTOR PROPS **/
  customErrorMessage = '',
  customHasError,
  customInputValue = '',
  customIsInputFocused,
  customOnBlurCallback,
  customSetErrorCallback,
  customSetInputValue,
  customSetIsInputFocused,
  isMinMaxSelector,
  minMaxDefaultValue = '',
  oppositeIsInputFocused,
  oppositeRangeInputValue = '',
  hideChevron = false,
}) => {
  /** LOCAL STATE **/
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(-1);
  const [inputHasError, setInputHasError] = useState(false);
  const [localIsInputFocused, setLocalIsInputFocused] = useState(false);
  const [localInputValue, setLocalInputValue] = useState('');
  const [predictiveValue, setPredictiveValue] = useState('');
  const [searchResults, setSearchResults] = useState<string[] | null>(null);
  const [updateOppositeRangeValue, setUpdateOppositeRangeValue] = useState(false);

  /** ELEMENT REFS **/
  const inputRef = useRef<HTMLInputElement>(null);
  const predictiveSearchRef = useRef<HTMLDivElement>(null);
  const searchResultsOptionRef = useRef<HTMLLIElement>(null);

  /** GLOBAL VARIABLES AND FORMAT METHODS **/
  const inputValue = isMinMaxSelector ? customInputValue : localInputValue;
  const isInputFocused = isMinMaxSelector ? customIsInputFocused : localIsInputFocused;
  const setInputValue =
    isMinMaxSelector && customSetInputValue ? customSetInputValue : setLocalInputValue;
  const setIsInputFocused =
    isMinMaxSelector && customSetIsInputFocused ? customSetIsInputFocused : setLocalIsInputFocused;
  const hideMinMaxDefaultValue = isMinMaxSelector && isInputFocused && !inputValue;
  const checkIfValuesInRange = (inputValue: string) => {
    if (!inputValue) return true;

    const numericInputValue = parseInt(inputValue, 10);
    const numericOppositeRangeInputValue = oppositeRangeInputValue
      ? parseInt(oppositeRangeInputValue, 10)
      : 0;
    const rangeMax =
      !numericOppositeRangeInputValue || minMaxDefaultValue === RangeDefault.NO_MAX
        ? Number.POSITIVE_INFINITY
        : numericOppositeRangeInputValue;
    const rangeMin = numericOppositeRangeInputValue;

    return minMaxDefaultValue === RangeDefault.NO_MAX
      ? numericInputValue >= rangeMin && numericInputValue <= rangeMax
      : numericInputValue <= rangeMax;
  };

  /** USEEFFECT HOOKS **/
  // Synchronize value changes
  useEffect(() => {
    setInputValue(value);
    setPredictiveValue(value);
  }, [value]);

  // required to clear all state via MoreFiltersModal clear all filters onClick
  useEffect(() => {
    if (clearFilters) {
      clearAllFilters();
    }
  }, [clearFilters]);

  useEffect(() => {
    if (!inputValue && inputHasError) {
      setInputHasError(false);
    }
  }, [inputValue]);

  // used for MinMaxSelector to set opposing range value when range error is cleared
  useEffect(() => {
    if (
      isMinMaxSelector &&
      inputValue &&
      inputValue !== value &&
      !customHasError &&
      updateOppositeRangeValue
    ) {
      if (checkIfValuesInRange(inputValue)) {
        setInputHasError(false);
        onSelect(inputValue, name);
        setUpdateOppositeRangeValue(false);
      }
    }
  }, [data, inputValue, customHasError, inputHasError, updateOppositeRangeValue, value]);

  /**
   * 1. handles setting/updating event listener for mousedown events
   * 2. if no input errors, saves input value upon clicking outside this component, otherwise, shows error message
   */
  useEffect(() => {
    const handleBlur = (event: MouseEvent) => {
      if (
        isInputFocused &&
        predictiveSearchRef.current &&
        !predictiveSearchRef.current.contains(event.target as Node)
      ) {
        handleSettingValues({
          inputHasError,
          newValue: inputValue,
        });
      }

      /**
       * used for a minMaxSelector contained within a parent modal
       * closes parent modal from here versus inside parent modal component
       * this allows the global value state to be updated before the parent modal is closed
       */
      if (
        isMinMaxSelector &&
        customOnBlurCallback &&
        (isInputFocused || (!isInputFocused && !oppositeIsInputFocused))
      ) {
        customOnBlurCallback(event);
      }
    };

    document.addEventListener('mousedown', handleBlur);
    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [
    customHasError,
    data,
    focusedOptionIndex,
    isInputFocused,
    inputHasError,
    inputValue,
    oppositeRangeInputValue,
    searchResults,
    value,
  ]);

  // updates search results based on input value
  useEffect(() => {
    if (inputValue) {
      const searchResults = data.filter((option) =>
        option.toLowerCase().startsWith(inputValue.toLowerCase()),
      );
      const matchFound = searchResults.length;

      setSearchResults(matchFound ? searchResults : null);
    } else {
      const updatedResults = displayResultsWhenFocused && !inputValue ? data : null;
      setSearchResults(updatedResults);
    }
  }, [data, inputValue]);

  const handleInputError = (inputValue: string, isValueInData: boolean) => {
    inputValue && !isValueInData
      ? setInputHasError(true)
      : inputHasError && setInputHasError(false);
  };

  /** LOCAL EVENT HANDLERS **/
  const clearAllFilters = () => {
    setInputValue('');
    setPredictiveValue('');
    setInputHasError(false);
    customSetErrorCallback && customSetErrorCallback(false);
  };

  // handles setting value and error state for onBlur, keyDown (Enter/Esc) and onClick events
  const handleSettingValues: handleSettingValuesFunc = ({
    inputHasError,
    newValue,
    triggerInputOnBlur,
    updateInputValue,
  }) => {
    const isDefaultValue = isMinMaxSelector && newValue === minMaxDefaultValue;
    const updatedValue = isDefaultValue ? '' : newValue;
    const rangeError =
      isMinMaxSelector && hasRangeError(oppositeRangeInputValue, name, updatedValue);
    const isValueInData = isMinMaxSelector ? checkIfValuesInRange(updatedValue) : true;
    const inputError = inputHasError !== undefined && inputValue && !isValueInData;

    handleInputError(inputValue, isValueInData);

    if (customSetErrorCallback) {
      rangeError
        ? (customSetErrorCallback(true), setUpdateOppositeRangeValue(true))
        : customHasError && customSetErrorCallback(false);
    }

    updatedValue !== value && onSelect(inputError || rangeError ? '' : updatedValue, name);
    triggerInputOnBlur && inputRef.current?.blur();
    updateInputValue && setInputValue(updatedValue);
    setIsInputFocused(false);
    setPredictiveValue(updatedValue);
  };

  // updates input and predictive values
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // reset focus so that options are no longer highlighted while user is typing
    setFocusedOptionIndex(-1);
    handleInputFocus();
    const inputValue = encodeString(removeCommasFromString(event.target.value));

    if ((isMinMaxSelector || numericOnly) && !isValidNumericStringValue(inputValue)) return;

    const searchResult = data.find((option) =>
      option.toLowerCase().startsWith(inputValue.toLowerCase()),
    );
    setInputValue(inputValue);
    setPredictiveValue(inputValue && searchResult ? searchResult : '');
  };

  // sets focus to the input when clicking anywhere on this component when not focused
  const handleInputFocus = () => {
    inputRef.current?.focus();
    setIsInputFocused(true);
  };

  // helps with keeping focus on a focused dropdown option when traversing the search results list
  const handleScrollOptionIntoView = (optionIndex: number, optionValue: string) => {
    const focusedOptionElement = document.getElementById(`${optionValue}-${optionIndex}`);

    focusedOptionElement &&
      focusedOptionElement.scrollIntoView({
        block: 'center',
      });
  };

  /**
   * 1. sets focused option index and predictive value when traversing the search results upon down and up key events
   * 2. saves input value and taking focus off of input upon enter key event
   * 3. resets state and taking focus off of input upon escape key event
   */
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isInputFocused) {
      return;
    }

    switch (event.key) {
      // move down list (uses remainder to allow index to be reset to 0 when last option is reached)
      case 'ArrowDown':
        event.preventDefault();
        if (searchResults) {
          const nextOptionIndex = (focusedOptionIndex + 1) % searchResults.length;
          const optionValue = searchResults[nextOptionIndex];

          handleScrollOptionIntoView(nextOptionIndex, optionValue);
          inputValue && setPredictiveValue(optionValue);
          setFocusedOptionIndex(nextOptionIndex);
        }
        break;
      // move up list (uses remainder to allow index to be reset to last index when first option is reached)
      case 'ArrowUp':
        event.preventDefault();
        if (searchResults) {
          const prevOptionIndex =
            (focusedOptionIndex + searchResults.length - 1) % searchResults.length;
          const optionValue = searchResults[prevOptionIndex];

          handleScrollOptionIntoView(prevOptionIndex, optionValue);
          inputValue && setPredictiveValue(optionValue);
          setFocusedOptionIndex(prevOptionIndex);
        }

        break;
      // select current focused option
      case 'Tab':
        event.preventDefault();
        const minMaxFocusedOption = searchResults ? searchResults[focusedOptionIndex] : '';
        const minMaxNewValue = minMaxFocusedOption || inputValue;

        handleSettingValues({
          inputHasError,
          newValue: minMaxNewValue,
          triggerInputOnBlur: true,
          updateInputValue: true,
        });
        break;
      case 'Escape':
      case 'Enter':
        event.preventDefault();
        const focusedOption = searchResults ? searchResults[focusedOptionIndex] : '';
        const newValue = focusedOption || inputValue;

        handleSettingValues({
          inputHasError,
          newValue,
          triggerInputOnBlur: true,
          updateInputValue: true,
        });
        setFocusedOptionIndex(-1);
        break;
    }
  };

  return (
    <div className={cn([classNames])}>
      {label && (
        <Typography className="mb-4 text-white-100" variant="subtitle-1">
          {label}
        </Typography>
      )}

      <div className="relative text-sm" ref={predictiveSearchRef}>
        <div
          className={cn([
            'predictive-search-input-outer-wrapper',
            (inputHasError || customHasError) && '!border-freight-200',
          ])}
          onClick={handleInputFocus}>
          <div
            className={cn([
              'relative',
              hideChevron ? 'w-[calc(100%-0.5rem)]' : 'w-[calc(100%-2rem)]',
            ])}>
            <input
              className={cn([
                'predictive-search-input',
                isInputFocused && 'border-b placeholder-opacity-0',
                !hideMinMaxDefaultValue && !isInputFocused && inputValue && 'text-white-100',
              ])}
              name={name}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder={placeholder}
              ref={inputRef}
              type="text"
              value={
                hideMinMaxDefaultValue
                  ? ''
                  : formatNumberWithCommas(decodeString(inputValue)) || minMaxDefaultValue
              }
            />
            <div className={cn(['h-[1.5625rem] py-0.5 w-full'])}>
              {hideMinMaxDefaultValue || !inputValue
                ? ''
                : predictiveHighlightedValue(inputValue, predictiveValue)}
            </div>
          </div>

          {!hideMinMaxDefaultValue && !isInputFocused && inputValue && (
            <InputValueClearingPill
              onRemove={() => {
                onSelect('', name);
                clearAllFilters();
              }}
              maxWidth={maxWidth}
              value={formatNumberWithCommas(inputValue) || minMaxDefaultValue}
            />
          )}

          {!hideChevron && (
            <div
              onClick={(e) => {
                if (isInputFocused) {
                  setIsInputFocused(false);
                  e.stopPropagation();
                }
              }}>
              <Icon
                classNames="!h-6 pointer-event-none !w-6"
                name={isInputFocused ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
              />
            </div>
          )}
        </div>

        {(customHasError || inputHasError) && (
          <p className="absolute predictive-search-input-error mt-1.5 text-freight-200 z-[11]">
            {customHasError ? customErrorMessage : errorMessage}
          </p>
        )}

        {isInputFocused && searchResults && searchResults.length > 0 && (
          <SearchResults
            focusedOptionIndex={focusedOptionIndex}
            handleSettingValues={handleSettingValues}
            inputValue={inputValue}
            minMaxDefaultValue={minMaxDefaultValue}
            optionIdentifierSubtext={optionIdentifierSubtext}
            searchResults={searchResults}
            searchResultsOptionRef={searchResultsOptionRef}
            setFocusedOptionIndex={setFocusedOptionIndex}
            setPredictiveValue={setPredictiveValue}
          />
        )}
      </div>
    </div>
  );
};

export default PredictiveSearch;
