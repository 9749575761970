import React, { Fragment, useMemo } from 'react';

import { TemplateAttributeKey as AttrKey, TemplateAttributeValue } from '@/types';
import { PrimaryInfoElements, PrimaryInfoProps } from './PrimaryInfo.types';
import { addChildrenToElement, primarySpecElement } from './PrimaryInfo.utils';
import PrimarySpecification from './PrimarySpecification';
import { addDays, format, isBefore } from 'date-fns';
import { isIndustrialOrPark } from '@/utilities/constants';
import useDynamicMediaQuery from '@/hooks/useDynamicMediaQuery';
import { ternaryOperation } from '@/utilities/functions';

const PrimaryInfo: React.FC<PrimaryInfoProps> = ({ attributes, propertyType }) => {
  const below368Pixels = useDynamicMediaQuery('(min-width: 360px) and (max-width: 368px)');
  const initialElements: PrimaryInfoElements = {
    // wraps property name and full address
    propertyNameAddress: <div>{[]}</div>,
    // wraps property primary image
    propertyPrimaryImage: <div>{[]}</div>,
    propertyDateAvailable: <>{[]}</>,
    propertyBuildingStatus: <>{[]}</>,
    // wraps property primary specifications
    propertySpecs: <div className="grid md:grid-cols-1 lg:grid-cols-2">{[]}</div>,
    propertyModifiedAt: <div>{[]}</div>,
    propertyId: <div>{[]}</div>,
  };

  const assignPrimaryInfo = (
    attribute: TemplateAttributeValue,
    primaryInfo: PrimaryInfoElements,
    element: JSX.Element,
  ) => {
    if (attribute.key === AttrKey.FullAddress || attribute.key === AttrKey.Name) {
      primaryInfo.propertyNameAddress = addChildrenToElement(
        element,
        primaryInfo.propertyNameAddress,
      );
    } else if (attribute.key === AttrKey.DigitalAssets) {
      primaryInfo.propertyPrimaryImage = addChildrenToElement(
        element,
        primaryInfo.propertyPrimaryImage,
      );
    } else if (attribute.key === AttrKey.BuildingStatus) {
      primaryInfo.propertyBuildingStatus = addChildrenToElement(
        element,
        primaryInfo.propertyBuildingStatus,
      );
    } else if (attribute.key === AttrKey.DateAvailable) {
      primaryInfo.propertyDateAvailable = addChildrenToElement(
        element,
        primaryInfo.propertyDateAvailable,
      );

      let label = attribute.label ?? '';
      let dateAvailable =
        attribute.value === 'CONTACT_US' ? <div>Contact Us</div> : attribute.value;

      if (isIndustrialOrPark(propertyType)) {
        label =
          attribute.value === 'Now' && attribute.value !== null
            ? 'Available Date'
            : 'Potentially Available Date';
        dateAvailable = ternaryOperation(
          !attribute.value || attribute.value === 'CONTACT_US',
          'Contact Us',
          ternaryOperation(
            isBefore(new Date(attribute.rawValue), addDays(new Date(), 1)),
            'Now',
            format(new Date(attribute.rawValue), 'MM/dd/yyyy'),
          ),
        );
      }

      primaryInfo.propertySpecs = addChildrenToElement(
        <PrimarySpecification
          key={attribute.key}
          label={label}
          value={dateAvailable}
          propertyType={propertyType}
        />,
        primaryInfo.propertySpecs,
      );
    } else if (attribute.key === AttrKey.ModifiedAt) {
      primaryInfo.propertyModifiedAt = addChildrenToElement(
        element,
        primaryInfo.propertyModifiedAt,
      );
    } else if (attribute.key === AttrKey.PropertyId) {
      primaryInfo.propertyId = addChildrenToElement(element, primaryInfo.propertyId);
    } else if (attribute.key === AttrKey.UnitSquareFootage) {
      primaryInfo.propertySpecs = addChildrenToElement(
        <PrimarySpecification
          key={attribute.key}
          label={attribute.label ?? ''}
          value={attribute.value}
          propertyType={propertyType}
        />,
        primaryInfo.propertySpecs,
      );
    } else {
      primaryInfo.propertySpecs = addChildrenToElement(element, primaryInfo.propertySpecs);
    }

    return primaryInfo;
  };

  const primaryInfo = useMemo(
    () =>
      attributes?.reduce((primaryInfo, attribute) => {
        const element = (
          <Fragment key={`${attribute.key}`}>
            {primarySpecElement(attribute, propertyType, below368Pixels)}
          </Fragment>
        );

        return assignPrimaryInfo(attribute, primaryInfo, element);
      }, initialElements),
    [below368Pixels],
  );

  return (
    <div className="flex items-center justify-between max-w-[70.625rem] w-full">
      <div className="lg:min-w-[70%] md:min-w-[60%]">
        {!isIndustrialOrPark(propertyType) && (
          <div className="hidden md:flex mb-3 row">
            {primaryInfo?.propertyBuildingStatus}
            {primaryInfo?.propertyDateAvailable}
          </div>
        )}
        <div className="hidden md:block">{primaryInfo?.propertyNameAddress}</div>
        {primaryInfo?.propertySpecs}
        <div className="flex space-x-10 mt-1 md:mt-5">
          {primaryInfo?.propertyModifiedAt}
          {primaryInfo?.propertyId}
        </div>
      </div>

      <div className="hidden md:block">{primaryInfo?.propertyPrimaryImage}</div>
    </div>
  );
};

export default PrimaryInfo;
