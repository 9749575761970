import { ActiveFilterQueryParam as QueryParam, InitialViewState } from '@/types';
import { MinMaxRange } from '@components/Inputs';
import { getUnixTime } from 'date-fns';

const primaryMapFiltersQueryParams = [
  QueryParam.SEARCHED_LOCATION,
  QueryParam.INITIAL_VIEW_STATE,
  QueryParam.MARKET_IDS,
  QueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX,
  QueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN,
  QueryParam.DATE_AVAILABLE,
  QueryParam.DATE_TIMEFRAME,
];

export const formatQueryParams = (
  availableSqFtRange: MinMaxRange,
  dateAvailable: Date | undefined,
  dateTimeFrame: string | undefined,
  marketId: string,
  searchedLocation: string,
  initialViewState: InitialViewState | null,
) =>
  primaryMapFiltersQueryParams.reduce((formattedMapRoute, queryParam) => {
    const delimiter = formattedMapRoute ? '&' : '?';

    switch (queryParam) {
      case QueryParam.DATE_AVAILABLE:
        if (dateAvailable)
          formattedMapRoute += `${delimiter}${QueryParam.DATE_AVAILABLE}=${getUnixTime(
            dateAvailable,
          )}`;
        break;
      case QueryParam.DATE_TIMEFRAME:
        if (dateTimeFrame)
          formattedMapRoute += `${delimiter}${QueryParam.DATE_TIMEFRAME}=${dateTimeFrame}`;
        break;
      case QueryParam.MARKET_IDS:
        if (marketId)
          formattedMapRoute += `${delimiter}${QueryParam.MARKET_IDS}=${encodeURIComponent(
            marketId,
          )}`;
        break;
      case QueryParam.SEARCHED_LOCATION:
        if (searchedLocation)
          formattedMapRoute += `${delimiter}${QueryParam.SEARCHED_LOCATION}=${encodeURIComponent(
            searchedLocation,
          )}`;
        break;
      case QueryParam.INITIAL_VIEW_STATE:
        if (initialViewState) {
          const { lngLatBoundsLike, zoom, listingId, selectedOptionCategory } = initialViewState;

          formattedMapRoute += `${delimiter}${QueryParam.INITIAL_VIEW_STATE}=${encodeURIComponent(
            `${lngLatBoundsLike}|${zoom ? zoom : ''}|${listingId ? listingId : ''}|${
              selectedOptionCategory ? selectedOptionCategory : ''
            }`,
          )}`;
        }
        break;
      case QueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX:
        if (availableSqFtRange.max)
          formattedMapRoute += `${delimiter}${QueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX}=${availableSqFtRange.max}`;
        break;
      case QueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN:
        if (availableSqFtRange.min)
          formattedMapRoute += `${delimiter}${QueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN}=${availableSqFtRange.min}`;
        break;
    }

    return formattedMapRoute;
  }, '');
