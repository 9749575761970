import { ternaryOperation } from '@/utilities/functions';
import { Icon, IconName } from '@components/Icon';
import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { CheckboxProps, CheckboxState } from './Checkbox.types';

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { checkedState, onClick, darkMode, disableIndeterminateOption, className, ...rest } = props;
  const { disabled, id, key = null, label, name, labelClassNames, labelIcon } = rest;

  const checkboxRef = useRef<HTMLInputElement>(null);

  const checkboxChecked = checkedState === CheckboxState.CHECKED;
  const checkboxUnchecked = checkedState === CheckboxState.EMPTY;
  const checkboxIndeterminate = !checkboxChecked && !checkboxUnchecked;

  const checkboxLabelClassNames = cn([
    'cursor-pointer',
    darkMode && 'text-white-100',
    disabled && '!text-cement-200',
    labelClassNames,
  ]);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = checkboxChecked;

      checkboxRef.current.indeterminate = checkboxIndeterminate;
    }
  }, [checkedState]);

  const handleClick = () => {
    const updatedCheckedState = checkboxChecked
      ? CheckboxState.EMPTY
      : !disableIndeterminateOption && checkboxUnchecked
      ? CheckboxState.INDETERMINATE
      : CheckboxState.CHECKED;

    if (onClick) onClick(updatedCheckedState, name);
  };

  const CheckboxIcon = checkboxChecked ? (
    <Icon
      classNames={cn([
        'absolute !h-[0.625rem] left-1 top-[0.325rem] !w-[0.8125rem]',
        darkMode ? 'text-slate-500' : 'text-base-white',
        disabled && darkMode ? 'text-slate-500/50' : undefined,
      ])}
      name={IconName.CHECK_MARK}
    />
  ) : checkboxIndeterminate ? (
    <Icon
      classNames={cn([
        'absolute !h-[0.125rem] left-1 top-[0.5625rem] !w-[0.75rem]',
        disabled ? 'text-cement-200' : 'text-slate-200',
      ])}
      name={IconName.HASH_MARK}
    />
  ) : (
    <></>
  );

  const disabledInputClassNames = cn([
    '!cursor-not-allowed',
    checkboxChecked ? 'opacity-50' : '!border-cement-200',
  ]);

  const checkboxInputClassNames = cn([
    'appearance-none border cursor-pointer h-5 rounded-sm w-5',
    ternaryOperation(
      checkboxChecked,
      ternaryOperation(
        darkMode,
        'bg-freight-100 border-freight-100',
        'bg-rust-100 border-rust-100',
      ),
      ternaryOperation(
        checkboxIndeterminate,
        'border-slate-200 bg-transparent',
        'border-slate-200 bg-white-100',
      ),
    ),
    checkboxUnchecked && darkMode && 'bg-transparent',
    disabled && disabledInputClassNames,
  ]);

  return (
    <div
      key={key}
      className={cn('flex items-center space-x-2 text-sm', className)}
      data-testid={`checkbox${label ? `-${label}` : ''}`}
      onClick={() => {
        if (disabled) return;
        handleClick();
      }}>
      <div
        className={cn([
          'relative cursor-pointer h-5 w-5 checkbox',
          disabled && 'cursor-not-allowed',
        ])}>
        <input className={checkboxInputClassNames} ref={checkboxRef} type="checkbox" {...rest} />
        {CheckboxIcon}
      </div>
      {labelIcon && (
        <Icon
          classNames={cn(['!h-[1.225rem] !w-[1.125rem]', disabled ? 'text-cement-200' : ''])}
          name={labelIcon}
        />
      )}
      {label && (
        <label className={checkboxLabelClassNames} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
