import React, { InputHTMLAttributes, useState } from 'react';
import { Icon, IconName } from '@components/Icon';
import cn from 'classnames';
import './TableSearch.css';
export interface TableSearchProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  onClear: () => void;
}

const TableSearch: React.FC<TableSearchProps> = (props) => {
  const { className, onClear, value, ...rest } = props;
  const [showX, setShowX] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') value ? setShowX(true) : setShowX(false);
  };

  return (
    <div
      className={cn(
        'table-search',
        isFocus ? ' bg-solar-500 outline-white-100 outline outline-1' : ' bg-solar-400',
      )}>
      <div>
        <Icon name={IconName.SEARCH} classNames="m-[0.4rem]" />
      </div>
      <div className="w-full mt-1">
        <input
          type="text"
          value={value}
          onFocus={() => setIsFocus(true)}
          onKeyDown={handleEnter}
          onBlur={() => {
            setIsFocus(false);
            value ? setShowX(true) : setShowX(false);
          }}
          className={cn('table-search-input', className, showX && 'font-semibold')}
          {...rest}
        />
      </div>

      {showX && (
        <div>
          <span
            className="cursor-pointer"
            onClick={() => {
              setShowX(false);
              onClear();
            }}>
            <Icon name={IconName.CLOSE} classNames="m-[0.4rem]" />
          </span>
        </div>
      )}
    </div>
  );
};

export default TableSearch;
