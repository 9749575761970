import useIsBigScreen from '@/hooks/useIsBigScreen';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, Typography } from '@mui/material';
import { PageNavigatorProps } from './Pagination.types';
import './Pagination.css';
import cn from 'classnames';

const PageNavigator: React.FC<PageNavigatorProps> = ({
  totalRows,
  pageSize,
  updateSelectedPageNumber,
  selectedPageNumber,
}) => {
  let lastPage = Math.ceil(totalRows / pageSize);
  let maxPageLimit = Math.ceil(100000 / pageSize);

  if (lastPage > maxPageLimit) {
    lastPage = maxPageLimit;
  } else if (lastPage < 1) {
    lastPage = 1;
  }
  const isBigScreen = useIsBigScreen();
  const offset = isBigScreen ? 5 : 2;

  const getPageList = (currentPage: any) => {
    const pageList = Array.from({ length: lastPage }, (value, index) => index + 1);
    const array = [];
    for (let i = 0; i < pageList.length; i += offset) {
      array.push(pageList.slice(i, i + offset));
    }

    let selectedList: number[] = [];
    array.forEach((list) => {
      if (list.includes(currentPage)) {
        selectedList = list;
      }
    });
    return selectedList;
  };
  function handlePageChange(page: any) {
    updateSelectedPageNumber(page);
  }
  function handleChange() {
    return true;
  }

  return (
    <div className="pagination-list">
      <Button
        className={cn(
          '!rounded-full !min-w-0',
          selectedPageNumber == 1 ? '!bg-cement-100' : '!bg-black',
        )}
        disabled={selectedPageNumber == 1}
        onClick={() => {
          handlePageChange(selectedPageNumber - 1);
        }}>
        <NavigateBeforeIcon fontSize="small" sx={{ fontSize: '14px', color: 'white' }} />
      </Button>
      {selectedPageNumber > offset ? (
        <>
          <div
            role="none"
            key={1}
            onClick={() => {
              handlePageChange(1);
            }}
            onKeyDown={handleChange}
            className={cn(
              '!min-w-0',
              selectedPageNumber == 1
                ? 'selected-page pagination-list-children'
                : 'pagination-list-children',
            )}>
            {1}
          </div>
          <Typography>...</Typography>
        </>
      ) : null}
      {getPageList(selectedPageNumber).map((page) => (
        <div
          role="none"
          key={page}
          onClick={() => {
            handlePageChange(page);
          }}
          onKeyDown={handleChange}
          className={cn(
            '!min-w-0',
            selectedPageNumber == page
              ? 'selected-page pagination-list-children'
              : 'pagination-list-children',
          )}>
          {page}
        </div>
      ))}
      {lastPage > selectedPageNumber &&
      lastPage > offset &&
      !getPageList(selectedPageNumber).includes(lastPage) ? (
        <>
          <Typography>...</Typography>
          <div
            role="none"
            key={lastPage}
            onClick={() => {
              handlePageChange(lastPage);
            }}
            onKeyDown={handleChange}
            className={cn(
              '!min-w-0',
              selectedPageNumber == lastPage
                ? 'selected-page pagination-list-children'
                : 'pagination-list-children',
            )}>
            {lastPage}
          </div>
        </>
      ) : null}
      <Button
        className={cn(
          '!rounded-full !min-w-0',
          selectedPageNumber == lastPage ? '!bg-cement-100' : '!bg-black',
        )}
        disabled={selectedPageNumber == lastPage}
        onClick={() => {
          handlePageChange(selectedPageNumber + 1);
        }}>
        <NavigateNextIcon fontSize="small" sx={{ fontSize: '14px', color: 'white' }} />
      </Button>
    </div>
  );
};
export default PageNavigator;
