import React, { useEffect, useState } from 'react';

import Footer from '@components/Footer';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import { SavedSearchCard } from '@components/SavedSearchCard';
import SavedContentPageWrapper from '@components/SavedContentPageWrapper';
import Sort from '@components/Sort';
import { FilterOption } from '@components/Sort/Sort.types';
import { Typography } from '@components/Typography';
import { useUserSavedSearches } from '@/hooks/useUserSavedSearches';
import { useMediaQuery } from 'react-responsive';

const A_TO_Z = 'aToZ';
const Z_TO_A = 'zToA';
let filterOptions: FilterOption[] = [
  {
    label: 'Newest-Oldest',
    value: 'createdAt',
  },
  {
    label: 'Name (A - Z)',
    value: A_TO_Z,
  },
  {
    label: 'Name (Z - A)',
    value: Z_TO_A,
  },
];

export const SavedSearches: React.FC = () => {
  const { isSavedSearchesLoading, savedSearches } = useUserSavedSearches();
  const [localSavedSearches, setLocalSavedSearches] = useState(savedSearches);
  const [sortedSearches, setSortedSearches] = useState(savedSearches);
  const isMobileScreen = useMediaQuery({ query: '(max-width: 499px)' });
  useEffect(() => {
    setLocalSavedSearches(savedSearches);
  }, [savedSearches]);

  const [selectedFilter, setSelectedFilter] = useState<FilterOption | undefined>(filterOptions[0]);

  useEffect(() => {
    let result = [...savedSearches]; // Create a shallow copy of the original listings array
    switch (selectedFilter?.value) {
      case A_TO_Z:
        result.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case Z_TO_A:
        result.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'createdAt':
        result.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        break;
      default:
        break;
    }
    setSortedSearches(result);
  }, [localSavedSearches, selectedFilter]);

  const SavedSearchCount = sortedSearches.length > 0 ? '(' + sortedSearches.length + ')' : '';

  return (
    <>
      <SavedContentPageWrapper>
        <div className="flex items-center justify-between mb-4 lg:mb-6 h-[3.125rem]">
          <Typography variant="title">My Saved Searches {SavedSearchCount}</Typography>
          <div className="min-w-fit">
            <Sort
              name="sort saved searches"
              filterOptions={filterOptions}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              customButtonLabel="Sort"
              showCustomButtonLabel={isMobileScreen}
            />
          </div>
        </div>

        {isSavedSearchesLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="flex flex-col space-y-6">
            {sortedSearches.length > 0 ? (
              sortedSearches?.map((savedSearch, index) => (
                <SavedSearchCard
                  key={`${savedSearch.savedSearchId}-${index}`}
                  savedSearch={savedSearch}
                />
              ))
            ) : (
              <div className="p-6 rounded-lg shadow bg-base-white">
                <Typography className="mb-1" variant="subtitle-1">
                  No Saved Searches
                </Typography>

                <Typography className="text-cement-500" variant="body-2">
                  Create a search in the home page and click on &apos;Save Search&apos;, to see your
                  search here.
                </Typography>
              </div>
            )}
          </div>
        )}
      </SavedContentPageWrapper>

      <Footer />
    </>
  );
};

export default SavedSearches;
