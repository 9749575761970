import { createContext, useState } from 'react';
export type selectedListingInCart = {
  listingId: string;
  listingName: string;
};
interface ReportCartContextValue {
  cartList: Set<selectedListingInCart>;
  addAllToCart: (selectedListings: selectedListingInCart[]) => void;
  addToCart: (selectedListingInCart: selectedListingInCart) => void;
  isAdded: (selectedListingInCart: selectedListingInCart) => boolean;
  removeFromCart: (selectedListing: selectedListingInCart) => void;
  removeSelectedFromCart: (selectedListings: selectedListingInCart[]) => void;
  clearCart: () => void;
}

export const CartContext = createContext<ReportCartContextValue>({} as ReportCartContextValue);

export const CartProvider: React.FC<any> = ({ children }) => {
  const [cartList, setCartList] = useState<Set<selectedListingInCart>>(new Set());

  const addAllToCart = (selectedListings: selectedListingInCart[]) => {
    const updatedSet: Set<selectedListingInCart> = new Set(cartList);
    selectedListings.forEach((selectedListing) => {
      if (!isAdded(selectedListing)) {
        updatedSet.add(selectedListing);
      }
    });
    setCartList(updatedSet);
  };

  const addToCart = (selectedListing: selectedListingInCart) => {
    const updatedSet = new Set(cartList);
    if (!isAdded(selectedListing)) {
      updatedSet.add(selectedListing);
    }
    setCartList(updatedSet);
  };

  const removeFromCart = (selectedListing: selectedListingInCart) => {
    const updatedSet = new Set(cartList);
    updatedSet.forEach((listing) => {
      if (listing.listingId === selectedListing.listingId) {
        updatedSet.delete(listing);
      }
    });
    setCartList(updatedSet);
  };

  const removeSelectedFromCart = (selectedListings: selectedListingInCart[]) => {
    const updatedSet = new Set(cartList);
    selectedListings.forEach((selectedListing) => {
      updatedSet.forEach((listing) => {
        if (listing.listingId === selectedListing.listingId) {
          updatedSet.delete(listing);
        }
      });
    });
    setCartList(updatedSet);
  };

  const isAdded = (selectedListing: selectedListingInCart): boolean => {
    return Array.from(cartList)
      .map((item) => item.listingId)
      .includes(selectedListing.listingId);
  };

  const clearCart = () => {
    setCartList(new Set());
  };

  const contextValue: ReportCartContextValue = {
    addAllToCart,
    isAdded,
    removeFromCart,
    removeSelectedFromCart,
    addToCart,
    cartList,
    clearCart,
  };

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};
