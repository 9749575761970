import { useState } from 'react';
import cn from 'classnames';
import { Typography } from '@components/Typography';
import placeholderImage from '@images/placeholder.png';

type ThumbnailProps = {
  imgClassNames?: string;
  propertyImageUrl?: string;
  showText?: boolean;
  children?: JSX.Element | JSX.Element[];
  onClick?: () => void;
};

const Thumbnail: React.FC<ThumbnailProps> = ({
  imgClassNames,
  propertyImageUrl,
  showText = true,
  children,
  onClick,
}) => {
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(!propertyImageUrl);

  return (
    <div className="relative">
      <img
        role="none"
        className={cn('object-cover', imgClassNames)}
        alt={showPlaceholder ? 'image coming soon' : 'thumbnail of property'}
        src={showPlaceholder ? placeholderImage : propertyImageUrl}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = placeholderImage;
          setShowPlaceholder(true);
        }}
        onClick={onClick}
        data-testid="property-image-url"
      />
      {showPlaceholder && (
        <div className="absolute top-0 flex items-center justify-center w-full h-full overflow-hidden bg-slate-200/80 text-white-100">
          {showText && (
            <Typography variant="h5" className="text-center">
              Image Coming Soon
            </Typography>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default Thumbnail;
