import React, { useEffect, useState } from 'react';

import { Typography } from '@components/Typography';
import TrafficCone from '@images/traffic-cone.png';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import { marketplaceApiInstance } from '@/lib/axios';
import { API_LISTINGS_BASE_URL } from '@/config';
import { AppConfig } from '@/types/app-config';
import { MAINTENCANCE_END_LABEL_APPCONFIG_KEY } from '@/utilities/constants';

export const Maintenance: React.FC = () => {
  // Get App Config
  const [maintenanceLabel, setMaintenanceLabel] = useState(() => {
    const label = sessionStorage.getItem(MAINTENCANCE_END_LABEL_APPCONFIG_KEY);
    return label;
  });
  const isBiScreen = useIsBigScreen();

  useEffect(() => {
    // We cannot make use the useApiClient() hook here because the context is not ready at this point.
    // So we will make a direct axios call to get the config value.
    marketplaceApiInstance
      .get<AppConfig>(
        `${API_LISTINGS_BASE_URL}/appconfig?key=${MAINTENCANCE_END_LABEL_APPCONFIG_KEY}`,
      )
      .then((res) => {
        const appConfig = res?.data;
        if (appConfig?.value) {
          setMaintenanceLabel(appConfig?.value);
          sessionStorage.setItem(MAINTENCANCE_END_LABEL_APPCONFIG_KEY, appConfig?.value);
        } else {
          sessionStorage.removeItem(MAINTENCANCE_END_LABEL_APPCONFIG_KEY);
        }
      });
  }, []);

  return (
    <div className="h-[100vh] flex justify-center items-center bg-[linear-gradient(0deg,rgba(255,255,255,0.85),rgba(255,255,255,0.85)),url('/src/assets/images/landing-page-background.png')] bg-center bg-cover bg-fixed bg-no-repeat">
      <div className="flex sm:flex-col sm:items-center md:flex-column">
        <img
          alt="maintenance-logo"
          className="sm:mr-0 sm:mb-7 md:mb-0 sm:w-[105px] sm:h-[100%] md:h-[216px] md:w-[193px]"
          src={TrafficCone}
        />

        <div className="flex flex-col justify-center sm:items-center">
          <Typography className="text-slate-500 mb-[0.625rem]" variant={isBiScreen ? 'h3' : 'h4'}>
            Site under maintenance.
          </Typography>
          <Typography className="text-slate-500 mt-2 mb-1 text-center px-2" variant="body-1">
            We are making improvements to Property Search to enhance your experience.
          </Typography>
          <Typography className="text-slate-500 mb-14 text-center px-2" variant="body-1">
            Please come back at {maintenanceLabel} to see what we have been up to.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
