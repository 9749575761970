import { LinkMailToProps } from './LinkMailTo.types';

const LinkMailTo: React.FC<LinkMailToProps> = ({ email, label }) => {
  const link = `mailto:${email}`;
  return (
    <>
      <a
        className="text-[0.6875rem] underline hover:text-slate-300"
        onClick={(e) => e.stopPropagation()}
        href={link}
        target="_blank"
        rel="noreferrer">
        {label}
      </a>
    </>
  );
};

export default LinkMailTo;
