import React, { useCallback, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import { Box, SliderMark } from '@mui/material';

const valueStart = 25;
const marks = [
  {
    value: 0,
    scaledValue: 0,
    label: '0',
  },
  {
    value: valueStart,
    scaledValue: 5,
    label: '5',
  },
  {
    value: valueStart * 2,
    scaledValue: 10,
    label: '10',
  },
  {
    value: valueStart * 3,
    scaledValue: 25,
    label: '25',
  },
  {
    value: valueStart * 4,
    scaledValue: 100,
    label: '100',
  },
  {
    value: valueStart * 5,
    scaledValue: 250,
    label: '250',
  },
  {
    value: valueStart * 6,
    scaledValue: 500,
    label: '500',
  },
  {
    value: valueStart * 7,
    scaledValue: 750,
    label: '750',
  },
];

// ScaleUp the value when sending value from Slider-To-Textbox
const scaleUp = (value: number) => {
  const previousMarkIndex = Math.floor(value / valueStart);
  const previousMark = marks[previousMarkIndex];
  const remainder = value % valueStart;
  if (remainder === 0) {
    return previousMark?.scaledValue;
  }
  const nextMark = marks[previousMarkIndex + 1];
  const increment = (nextMark?.scaledValue - previousMark?.scaledValue) / valueStart;
  return Math.round(remainder * increment + previousMark?.scaledValue);
};

// ScaleDown the value when sending value from Textbox-To-Slider.
// Used when user enters a value directly in the input field in parent component
const scaleDown = (inputScaledValue: number) => {
  const startingMarkIndex = marks.findIndex((m: any) => m.scaledValue >= inputScaledValue) - 1;
  if (startingMarkIndex < 0) {
    return 0;
  }
  if (startingMarkIndex === marks.length) {
    return marks[startingMarkIndex]?.value;
  }
  const startingMark = marks[startingMarkIndex];
  const nextMark = marks[startingMarkIndex + 1];

  const scaledIncrementPercent =
    (inputScaledValue - startingMark?.scaledValue) /
    (nextMark?.scaledValue - startingMark?.scaledValue);

  const valueIncrement = nextMark?.value - startingMark?.value;
  return Math.round(scaledIncrementPercent * valueIncrement + startingMark?.value);
};

type SliderProps = {
  defaultValue: number | undefined;
  onChange: (value: number, slideComplete: boolean) => void;
};

type Mark = {
  label?: string;
  value?: number;
  scaledValue?: number;
};

export const CustomMark = (props: any) => {
  const { onCommitted } = props;
  const index = props['data-index'] as number;
  const mark = props.ownerState.marks[index] as Mark;
  const markValue = mark.value;

  const onClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (markValue != null) {
        onCommitted(e, markValue, true);
      }
    },
    [onCommitted, markValue],
  );

  const noop = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  return <SliderMark onMouseDown={noop} onTouchStart={noop} onClick={onClick} {...props} />;
};

export const NonLinearSlider: React.FC<SliderProps> = ({ defaultValue, onChange }) => {
  const [value, setValue] = React.useState(scaleDown(defaultValue!));

  // When textbox value changes, reflect it on slider
  useEffect(() => {
    setValue(scaleDown(defaultValue!));
  }, [defaultValue]);

  // onMouseUp
  const handleChangeCommitted = (e: any, newValue: any, fromMarker: boolean = false) => {
    // When slider changes, update the textbox value
    if (fromMarker) {
      setValue(newValue);
    }
    onChange(scaleUp(newValue), true);
  };

  // onMouseDown, onMouseMove
  const handleChange = (e: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: 200 }}>
      <Slider
        value={value}
        min={0}
        step={1}
        max={valueStart * 7}
        marks={marks}
        scale={scaleUp}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="off"
        aria-labelledby="non-linear-slider"
        slotProps={{
          mark: {
            // @ts-ignore
            onCommitted: handleChangeCommitted,
          },
        }}
        slots={{
          mark: CustomMark,
        }}
        sx={{
          '& .MuiSlider-markLabel': {
            color: '#ccc',
            fontSize: 11,
          },
          '&.MuiSlider-root': {
            color: '#eb6547',
            height: '6px',
          },
          '& .MuiSlider-mark': {
            width: '6px',
            height: '6px',
            borderRadius: '6px',
            backgroundColor: 'white',
            '&:hover': {
              width: '8px',
              height: '8px',
              outline: '4px solid #eb6547',
            },
          },
          '& .MuiSlider-thumb': {
            width: '16px',
            height: '16px',
            backgroundColor: 'white',
            border: '4px solid #eb6547',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:focus': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
            },
            '::after': {
              width: '16px',
              height: '16px',
            },
          },
        }}
      />
    </Box>
  );
};
