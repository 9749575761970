import { MenuItem } from '@szhsin/react-menu';
import React from 'react';
import cn from 'classnames';

import { Icon, IconName } from '@components/Icon';
import { Typography } from '@components/Typography';

interface AuthenticationMenuItemProps {
  label?: string;
  iconName?: IconName;
  onClick?: () => void;
  children?: any;
  hover?: boolean;
  menuClasses?: string;
}

const AuthenticationMenuItem: React.FC<AuthenticationMenuItemProps> = ({
  iconName,
  label,
  onClick,
  children,
  hover,
  menuClasses,
}) => {
  const menuItemClass = cn([
    'flex items-center py-4 md:p-4  space-x-2 cursor-pointer bg-solar-500',
    hover && 'hover:bg-slate-500',
    // menu library adds pointer cursors to all items on hover by
    // default; overriding with default cursor for non-clickable items
    !hover && 'cursor-default',
    menuClasses,
  ]);

  return (
    <MenuItem onClick={onClick} className={menuItemClass}>
      {iconName && <Icon classNames="text-base-white !w-4 !h-4" name={iconName} />}
      {!!label && (
        <Typography className="text-base-white" variant="body-3">
          {label}
        </Typography>
      )}
      {children}
    </MenuItem>
  );
};

export default AuthenticationMenuItem;
