import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import BackHeader from '@components/BackHeader';
import Tab from '@components/Tab';
import './Bookmarks.css';

export const Bookmarks: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-col justify-between">
      <BackHeader to={-1}>
        <div className="flex md:space-x-10 bookmarks-container">
          <Tab
            label="Favorites"
            active={location.pathname === '/bookmarks/favorites'}
            onClick={() => navigate('favorites')}
          />
          <Tab
            label="Collections"
            active={location.pathname.includes('collections')}
            onClick={() => navigate('collections')}
          />
          <Tab
            label="Saved Searches"
            active={location.pathname === '/bookmarks/saved-searches'}
            onClick={() => navigate('saved-searches')}
          />
        </div>
      </BackHeader>

      <Outlet />
    </div>
  );
};
