import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import cn from 'classnames';
import React from 'react';

import { Icon, IconName } from '@components/Icon';
import { Typography } from '@components/Typography';
import { CollectionCardOptionProps } from './CollectionCard.types';

const CollectionCardOptions: React.FC<CollectionCardOptionProps> = ({
  collectionId,
  copyLinkHandler,
  createOpHandler,
  deleteHandler,
  exportOpHandler,
  numberOfListings,
  renameHandler,
}) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="absolute right-[-0.5625rem] top-[-8px] sm:top-1.5">
      <Menu
        align="end"
        menuButton={
          <MenuButton>
            <Icon classNames="text-cement-500" name={IconName.MORE_VERTICAL} />
          </MenuButton>
        }
        menuClassName="collection-card-option-menu"
        gap={-3}
        shift={6}>
        <MenuItem
          className="collection-card-option-menu-item"
          onClick={() => deleteHandler(collectionId)}>
          <Icon name={IconName.TRASH} />
          <Typography variant="button">Delete</Typography>
        </MenuItem>
        <MenuItem
          className="collection-card-option-menu-item"
          onClick={() => renameHandler(collectionId)}>
          <Icon name={IconName.EDIT} />
          <Typography variant="button">Rename</Typography>
        </MenuItem>
        <MenuItem
          className={cn([
            'collection-card-option-menu-item',
            numberOfListings === 0 ? 'disabled' : undefined,
          ])}
          disabled={numberOfListings === 0}
          onClick={() => copyLinkHandler(collectionId)}>
          <Icon name={IconName.EXTERNAL_LINK} />
          <Typography variant="button">Share Collection</Typography>
        </MenuItem>
        <div>
          <div data-tooltip-id="max-listings-tooltip" data-tooltip-target="tooltip-default">
            <MenuItem
              className={cn([
                'collection-card-option-menu-item',
                numberOfListings === 0 ? 'disabled' : undefined,
              ])}
              onClick={() => createOpHandler(collectionId)}>
              <Icon name={IconName.LEASING_AND_OCCUPANCY} />
              <Typography variant="button">Create Report</Typography>
            </MenuItem>
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default CollectionCardOptions;
