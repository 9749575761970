import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { MAP_3D_ZOOM_THRESHOLD, MAP_FLY_ZOOM } from '@/config';
import { AVAILABLE_SQ_FT, useAvailableSqFtButtonLabel } from '@/hooks/useAvailableSqFtButtonLabel';
import useFlyToLocation from '@/hooks/useFlyToLocation';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import { useGeolocation } from '@/pages/Map/hooks/useGeolocation';
import { useMapActions } from '@/pages/Map/hooks/useMapActions';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import { ActiveFilterQueryParam } from '@/types';
import { CURRENT_LOCATION_STRING } from '@/utilities/constants';
import AvailableSqFtFilterModal from '@components/AvailableSqFtFilterModal';
import Button from '@components/Button';
import { ButtonVariant } from '@components/Button/Button.types';
import { Icon, IconName } from '@components/Icon';
import {
  DatePicker,
  DatePickerMode,
  DatePickerSelectedDate,
  DatePickerTimeFrames,
} from '@components/Inputs';
import Link, { LinkVariant } from '@components/Link';
import SearchInput from '@components/Map/SearchInput';
import MapFilterModalButton from '@components/MapFilterModalButton';
import MoreFiltersModal from '@components/MoreFiltersModal';
import SaveSearchModal from '@components/SaveSearchModal';
import { getUnixTime } from 'date-fns';
import './MapFilterHeader.css';
import { useIsAuthenticated } from '@azure/msal-react';

export const MapFilterHeader: React.FC = () => {
  const isBigScreen = useIsBigScreen();
  const isAuthenticated = useIsAuthenticated();
  const [showSaveSearchButton, setShowSaveSearchButton] = useState(isAuthenticated);
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);

  const { flyToLocation } = useFlyToLocation();
  const { updateCurrentLocation } = useGeolocation();
  const {
    dateAvailable,
    dateTimeFrame,
    unitSquareFootageAvailableRange,
    clearHeightRange,
    marketIds,
    submarketIds,
    listingTypes,
    loadingDocksRange,
    searchedLocation,
    classifications,
    accountOwnerships,
    railServed,
    trailerParking,
    outdoorStorage,
    esfr,
    fund,
    clearAllFilters,
    updateSearchParameters,
    clearFilters,
    listings,
    setSelectedListing,
    setSelectedOption,
    setSearchedLocation,
    resetDatePicker,
    updateDateAvailable,
    updateDateTimeFrame,
    setDistanceOption,
    distanceFilter,
  } = useMapData();
  const { reset } = useMapActions();
  const availableSqFtButtonLabel = useAvailableSqFtButtonLabel(unitSquareFootageAvailableRange);

  useEffect(() => {
    unitSquareFootageAvailableRange.min ||
    unitSquareFootageAvailableRange.max ||
    dateAvailable ||
    dateTimeFrame ||
    clearHeightRange.min ||
    clearHeightRange.max ||
    loadingDocksRange.min ||
    loadingDocksRange.max ||
    marketIds.length ||
    submarketIds.length ||
    listingTypes.length ||
    classifications.length ||
    accountOwnerships.length ||
    railServed === true ||
    searchedLocation ||
    trailerParking === true ||
    outdoorStorage === true ||
    esfr === true ||
    fund
      ? setShowSaveSearchButton(isAuthenticated)
      : setShowSaveSearchButton(false);
  }, [
    unitSquareFootageAvailableRange.min,
    unitSquareFootageAvailableRange.max,
    dateAvailable,
    dateTimeFrame,
    clearHeightRange.min,
    clearHeightRange.max,
    loadingDocksRange.min,
    loadingDocksRange.max,
    marketIds,
    submarketIds,
    listingTypes,
    classifications,
    accountOwnerships,
    railServed,
    searchedLocation,
    trailerParking,
    outdoorStorage,
    esfr,
    fund,
  ]);

  const handleDatePickerOnSelect = (selection: DatePickerSelectedDate, mode: DatePickerMode) => {
    if (!selection) {
      updateSearchParameters([
        [ActiveFilterQueryParam.DATE_TIMEFRAME, ''],
        [ActiveFilterQueryParam.DATE_AVAILABLE, ''],
      ]);
      resetDatePicker();
    } else {
      switch (mode) {
        case DatePickerMode.TIMEFRAME:
          if (selection === '180') {
            let currentDate = new Date();
            let nextSixMonthDate = new Date();
            nextSixMonthDate.setMonth(nextSixMonthDate.getMonth() + 6);

            const totalDays = Math.round(
              (nextSixMonthDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
            );

            updateSearchParameters([
              [ActiveFilterQueryParam.DATE_TIMEFRAME, totalDays ?? ''],
              [ActiveFilterQueryParam.DATE_AVAILABLE, ''],
            ]);
          } else {
            updateSearchParameters([
              [ActiveFilterQueryParam.DATE_TIMEFRAME, (selection as DatePickerTimeFrames) ?? ''],
              [ActiveFilterQueryParam.DATE_AVAILABLE, ''],
            ]);
          }
          updateDateAvailable(undefined);
          break;
        case DatePickerMode.DATE:
          updateSearchParameters([
            [ActiveFilterQueryParam.DATE_AVAILABLE, getUnixTime(selection as Date)],
            [ActiveFilterQueryParam.DATE_TIMEFRAME, ''],
          ]);
          updateDateTimeFrame(undefined);
          break;
      }
    }
  };

  return (
    <div className="bg-slate-500 flex items-center justify-between lg:justify-between sm:py-3 sm:px-6 lg:px-3 w-full">
      <div className="flex h-12 sm:flex-1 lg:flex-initial">
        <SearchInput
          classNames="map-search-input sm:flex-1 lg:flex-initial"
          clearFilters={clearFilters}
          dropdownHeight={40}
          showMyLocation
          onMyLocation={async () => {
            updateSearchParameters([
              [ActiveFilterQueryParam.SEARCHED_LOCATION, CURRENT_LOCATION_STRING],
              [ActiveFilterQueryParam.INITIAL_VIEW_STATE, null],
              [ActiveFilterQueryParam.MARKET_IDS, null],
              [ActiveFilterQueryParam.SUBMARKET_IDS, null],
            ]);
            const currentLocation = await updateCurrentLocation();
            if (!currentLocation) {
              updateSearchParameters([[ActiveFilterQueryParam.SEARCHED_LOCATION, null]]);
              return;
            }
            const { longitude, latitude } = currentLocation;
            setSearchedLocation(CURRENT_LOCATION_STRING);
            setDistanceOption({
              optionIndex: -1,
              category: 'locations',
              center: [longitude, latitude],
              boundingBox: null,
              displayName: 'Current Location',
              listingId: null,
              types: ['currentLocation'],
            });
            flyToLocation([longitude, latitude], MAP_FLY_ZOOM);
          }}
          onSelect={(selectedOption) => {
            if (!selectedOption) {
              setSelectedListing(null);
              setSelectedOption(null);
              return;
            }

            if (selectedOption.category === 'markets') {
              return;
            }

            const bounds = selectedOption.boundingBox ?? selectedOption.center;
            let zoom: number | undefined;

            switch (selectedOption.category) {
              case 'locations': {
                setSelectedOption(selectedOption);
                zoom = MAP_FLY_ZOOM;
                setDistanceOption(selectedOption);
                break;
              }

              case 'listingNames': {
                setSelectedOption(selectedOption);
                const listing = listings.find((x) => String(x.id) === selectedOption.listingId);
                setSelectedListing(listing ?? null);
                setDistanceOption(selectedOption);
                zoom = MAP_3D_ZOOM_THRESHOLD;
                break;
              }

              case 'propertyAddresses': {
                setSelectedOption(selectedOption);
                setDistanceOption(selectedOption);
                setSelectedListing(null);

                zoom = MAP_3D_ZOOM_THRESHOLD;
                break;
              }
            }

            flyToLocation(bounds, zoom);
          }}
          selection={searchedLocation}
        />

        {isAuthenticated && (
          <DatePicker
            classNames="map-date-input"
            disablePastDays
            onSelect={(selection, mode) => handleDatePickerOnSelect(selection, mode)}
            placeholder={'Date Available'}
            selectedDate={dateAvailable}
            selectedTimeFrame={dateTimeFrame}
          />
        )}

        <MapFilterModalButton
          buttonLabel={availableSqFtButtonLabel}
          classNames="map-filter-modal"
          disableMousedownEventListener
          Modal={AvailableSqFtFilterModal}
          onRemoveInputValueClearingPill={() => {
            updateSearchParameters([
              [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX, ''],
              [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN, ''],
            ]);
          }}
          showInputValueClearingPill={availableSqFtButtonLabel !== AVAILABLE_SQ_FT}
          sqFtRange={unitSquareFootageAvailableRange}
          maxWidth="max-w-[6.94rem]"
        />

        <MapFilterModalButton
          classNames="map-more-filters"
          buttonLabel="More"
          Modal={MoreFiltersModal}
          showFilterCount
          isFilterButton
        />

        <div className="flex justify-between map-clear-filters flex-col">
          <Link
            classNames="!text-xs"
            onClick={() => {
              clearAllFilters();
              if (!distanceFilter) {
                reset();
              }
            }}
            variant={LinkVariant.DARK_THEME}>
            <Icon name={IconName.REFRESH} classNames="p-1" />
            Clear Filters
          </Link>
        </div>
      </div>

      <div className="flex h-12">
        {showSaveSearchButton && (
          <div className="ml-2 map-search-button">
            <Button
              buttonClassNames={cn([
                '!px-4',
                showSaveSearchModal && '!border-freight-100 !text-freight-100 !h-12',
              ])}
              label={isBigScreen ? 'Save Search' : ''}
              Icon={<Icon name={IconName.BOOKMARK} />}
              onClick={() => {
                setShowSaveSearchModal(!showSaveSearchModal);
              }}
              variant={ButtonVariant.DEFAULT_OUTLINE_DARK_THEME}
            />
          </div>
        )}
      </div>

      {showSaveSearchModal && (
        <SaveSearchModal
          onClose={() => {
            setShowSaveSearchModal(false);
          }}
        />
      )}
    </div>
  );
};

export default MapFilterHeader;
