import React, { useState, useRef, useEffect, useMemo } from 'react';
import cn from 'classnames';

import Button, { ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import { FilterOption, SortProps } from './Sort.types';
import './Sort.css';
import { ActiveFilterQueryParam as SearchParameterNames } from '@/types';
import { useMapData } from '@/pages/Map/hooks/useMapData';

const Sort: React.FC<SortProps> = ({
  onFilterSelect,
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  disabled,
  showCustomButtonLabel,
  customButtonLabel,
  setSortSelection,
}) => {
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(
    selectedFilter ? filterOptions.indexOf(selectedFilter) : -1,
  );
  const { updateSearchParameters } = useMapData();
  const [isOpen, setIsOpen] = useState(false);

  const sortRef = useRef<HTMLDivElement>(null);

  useMemo(() => {
    setFocusedOptionIndex(selectedFilter ? filterOptions.indexOf(selectedFilter) : -1);
  }, [selectedFilter]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sortRef.current && !sortRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // used for traversing dropdown list via keyboard
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isOpen) {
      return;
    }
    switch (event.key) {
      case 'ArrowDown': // move down list (uses remainder to allow index to be reset to 0 when last option is reached)
        event.preventDefault();
        const nextOptionIndex = (focusedOptionIndex + 1) % filterOptions.length;
        setFocusedOptionIndex(nextOptionIndex);
        break;
      case 'ArrowUp': // move up list (uses remainder to allow index to be reset to last index when first option is reached)
        event.preventDefault();
        const prevOptionIndex =
          (focusedOptionIndex + filterOptions.length - 1) % filterOptions.length;
        setFocusedOptionIndex(prevOptionIndex);
        break;
      case 'Enter': // hide dropdown list (un-focus) and reset state
        event.preventDefault();
        setSelectedFilter(filterOptions[focusedOptionIndex]);
        if (updateSearchParameters) {
          updateSearchParameters([
            [SearchParameterNames.SORT, filterOptions[focusedOptionIndex].value],
          ]);
        }
        setIsOpen(false);
        break;
      case 'Escape': // select the current dropdown list option
        !selectedFilter && setFocusedOptionIndex(-1);
        setIsOpen(false);
        break;
    }
  };

  const handleSelect = (option: FilterOption) => {
    if (updateSearchParameters) {
      updateSearchParameters([[SearchParameterNames.SORT, option.value]]);
    }
    setSortSelection && setSortSelection(option.value);
    setFocusedOptionIndex(filterOptions.indexOf(option));
    setSelectedFilter(option);
    setIsOpen(false);
    if (!!onFilterSelect) {
      onFilterSelect(option);
    }
  };

  const filterOptionClassNames = (option: FilterOption) =>
    cn(
      ['filter-option'],
      filterOptions.findIndex((item: FilterOption) => item.label === option.label) ===
        focusedOptionIndex
        ? 'font-semibold'
        : '',
    );
  const label = showCustomButtonLabel ? customButtonLabel : selectedFilter?.label;

  return (
    <div className="sort-outer-container" ref={sortRef}>
      <div className="sort-inner-container" onKeyDown={handleKeyDown}>
        <Button
          disabled={disabled}
          Icon={<Icon name={IconName.SORT} />}
          label={label ?? 'Sort'}
          onClick={() => setIsOpen(!isOpen)}
          variant={ButtonVariant.NO_FILL}
        />

        {isOpen && (
          <div className="filter-list z-20" data-testid="listbox">
            <ul aria-labelledby="selected-filter" role="listbox">
              {filterOptions.map((filterOption: FilterOption) => (
                <li
                  key={filterOption.label}
                  aria-selected={filterOption.value === selectedFilter?.value}
                  className={filterOptionClassNames(filterOption)}
                  onClick={() => handleSelect(filterOption)}
                  role="option">
                  {filterOption.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sort;
