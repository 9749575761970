import { ConstrainedTypography } from '@components/ConstrainedTypography';
import { Icon, IconName } from '@components/Icon';
import { Typography } from '@components/Typography';
import cn from 'classnames';
import { groupBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import InputValuePill from '../InputValueClearingPill';
import './DropdownSelector.css';
import {
  DropdownSelectorColor,
  DropdownSelectorOption,
  DropdownSelectorProps,
} from './DropdownSelector.types';
import { handleDownConditions, handleUpConditions } from './utils';

const DropdownSelector: React.FC<DropdownSelectorProps> = ({
  displayPill,
  classNames,
  clearFilters,
  color = DropdownSelectorColor.WHITE,
  disabled,
  error,
  errorMessage,
  id = '',
  ignoreFocusedBorder,
  isCheckboxDropdown,
  name,
  onSelect,
  options,
  placeholder = '',
  renderOption,
  selectedOption,
  showList = false,
  isValuePill,
  isSubmarket,
  buttonWrapperClassName = '',
}) => {
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(0);
  const [focusedChildsParentOptionIndex, setFocusedChildsParentOptionIndex] = useState(0);
  const [focusedChildOptionIndex, setFocusedChildOptionIndex] = useState('-');
  const [isOpen, setIsOpen] = useState(showList);

  const dropdownSelectorRef = useRef<HTMLDivElement>(null);

  const optionSelected = selectedOption && !showList;
  const showErrorMessage = error && errorMessage && optionSelected;
  const selectAllClass =
    options.findIndex((item) => item.label === 'Select All') === -1 ? 'ul-display-block' : '';

  // required to clear all state via MoreFiltersModal clear all filters onClick
  useEffect(() => {
    if (clearFilters) {
      setFocusedOptionIndex(0);
      setFocusedChildsParentOptionIndex(0);
      setFocusedChildOptionIndex('0-0');
      onSelect('', name);
    }
  }, [clearFilters]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownSelectorRef.current &&
        !dropdownSelectorRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // used for traversing dropdown list via keyboard
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isOpen) {
      return;
    }
    switch (event.key) {
      case 'ArrowDown': {
        // move down list (uses remainder to allow index to be reset to 0 when last option is reached)
        event.preventDefault();
        const childs = options[focusedChildsParentOptionIndex]?.childs;
        const focusedSingleChildOptionIndex = Number(focusedChildOptionIndex.split('-')[1]);
        handleDownConditions(options, childs, {
          focusedChildOptionIndex,
          focusedSingleChildOptionIndex,
          focusedChildsParentOptionIndex,
          setFocusedOptionIndex,
          setFocusedChildsParentOptionIndex,
          setFocusedChildOptionIndex,
        });
        break;
      }
      case 'ArrowUp': {
        // move up list (uses remainder to allow index to be reset to last index when first option is reached)
        event.preventDefault();
        const prevOptionIndex = focusedChildsParentOptionIndex - 1;
        const childs = options[prevOptionIndex === -1 ? 0 : prevOptionIndex]?.childs;
        const focusedSingleChildOptionIndex = Number(focusedChildOptionIndex.split('-')[1]);
        handleUpConditions(prevOptionIndex, options, childs, {
          focusedOptionIndex,
          focusedChildOptionIndex,
          focusedSingleChildOptionIndex,
          focusedChildsParentOptionIndex,
          setFocusedOptionIndex,
          setFocusedChildsParentOptionIndex,
          setFocusedChildOptionIndex,
        });
        break;
      }
      case 'Enter': {
        // hide dropdown list (un-focus) and reset state
        event.preventDefault();
        onSelect(options[focusedOptionIndex], name);
        setIsOpen(false);
        break;
      }
      case 'Escape': {
        // select the current dropdown list option
        (placeholder || !selectedOption) && setFocusedOptionIndex(0);
        setIsOpen(false);
        break;
      }
    }
  };

  // sets focused option index and predictive value
  const handleOptionHover = (optionIndex: number, id: string) => {
    setFocusedChildOptionIndex('-');
    setFocusedOptionIndex(optionIndex);
    setFocusedChildsParentOptionIndex(optionIndex);
  };
  const handleChildOptionHover = (optionIndex: number, childOptionIndex: number, id: string) => {
    setFocusedOptionIndex(-1);
    setFocusedChildsParentOptionIndex(optionIndex);
    setFocusedChildOptionIndex(optionIndex + '-' + childOptionIndex);
  };

  const handleSelect = (option: DropdownSelectorOption) => {
    onSelect(option, name);
    setFocusedOptionIndex(options.indexOf(option));
    setFocusedChildsParentOptionIndex(options.indexOf(option));
    !isCheckboxDropdown && setIsOpen(false);
  };

  const dropdownListOptionClassNames = (option: DropdownSelectorOption) =>
    cn(
      ['dropdown-list-option'],
      options.findIndex((item) => item.label === option.label) === focusedOptionIndex
        ? 'bg-slate-500'
        : '',
    );

  const dropdownListOptionChildsClassNames = (
    currentOptionIndex: number,
    currentChildOptionIndex: number,
  ) => {
    const combinedFocusedChildOptionIndex = currentOptionIndex + '-' + currentChildOptionIndex;
    return cn(
      ['dropdown-list-option !pl-8'],
      combinedFocusedChildOptionIndex === focusedChildOptionIndex ? 'bg-slate-500' : '',
    );
  };

  const buttonClassNames = cn(
    'dropdown-selector-btn focus:outline-none text-left w-[calc(100%-2rem)] h-12',
    optionSelected && 'font-medium',
    disabled && '!cursor-not-allowed',
  );
  const buttonWrapperClassNames = cn([
    'button-wrapper',
    buttonWrapperClassName,
    color,
    isOpen && !ignoreFocusedBorder && color !== DropdownSelectorColor.DARKSLATE && '!border-black',
    isCheckboxDropdown && isOpen && 'rounded-b-none',
    error && errorMessage && '!border-freight-100',
    disabled && '!cursor-not-allowed !bg-cement-100 !border-cement-100 text-cement-300',
  ]);

  const submarketOptionsGroupBy = groupBy(options, 'parentLabel');

  return (
    <div className={cn(['flex text-sm', classNames])} ref={dropdownSelectorRef}>
      <div
        className={cn(['h-full outline-none relative text-sm w-full'])}
        onKeyDown={handleKeyDown}
        role="none">
        <div
          className={buttonWrapperClassNames}
          data-testid="buttonWrapper"
          onClick={!disabled ? () => setIsOpen(!isOpen) : undefined}
          onKeyUp={() => {}}
          role="none">
          <button
            aria-disabled={disabled}
            aria-haspopup="listbox"
            aria-expanded={isOpen}
            aria-labelledby={`selected-option-${id}`}
            className={buttonClassNames}
            data-testid="button"
            disabled={disabled}
            key={`button-${id}`}
            type="button">
            <span id={`selected-option-${id}`}>
              {!(selectedOption as DropdownSelectorOption)?.value ? placeholder : ''}
            </span>
          </button>

          <Icon
            classNames="!h-6 pointer-event-none !w-6"
            name={isOpen ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
          />

          {optionSelected && isValuePill && (
            <InputValuePill
              onRemove={() => handleSelect({ label: '', value: '' })}
              value={(selectedOption as DropdownSelectorOption)?.label}
            />
          )}

          {optionSelected && !isValuePill && (
            <div
              className={cn([
                'absolute  flex space-x-2 h-8 items-center px-1.5 rounded-lg z-10',
                displayPill ? 'bg-cement-100' : '',
              ])}>
              <ConstrainedTypography variant="body-3">
                {(selectedOption as DropdownSelectorOption)?.label}
              </ConstrainedTypography>
            </div>
          )}
        </div>

        {showErrorMessage && <p className="mt-1.5 text-freight-100">{errorMessage}</p>}

        {isOpen && !isSubmarket && (
          <div className="dropdown-list" data-testid="listbox">
            <ul aria-labelledby={`selected-option-${id}`} className={selectAllClass}>
              {options.map((option, index) => {
                const hasOptions = option.childs !== undefined && option.childs.length > 0;
                const ChildOptions = (option: DropdownSelectorOption) => {
                  return option.childs!.map((childOption, childOptionIndex) => (
                    <li
                      role="none"
                      id={childOption.label}
                      key={`item-${childOption.label}-${id}`}
                      className={dropdownListOptionChildsClassNames(index, childOptionIndex)}
                      onClick={() => handleSelect(childOption)}
                      onMouseEnter={() =>
                        handleChildOptionHover(index, childOptionIndex, childOption.label)
                      }>
                      {renderOption
                        ? renderOption(
                            childOption,
                            () => onSelect(childOption, name, childOption.value as string),
                            name,
                          )
                        : childOption.label}
                    </li>
                  ));
                };

                return (
                  <div key={`item-${option.label}-${id}`}>
                    <li
                      role="none"
                      id={option.label}
                      className={dropdownListOptionClassNames(option)}
                      onClick={() => handleSelect(option)}
                      onMouseEnter={() => handleOptionHover(index, option.label)}>
                      {renderOption
                        ? renderOption(
                            option,
                            () => onSelect(option, name, option.value as string),
                            name,
                          )
                        : option.label}
                    </li>
                    {hasOptions && ChildOptions(option)}
                  </div>
                );
              })}
            </ul>
          </div>
        )}
        {isOpen && isSubmarket && (
          <div className="dropdown-list" data-testid="listbox">
            {Object.entries(submarketOptionsGroupBy).map(([parentName, submarkets]) => (
              <>
                {parentName !== 'undefined' && (
                  <>
                    <div className="flex items-center space-x-2 pt-2">
                      <Typography
                        className="py-1 uppercase text-slate-100 whitespace-nowrap"
                        variant="label-3">
                        {parentName}
                      </Typography>
                      <div className="w-full border-b border-b-slate-100" />
                    </div>
                    <br />
                  </>
                )}
                <ul
                  aria-labelledby={`selected-option-${id}`}
                  key={`option-${id}`}
                  className={selectAllClass}>
                  {submarkets.map((submarket) => {
                    const optionsIndex = options.findIndex(
                      (option: DropdownSelectorOption) => option.value === submarket.value,
                    );
                    return (
                      <li
                        role="none"
                        id={submarket.label}
                        key={submarket.label}
                        className={dropdownListOptionClassNames(submarket)}
                        onClick={() => handleSelect(submarket)}
                        onMouseEnter={() => handleOptionHover(optionsIndex, submarket.label)}>
                        {renderOption
                          ? renderOption(
                              submarket,
                              () => onSelect(submarket, name, submarket.value as string),
                              name,
                            )
                          : submarket.label + ' (' + submarket.listingsCount + ')'}
                      </li>
                    );
                  })}
                </ul>
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownSelector;
