import React from 'react';

import { Typography } from '@components/Typography';
import { UnitSpecsProps } from './UnitSpecs.types';
import { isIndustrialOrPark } from '@/utilities/constants';

const UnitSpecs: React.FC<UnitSpecsProps> = ({ attributes, propertyType, isMobileView }) => (
  <>
    <Typography className="hidden md:block mb-10 font-medium" variant="h5">
      Details
    </Typography>
    <div className="flex-col md:flex md:flex-row md:flex-wrap md:justify-between w-full">
      {attributes?.map((unitSpec) =>
        isIndustrialOrPark(propertyType) && unitSpec.label?.toLowerCase() === 'available size' ? (
          <></>
        ) : (
          <div
            key={`unit-spec-${unitSpec.key}`}
            className={
              isMobileView
                ? 'grid grid-cols-2 mb-[0.9375rem] md:w-[45%]'
                : 'border-b border-cement-200 border-dotted grid grid-cols-2 mb-[0.9375rem] md:w-[45%]'
            }>
            <Typography variant="body-2">{unitSpec.label}</Typography>
            <Typography variant="subtitle-2">{unitSpec.value}</Typography>
          </div>
        ),
      )}
    </div>
  </>
);

export default UnitSpecs;
