import { User as MicrosoftGraphUser } from '@microsoft/microsoft-graph-types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { API_LISTINGS_BASE_URL } from '@/config';
import { User, submitAnIdeaRequest } from '@/types';

export function getMe(axios: AxiosInstance) {
  return useQuery(['me'], {
    queryFn: async () => {
      try {
        const response = await axios.get<User | null>(`${API_LISTINGS_BASE_URL}/users/me`);
        return response.data;
      } catch {
        return null;
      }
    },
  });
}

type CreateMeVariables = {
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
};

export function createMe(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ firstName, lastName, emailAddress }: CreateMeVariables) => {
      const response = await axios.post<User>(`${API_LISTINGS_BASE_URL}/users/me`, {
        firstName,
        lastName,
        emailAddress,
      });

      return response.data;
    },
  });
}

export async function getMicrosoftGraphUserInfo(axios: AxiosInstance) {
  const response = await axios.get<MicrosoftGraphUser>('v1.0/me');
  return response.data;
}

export async function getMicrosoftGraphUserPhoto(axios: AxiosInstance) {
  const response = await axios.get<Blob>('v1.0/me/photos/64x64/$value', {
    responseType: 'blob',
  });

  return window.URL.createObjectURL(response.data);
}

export function submitAnIdea(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ message, submissionType }: submitAnIdeaRequest) => {
      const response = await axios.post<boolean>(`${API_LISTINGS_BASE_URL}/contact-us`, {
        message,
        submissionType,
      });

      return response.data;
    },
  });
}
