import React from 'react';
import cn from 'classnames';
import './TextArea.css';
import { TextAreaProps } from './TextArea.types';
import { decodeString } from '@/utilities/textHelpers';

const TextArea: React.FC<TextAreaProps> = (props) => {
  const {
    classNames,
    error,
    errorMessage,
    value,
    maxLength,
    maxCharactersRemaining,
    showRemainingCharactersText,
    ...rest
  } = props;

  const inputClassNames = cn(['text-area', error && 'text-area--error']);

  return (
    <div className={cn(['relative text-sm', classNames])}>
      <textarea
        className={inputClassNames}
        value={typeof value === 'string' ? decodeString(value) : value}
        maxLength={maxLength}
        {...rest}
      />
      {showRemainingCharactersText && (
        <p
          className={cn([
            maxCharactersRemaining && maxCharactersRemaining <= 0
              ? 'mb-5 pt-1.5 text-right text-xs text-freight-200'
              : 'mb-5 pt-1.5 text-xs text-right',
          ])}>
          {maxCharactersRemaining}{' '}
          {(maxCharactersRemaining ?? 0) == 1 ? 'Character Remaining' : 'Characters Remaining'}
        </p>
      )}
      {error && <p className="absolute bottom-1.5 text-freight-200">{errorMessage}</p>}
    </div>
  );
};

export default TextArea;
