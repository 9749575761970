import React from 'react';

import { useToast } from '@/hooks/useToast';
import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import { decodeString } from '@/utilities/textHelpers';
import ConfirmationModal from '@components/ConfirmationModal';
import { RemoveFavoritesFromCollectionModalProps } from './RemoveFavoritesFromCollectionModal.types';

export const RemoveFavoritesFromCollectionModal: React.FC<
  RemoveFavoritesFromCollectionModalProps
> = ({
  show,
  favoriteCollection,
  favorites,
  onConfirm,
  onClose,
  allCollectionFavorite,
  selectedFavoriteListings,
  setSelectedListings,
  setSelectedFavoriteListings,
  removeSingleRow,
  setRemoveSingleRow,
  isSelectAll,
  setSelectedPageNumber,
}) => {
  const { removeFavoritesFromFavoriteCollection } = useUserFavoriteCollections();
  const { addToast } = useToast();
  const count = selectedFavoriteListings.length;

  const handleConfirm = async () => {
    if (allCollectionFavorite.length > 0 && isSelectAll && !removeSingleRow) {
      await removeFavoritesFromFavoriteCollection(
        favoriteCollection.favoriteCollectionId,
        allCollectionFavorite.map((x) => x.favoriteId),
      );
      setSelectedPageNumber(1);
    } else if (selectedFavoriteListings.length > 0 && !removeSingleRow) {
      await removeFavoritesFromFavoriteCollection(
        favoriteCollection.favoriteCollectionId,
        selectedFavoriteListings.map((favoriteId) => favoriteId),
      );
      setSelectedPageNumber(1);
    } else if (removeSingleRow) {
      await removeFavoritesFromFavoriteCollection(
        favoriteCollection.favoriteCollectionId,
        favorites.map((x) => x.favoriteId),
      );
    }

    addToast({
      id: `remove-favorites-from-collection-${new Date().getTime()}`,
      description: 'Favorite(s) Removed Successfully',
      title: 'Success',
      type: 'success',
    });

    setSelectedListings([]);
    setSelectedFavoriteListings([]);
    setRemoveSingleRow(false);
    onConfirm ? onConfirm() : onClose();
  };

  return (
    <ConfirmationModal
      header="Remove from Collection"
      onClose={onClose}
      primaryBtnLabel="Yes, remove from collection"
      primaryBtnOnClick={handleConfirm}
      secondaryBtnLabel="No, I want to cancel"
      secondaryBtnOnClick={onClose}
      show={show}
      subText={
        favorites.length === 1 ? (
          <>
            Are you sure you want to remove{' '}
            <span className="font-bold underline">{favorites[0].name}</span> listing from your
            collection &apos;{decodeString(favoriteCollection.name)}&apos;?
          </>
        ) : (
          `Are you sure you want to remove the selected listings (${
            count > 0 ? count : favorites.length
          }) from your collection '${decodeString(favoriteCollection.name)}'?`
        )
      }
    />
  );
};

export default RemoveFavoritesFromCollectionModal;
