import { ControlledMenu, useHover } from '@szhsin/react-menu';
import cn from 'classnames';
import React, { useRef, useState } from 'react';

import { MAP_STYLES } from '@/config';
import { Icon, IconName } from '@components/Icon';
import './../Map.css';

interface MapStyleMenuItemProps {
  styles: typeof MAP_STYLES;
  setMapView: (style: string) => void;
}

export const MapStyleMenuItem: React.FC<MapStyleMenuItemProps> = ({
  styles,
  setMapView,
}: MapStyleMenuItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setIsOpen);
  const [activeStyleId, setActiveStyleId] = useState(MAP_STYLES[0].id);

  const menuRef = useRef(null);

  return (
    <>
      <button
        className={cn([
          'mb-0.5 p-2 inline-flex justify-center items-center bg-slate-500 text-cardboard-100 hover:text-slate-500 hover:bg-site-100 first:rounded-tr-md last:rounded-br-md',
          isOpen ? 'bg-site-100 !text-slate-500' : '',
          'margin-bottom-all',
        ])}
        ref={menuRef}
        {...anchorProps}>
        <Icon name={IconName.MAP_SETTINGS} classNames="w-[1.2rem]" />
      </button>

      <ControlledMenu
        {...hoverProps}
        anchorRef={menuRef}
        direction="right"
        onClose={() => setIsOpen(false)}
        state={isOpen ? 'open' : 'closed'}
        transition>
        <div className="max-h-[3.5rem] flex m-0 p-0 justify-center mb-3">
          <div className="inline-flex max-h-[3.5rem]" role="group">
            {styles.map((style) => (
              <button
                key={style.id}
                className={cn([
                  'ml-0.5 h-[3.5rem] px-4 inline-flex text-xs uppercase justify-center items-center bg-slate-500 text-white-100 hover:text-slate-500 hover:bg-site-100  transition-all last:rounded-tr-md',
                  style.id === activeStyleId ? '!bg-slate-500 !text-site-100' : '',
                ])}
                onClick={() => {
                  setMapView(style.url);
                  setActiveStyleId(style.id);
                }}
                type="button">
                {style.displayName}
              </button>
            ))}
          </div>
        </div>
      </ControlledMenu>
    </>
  );
};
