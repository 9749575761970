import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

import { AAD_CLIENT_ID, AAD_TENANT_ID, APIM_SCOPE_URI } from '@/config';

const defaultScopes = ['openid'];

export const msGraphScopes = [...defaultScopes, 'User.Read'];

export const apiScopes = APIM_SCOPE_URI
  ? [...defaultScopes, `${APIM_SCOPE_URI}/.default`]
  : [...defaultScopes];

export const msalConfiguration: Configuration = {
  auth: {
    clientId: AAD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AAD_TENANT_ID}`,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfiguration);

setActiveAccountFromCache();

// NOTE - Add event callbacks for MSAL.

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  switch (event.eventType) {
    // NOTE - On a successful login, we set the associated account as active.

    case EventType.LOGIN_SUCCESS: {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;

      msalInstance.setActiveAccount(account);

      break;
    }

    // NOTE - Fall back to the next account, if MSAL is aware of multiple.

    case EventType.LOGOUT_SUCCESS: {
      setActiveAccountFromCache();
      break;
    }

    // NOTE - Synchronize logout status across windows / tabs.

    case EventType.ACCOUNT_REMOVED: {
      const account = event.payload as AccountInfo;

      if (!msalInstance.getActiveAccount()) {
        msalInstance.logoutRedirect({ account });
      }

      break;
    }
  }
});

function setActiveAccountFromCache() {
  // NOTE - If MSAL is aware of multiple accounts, simply select the first one
  // to/ use as the currently active account.

  const accounts = msalInstance.getAllAccounts();

  if (!msalInstance.getActiveAccount() && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
}
