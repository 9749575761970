import cn from 'classnames';
import React from 'react';

import useWindowSize from '@/hooks/useWindowSize';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import { MinMaxSelector } from '@components/Inputs';
import { setMinMaxOptionRange } from '@components/Inputs/MinMaxSelector/MinMaxSelector.utils';
import { AvailableSqFtFilterModalProps } from './AvailableSqFtFilterModal.types';

const availableSqFtMockData = [
  '25000',
  '50000',
  '100000',
  '300000',
  '500000',
  '750000',
  '1000000',
  '1500000',
];

const AvailableSqFtFilterModal: React.FC<AvailableSqFtFilterModalProps> = ({
  classNames,
  closeModal,
  setSqFtRange,
  sqFtRange,
}) => {
  const { clearFilters, updateSearchParameters } = useMapData();
  const { innerHeight } = useWindowSize();

  return (
    <div className={`top-[${Math.round(innerHeight / 12.6438356)}px]`}>
      <div
        className={cn([
          '!absolute bg-slate-400 left-0 px-2 py-4 md:p-4 rounded-b-lg rounded-t-lg w-full md:w-[22.5rem] z-50',
          classNames,
        ])}>
        <MinMaxSelector
          classNames="[&_.predictive-search-input-error]:relative [&_.range-divider]:border-white-100"
          clearFilters={clearFilters}
          customOnBlurCallback={closeModal}
          data={availableSqFtMockData}
          onSelect={setMinMaxOptionRange({
            queryParameter: 'available_sq_ft',
            setRange: setSqFtRange,
            updateSearchParameters,
          })}
          optionIdentifierSubtext="SF"
          range={sqFtRange}
          maxWidth="responsive-sqft-modal-w-landing-page md:max-w-[5rem]"
        />
      </div>
    </div>
  );
};

export default AvailableSqFtFilterModal;
