import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { ButtonVariant } from '@components/Button';
import ConfirmationModal, { BtnsDirection } from '@components/ConfirmationModal';
import { Icon, IconName } from '@components/Icon';
import OverflowAndScrollBarHiddenWrapper from '@components/OverflowHiddenWrapper';
import FavoriteCollectionModalCard from './FavoriteCollectionModalCard';
import {
  LISTING_ALREADY_ADDED_TO_COLLECTION,
  THIS_LISTING_HAS_BEEN_FAVORITED,
  WOULD_YOU_LIKE_ADD_TO_ANOTHER,
  WOULD_YOU_LIKE_ADD_TO_NEW_OR_EXISTING,
} from './PostFavoritedModals.constants';
import { FavoriteCollectionsModalProps } from './PostFavoritedModals.types';
import { CheckboxState } from '@components/Inputs';
import { Favorite } from '@/types';
import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import _ from 'lodash';

const NEW_COLLECTION_ADDED = 'New Collection Added!';

const FavoriteCollectionsModal: React.FC<FavoriteCollectionsModalProps> = ({
  favoriteListingIds,
  favoritesCollections,
  newFavoriteCollectionAdded,
  newFavoriteCollectionOnClick,
  onClose,
  addToCollectionFlow,
  newCollectionAdded,
}) => {
  const description =
    favoriteListingIds.length == 1
      ? 'Choose which collections you’d like to add this listing to.'
      : `Choose which collections you’d like to add these (${favoriteListingIds.length}) listings to.`;
  const descriptionNote = LISTING_ALREADY_ADDED_TO_COLLECTION;
  const header = newFavoriteCollectionAdded
    ? NEW_COLLECTION_ADDED
    : addToCollectionFlow
    ? 'Add to Collection'
    : THIS_LISTING_HAS_BEEN_FAVORITED;

  const subText = newFavoriteCollectionAdded
    ? WOULD_YOU_LIKE_ADD_TO_ANOTHER
    : addToCollectionFlow
    ? ''
    : WOULD_YOU_LIKE_ADD_TO_NEW_OR_EXISTING;

  const [collectionIdsToSave, setCollectionIdsToSave] = useState<string[]>([]);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isStateModified, setIsStateModified] = useState(false);
  const {
    addFavoritesToFavoriteCollection,
    getFavoriteCollectionById,
    removeFavoritesFromFavoriteCollection,
  } = useUserFavoriteCollections();

  const onCardChecked = (collectionId: string, checkboxState: CheckboxState) => {
    setCollectionIdsToSave((prevState) => {
      if (checkboxState === CheckboxState.CHECKED) {
        if (!prevState.includes(collectionId)) {
          const tempList = prevState.filter((c) => c);
          tempList.push(collectionId);
          return tempList;
        } else return prevState.filter((c) => c);
      } else if (prevState.includes(collectionId)) {
        const tempList = prevState.filter((f) => f !== collectionId);
        return tempList;
      } else {
        return prevState.filter((c) => c);
      }
    });
  };

  const onCancel = () => {
    if (!isStateModified) {
      onClose();
    } else {
      setShowUnsavedChangesModal(true);
    }
  };

  const getInitialCheckedStateNew = (
    collectionFavorites: Favorite[] | undefined,
    selectedFavoritesIds: string[],
  ) => {
    if (!collectionFavorites) {
      return CheckboxState.EMPTY;
    }
    const collectionFavoritesIds = collectionFavorites.map((favorite) => favorite.favoriteId);
    const allSelectedFavoritesInCollection = selectedFavoritesIds.every((favorite) =>
      collectionFavoritesIds?.includes(favorite),
    );

    return allSelectedFavoritesInCollection ? CheckboxState.CHECKED : CheckboxState.EMPTY;
  };
  const reversedFavoritesCollections = favoritesCollections.slice().reverse();

  const collectionsOldState = reversedFavoritesCollections.map((favoriteCollection) => {
    const { favoriteCollectionId, listingIds, name, thumbnailImageUrls } = favoriteCollection;
    const { data: favoriteCollectionDetails } = getFavoriteCollectionById(favoriteCollectionId);

    const exists = getInitialCheckedStateNew(
      favoriteCollectionDetails?.favorites,
      favoriteListingIds,
    );
    return { favoriteCollectionId, listingIds, name, thumbnailImageUrls, exists };
  });

  if (newCollectionAdded) {
    const itemFind = collectionsOldState.find(
      (collection) => collection.favoriteCollectionId === newCollectionAdded.favoriteCollectionId,
    );

    const fndIndex = collectionsOldState.findIndex(
      (collection) => collection.favoriteCollectionId === newCollectionAdded.favoriteCollectionId,
    );

    collectionsOldState.splice(fndIndex, 1);
    itemFind && collectionsOldState.unshift(itemFind);
  }

  const collectionCards = collectionsOldState.map((favoriteCollection, index) => {
    const { favoriteCollectionId, listingIds, name, thumbnailImageUrls, exists } =
      favoriteCollection;
    let isExists = exists;
    let isDisabled = isExists == CheckboxState.CHECKED;

    if (isStateModified) {
      isExists = collectionIdsToSave.includes(favoriteCollectionId)
        ? CheckboxState.CHECKED
        : CheckboxState.EMPTY;
    }

    const checked = newCollectionAdded
      ? index === 0
        ? CheckboxState.CHECKED
        : isExists
      : isExists;

    return (
      <FavoriteCollectionModalCard
        key={favoriteCollectionId}
        classNames={cn([index && 'mt-4'])}
        favoriteCollectionId={favoriteCollectionId}
        favoriteCollectionName={name}
        favoriteListingIds={favoriteListingIds}
        listingCount={listingIds.length}
        thumbnailImageUrl={thumbnailImageUrls?.[0] ?? null}
        isChecked={checked}
        isDisabled={isDisabled}
        onChecked={onCardChecked}
      />
    );
  });
  const onSave = () => {
    const existingColIds = collectionsOldState
      .filter((c) => c.exists === CheckboxState.CHECKED)
      .map((c) => c.favoriteCollectionId)!;
    const idsToRemove = existingColIds.filter(
      (idExisting) => !collectionIdsToSave.includes(idExisting),
    );
    setLoading(true);
    if (idsToRemove.length === 0 || collectionIdsToSave.length === 0) {
      setLoading(false);
      onClose();
    }
    idsToRemove.forEach((id, index) =>
      removeFavoritesFromFavoriteCollection(id, favoriteListingIds).then(() => {
        if (collectionIdsToSave.length == 0 && index === idsToRemove.length - 1) {
          setLoading(false);
          onClose();
        }
      }),
    );
    collectionIdsToSave.forEach((id, index) =>
      addFavoritesToFavoriteCollection(id, favoriteListingIds).then(() => {
        if (index === collectionIdsToSave.length - 1) {
          setLoading(false);
          onClose();
        }
      }),
    );
  };

  useEffect(() => {
    const existingColIds = collectionsOldState
      .filter((c) => c.exists === CheckboxState.CHECKED)
      .map((c) => c.favoriteCollectionId)!;
    const sortedArrayNew = collectionIdsToSave.sort((a, b) => a.localeCompare(b));
    const sortedArrayExisting = existingColIds.sort((a, b) => a.localeCompare(b));
    if (_.isEqual(sortedArrayNew, sortedArrayExisting)) {
      setIsStateModified(false);
    } else {
      setIsStateModified(true);
    }
  }, [collectionIdsToSave]);

  return (
    <div>
      {showUnsavedChangesModal ? (
        <ConfirmationModal
          btnsDirection={BtnsDirection.ROW}
          classNames=""
          header="Unsaved Changes"
          description="You have unsaved changes. Are you sure you want to cancel?"
          primaryBtnLabel="No"
          primaryBtnOnClick={() => setShowUnsavedChangesModal(false)}
          primaryBtnVariant={ButtonVariant.DEFAULT_OUTLINE}
          secondaryBtnLabel="Yes"
          secondaryBtnOnClick={onClose}
          secondaryBtnVariant={ButtonVariant.PRIMARY_ONE}
          onClose={onClose}
          show
          haveCloseButton={false}></ConfirmationModal>
      ) : (
        <ConfirmationModal
          isMediumScreen={true}
          btnsDirection={addToCollectionFlow ? BtnsDirection.COL : BtnsDirection.ROW}
          classNames="post-favorited-modal"
          header={header}
          description={description}
          descriptionNote={descriptionNote}
          primaryBtnLabel={`Add ${favoriteListingIds.length} ${
            favoriteListingIds.length === 1 ? 'Listing' : 'Listings'
          } to Selected Collections`}
          primaryBtnOnClick={onSave}
          primaryBtnVariant={ButtonVariant.PRIMARY_ONE}
          primaryBtnDisabled={loading || collectionsOldState.length <= 0 || !isStateModified}
          hidePrimaryButton={loading || collectionsOldState.length <= 0 || !isStateModified}
          onClose={onCancel}
          secondaryBtnLabel="New Collection"
          secondaryBtnIcon={<Icon name={IconName.ADD_FOLDER} />}
          secondaryBtnOnClick={newFavoriteCollectionOnClick}
          secondaryBtnVariant={ButtonVariant.DEFAULT_OUTLINE}
          show
          subText={subText}>
          <OverflowAndScrollBarHiddenWrapper classNames="mt-6" heightRem={13.5625}>
            {collectionCards}
          </OverflowAndScrollBarHiddenWrapper>
        </ConfirmationModal>
      )}
    </div>
  );
};

export default FavoriteCollectionsModal;
