import { createContext, useState } from 'react';
import { ToastType } from '@components/Toaster/Toaster';
import { v4 as uuidv4 } from 'uuid';

interface ToastContextValue {
  toastList: ToastType[];
  addToast: (toast: ToastType) => void;
  deleteToast: (toast: ToastType) => void;
}

export const ToastContext = createContext<ToastContextValue>({} as ToastContextValue);

export const ToastProvider: React.FC<any> = ({ children }) => {
  const [toastList, setToastList] = useState<ToastType[]>([]);

  const addToast = (toast: ToastType) => {
    toast.id = uuidv4();
    setToastList((toastList) => [...toastList, toast]);
  };

  const deleteToast = (toast: ToastType) => {
    setToastList((toastList) => toastList.filter((t) => t.id !== toast.id));
  };

  const contextValue: ToastContextValue = {
    addToast,
    deleteToast,
    toastList,
  };

  return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>;
};
