import React, { useEffect, useState } from 'react';
import { Typography } from '@components/Typography';
import Modal from '@components/Modal';
import { AppSwitcherModalProps } from './AppSwitcherModal.types';
import { useApiClient } from '@/hooks/useApiClient';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import Link from '@components/Link/Link';
import { AppConfig } from '@/types/app-config';

const AppSwitcherModal: React.FC<AppSwitcherModalProps> = ({ onClose }) => {
  const isBigScreen = useIsBigScreen();

  const _customerIQUrlKey = 'CustomerIQUrl';
  const _propertyIQUrlKey = 'PropertyIQUrl';
  const _researchIQUrlKey = 'ResearchIQUrl';
  const _reportingLibraryUrlKey = 'ReportingLibraryUrl';
  const _theLinkUrlKey = 'TheLinkUrl';

  const { getAppConfigs } = useApiClient();

  const [configs, setConfigs] = useState<AppConfig[]>([]);

  const { data: appConfigs } = getAppConfigs();

  useEffect(() => {
    setConfigs(appConfigs!);
  }, [appConfigs]);

  const isValidConfig = (key: string) => {
    const value = configValue(key);
    return !!value;
  };

  const configValue = (key: string) => {
    const config = configs?.find((c) => c.key === key);
    return config?.value;
  };

  const menu = (
    <>
      {isValidConfig(_customerIQUrlKey) && (
        <Link url={configValue(_customerIQUrlKey)!} newTab={true}>
          <Typography className="text-base-black p-2 pl-[0.5rem] link-label link-" variant="body-1">
            Customer IQ
          </Typography>
        </Link>
      )}
      {isValidConfig(_propertyIQUrlKey) && (
        <Link classNames="rounded" url={configValue(_propertyIQUrlKey)!} newTab={true}>
          <Typography className="text-base-black p-2 pl-[0.5rem] link-label " variant="body-1">
            Property IQ
          </Typography>
        </Link>
      )}
      {isValidConfig(_researchIQUrlKey) && (
        <Link classNames="rounded" url={configValue(_researchIQUrlKey)!} newTab={true}>
          <Typography className="text-base-black p-2 pl-[0.5rem] link-label " variant="body-1">
            Research IQ
          </Typography>
        </Link>
      )}
      {isValidConfig(_reportingLibraryUrlKey) && (
        <Link classNames="rounded" url={configValue(_reportingLibraryUrlKey)!} newTab={true}>
          <Typography className="text-base-black p-2 pl-[0.5rem] link-label " variant="body-1">
            Reporting Library
          </Typography>
        </Link>
      )}
      {isValidConfig(_theLinkUrlKey) && (
        <Link classNames="rounded" url={configValue(_theLinkUrlKey)!} newTab={true}>
          <Typography className="text-base-black p-2 pl-[0.5rem] link-label " variant="body-1">
            The Link
          </Typography>
        </Link>
      )}
    </>
  );
  return (
    <Modal
      haveClose
      onClose={onClose}
      show
      classNames={isBigScreen ? '' : 'min-h-[17rem] max-h-[calc(100vh-3.75rem)] pt-6'}>
      <Typography className="mb-6 text-center mt-7" variant="h6">
        Switch Apps
      </Typography>
      {menu}
    </Modal>
  );
};

export default AppSwitcherModal;
