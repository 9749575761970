import useIsBigScreen from '@/hooks/useIsBigScreen';
import { TemplateValue } from '@/types/templates';
import './ListingDetails.css';
import ListingDetailsContentDesktop from './ListingDetailsContent-view-desktop';
import ListingDetailsContentMobile from './ListingDetailsContent-view-mobile';

export interface ListingDetailsContentProps {
  data: TemplateValue[];
  isBottom: boolean;
}
const ListingDetailsContent: React.FC<ListingDetailsContentProps> = ({ data, isBottom }) => {
  const isBigScreen = useIsBigScreen();
  return (
    <>
      {isBigScreen ? (
        <ListingDetailsContentDesktop data={data} isBottom={isBottom} />
      ) : (
        <ListingDetailsContentMobile data={data} />
      )}
    </>
  );
};

export default ListingDetailsContent;
