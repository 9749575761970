import React from 'react';
import cn from 'classnames';
import GridMapToggleSwitchOption from './GridMapToggleSwitchOption';
import {
  GridMapToggleSwitchProps,
  ToggleSwitchOption,
  ToggleSwitchVariant,
} from './GridMapToggleSwitch.types';

const toggleSwitchOptions = ['Grid', 'Map'];

const GridMapToggleSwitch: React.FC<GridMapToggleSwitchProps> = ({
  classNames,
  onClick,
  option,
  variant = ToggleSwitchVariant.BLACK,
}) => {
  const handleClick = (option: ToggleSwitchOption) => {
    onClick(option);
  };

  const variantIsBlack = variant === ToggleSwitchVariant.BLACK;
  const toggleButtonClassNames = variantIsBlack
    ? 'border-black text-black'
    : 'border-freight-100 text-freight-100';
  const gridActive = option === ToggleSwitchOption.GRID;

  return (
    <div
      className={cn([
        'cursor-pointer flex font-medium h-6 border rounded text-sm w-fit',
        toggleButtonClassNames,
        classNames,
      ])}>
      {toggleSwitchOptions.map((option) => {
        const isGrid = option === 'Grid';

        return (
          <GridMapToggleSwitchOption
            key={option}
            active={isGrid ? gridActive : !gridActive}
            onClick={handleClick}
            label={option}
            option={isGrid ? ToggleSwitchOption.GRID : ToggleSwitchOption.MAP}
            variantIsBlack={variantIsBlack}
          />
        );
      })}
    </div>
  );
};

export default GridMapToggleSwitch;
