import React, { useEffect } from 'react';
import VideoPlayer from './VideoPlayer';

import {
  VideoPlayerOptions,
  VideoSlideProps,
  VideoSlideResetControl,
} from './AssetsCarousel.types';

const VideoSlide: React.FC<VideoSlideProps> = ({
  activeSlideIndex,
  index,
  onSettingCurrentSlideControlsRef,
  videoUrl,
}) => {
  const playerRef = React.useRef<any | null>(null);

  const videoJsOptions: VideoPlayerOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    preload: 'auto',
    sources: [
      {
        src: videoUrl,
        type: 'video/mp4',
      },
    ],
  };

  /**
   * Each video slide has its own reset control.
   * Navigating between slides requires resetting the previously active video slide.
   * By default the navigation controls do not have access to the currently active video slide's reset control.
   * Thus, a global ref is required to store the active video slide's reset control.
   * This will allow resetting the player while navigating away from the video slide.
   * (Note) the global ref is interchangeable with image controls.
   */
  useEffect(() => {
    if (activeSlideIndex === index) {
      onSettingCurrentSlideControlsRef({
        resetPlayer: handleResetPlayer,
      } as VideoSlideResetControl);
    }
  }, [activeSlideIndex]);

  const handleResetPlayer = () => {
    if (playerRef.current) {
      playerRef.current.pause();
      playerRef.current.currentTime(0);
      playerRef.current.trigger('loadstart');
    }
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // Handle player events here

    player.on('mouseout', () => {
      const controlBar = document.getElementsByClassName('vjs-control-bar')[0];
      controlBar.classList.add('vjs-fade-out');
    });

    player.on('mouseover', () => {
      const controlBar = document.getElementsByClassName('vjs-control-bar')[0];
      controlBar.classList.remove('vjs-fade-out');
    });
  };

  return <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />;
};

export default VideoSlide;
