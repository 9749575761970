import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonVariant } from '@components/Button';
import { Typography } from '@components/Typography';
import TrafficCone from '@images/traffic-cone.png';
import useIsBigScreen from '@/hooks/useIsBigScreen';

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const isBiScreen = useIsBigScreen();
  return (
    <div className="h-[calc(100vh-4.5rem)] flex justify-center items-center bg-[linear-gradient(0deg,rgba(255,255,255,0.85),rgba(255,255,255,0.85)),url('/src/assets/images/landing-page-background.jpg')] bg-center bg-cover bg-fixed bg-no-repeat">
      <div className="flex sm:flex-col sm:items-center md:items-start md:flex-row">
        <img
          className="sm:mr-0 md:mr-12 sm:mb-7 md:mb-0 sm:w-[105px] sm:h-[100%] md:h-[216px] md:w-[193px]"
          src={TrafficCone}
        />

        <div className="flex flex-col justify-center sm:items-center md:items-start">
          <Typography className="text-slate-500 mb-[0.625rem]" variant={isBiScreen ? 'h3' : 'h4'}>
            Page not found.
          </Typography>
          <Typography className="text-slate-500 mb-14" variant="body-1">
            Sorry for the inconvenience.
          </Typography>
          <Button
            classNames="w-full sm:fixed md:relative sm:bottom-[8px] sm:px-[20px] sm:py-[34px] md:p-0"
            buttonClassNames="w-full"
            variant={ButtonVariant.PRIMARY_ONE}
            onClick={() => navigate('/map')}>
            Back to Map
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
